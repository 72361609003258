import React, {Component} from 'react';
import {
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import {grey} from '@material-ui/core/colors';


const underline_btn_styles = (theme) => ({
  root:{
    padding:`0px ${theme.spacing.unit*2}px`,
  },
  span:{
    padding: `${theme.spacing.unit*2}px 0px`,
    paddingTop: theme.spacing.unit*1,
    '&:hover':{
      cursor:'pointer',
    }
  },
  underline:{
    minHeight:'3px',
    width:'0',
    background:'#33ccff',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
  underlineHover:{
    width:'100%',
  },
  underlineSelected:{
    minHeight:'3px',
    background:'#33ccff',
    width:'100%',
  },
  label:{
    color:'white',
    //fontWeight:'600',
    fontSize:'24px',
    //textTransform:'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize:'20px',
    },
  },
  label2:{
    color:'white',
    fontSize:'24px',
    //fontWeight:'600',
    //fontSize:'0.65em',
    textTransform:'uppercase',
    /* [theme.breakpoints.down('md')]: {
      fontSize:'0.65em',
    }, */
  },
  label_selected:{
    color:theme.palette.primary.main,
    fontWeight:900
  }
});

class PreUnderlineButton extends Component{

  state = {
    ishover: false,
  }

  customFunction = (event) =>  {
    this.setState({ishover:true})
  }

  onLeave = (event) => {
    this.setState({ishover:false})
  }

  render(){

    const {classes, onClick, selected, id, mode} = this.props;
    const {ishover} = this.state

    return(
      <div className={classes.root}>
        <div
          className={classes.span}
          onMouseEnter={this.customFunction}
          onMouseLeave={this.onLeave}
          onClick={(event) => onClick(event, id)}
        >
          <Typography
            align='center'
            //variant='h6'
            className={cx({
              //[classes.label]:!mode,
              //[classes.label2]:mode,
              [classes.label]:true,
              [classes.label_selected]:selected
            })}
          >
            {this.props.text}
          </Typography>

        </div>
        {/* <div className={cx({
            [classes.underline]:true,
            [classes.underlineHover]: ishover,
            [classes.underlineSelected]:selected})}
        /> */}
      </div>
    )
  }
}

export const UnderlineButton = withStyles(underline_btn_styles)(PreUnderlineButton);
