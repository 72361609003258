import { useEffect, useState } from "react"
import { mailServer } from "../../../../../../config"
import { isFormValid, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from "../../../../../../shared/utility"



/* const mailServer = {
    instance: axios.create({
        baseURL: "https://jna02sfp70.execute-api.us-east-1.amazonaws.com",
        headers: {
        'X-Api-Key': 'agbCMI6hTOBFFt0Uqa2g2z1lyGHGM9N5wHrBrq45',
        },
    }),
    credentials: {
        key: 'agbCMI6hTOBFFt0Uqa2g2z1lyGHGM9N5wHrBrq45'
    }
} */



const useSectionA = ({ }) => {

    //const {id} = useParams()
    //console.log(id)

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [snack, setSnack] = useState(false)
    const [success, setSuccess] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    const [user, setUser] = useState(null)

    const [modals, setModals] = useState({
        delete:false,
        change_picture:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

  

    const actions = {
        onInitModule: async() => {
            setError(null)
            setSuccess(null)
            /* try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const _user = await private_server.get(`/user/${id}`)
                setUser(_user.data.data.user)
                let _form = onInitForm(form, _user.data.data.user, content.form)
                //_form.user_type_id.options = catalogs.user_types
                _form.mobile_country_code.options = [{value:"+52", label:"+52"}]
                setForm(_form)
                setLoading(false)       
            } catch (error) {
                setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error)
                onError(_error ? _error.message : null)
                //setError(_error ? _error.message : null)
            } */
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)
        },
        onUpdateModal: (_key, _value) => {
            let _modals = {...modals, [_key]:_value}
            setModals(_modals)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
    
            //Extract data
            let data2send = onGetFormData(form)
            data2send.type = 'Solicitud de contacto'
            console.log(data2send)
            setSending(true)
            try {
                //updateAuthorizationHeader(private_server)
                //await private_server.patch(`/user/${id}`, data2send)
                await mailServer.instance.post("/dev/contact-email", data2send);
                //const _user = await private_server.get(`/user/${id}`)
                //setUser(_user.data.data.user)
                setSuccess('Information has been sent correctly.')
                
            } catch (error) {
                console.log(error)
                //const _error = onGetErrorMessage(error)  
                setError('There was an error')
                
            }
            setSending(false)
        },
        onCloseSuccess: () => setSuccess(null),
        onClearError: () => setError(null)
    }

    const system = {loading, sending, success, error}
   

    return {system, form, user, actions, modals}
}

export default useSectionA

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label:'Name',
            helperText: 'Required field',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label:'Email',
            helperText: 'Invalid email',
        },
        rules: {
            type: 'email',
        }
    },
    /* mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,     
        },
        rules: {
            type: 'phone_number',
        }
    },
    mobile_country_code: {
        value: '52',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'mobile_country_code',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }

    }, */
    phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone',
            type: 'number',
            fullWidth: true,
            label: 'Phone number',
            helperText: 'Invalid phone number',
        },
        rules: {
            type: 'phone',
        }
    },
    subject: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'subject',
            type: 'text',
            fullWidth: true,
            label:'Subject'
        },
        rules: {
            type: 'distance',
            min: 0, max: 256
        }
    },
    message: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'message',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows:4,
            label:'Message'
        },
        rules: {
            type: 'distance',
            min: 0, max: 256
        }
    },
}