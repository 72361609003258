import React from "react"
import { makeStyles, Slide, Typography, Zoom } from "@material-ui/core"
import InfoItem from "../InfoItem"
import image from '../../../../../../../assets/Home/IMAGEN-PHYSICIANS-JOURNEY.png'
import WorkModal from "../WorkModal"



const Physicians = ({open, onClose }) => {

    const classes = useStyles()

    return(
        <WorkModal open={open} onClose={onClose}>
            <Typography variant='h3' className={classes.title} >Physicians Journey</Typography>
            <Zoom in timeout={750} style={{ transitionDelay:'500ms' }}>
            <div className={classes.challenge}>
                <InfoItem 
                    title='Challenge.'
                    info="Our client, a leading pharmaceutical company, needs 
                    to develop a launch strategy in a very complicated and 
                    crowded market for an innovative product where 
                    target physicians are struggling to pick the best options 
                    for patients."
                />
            </div>
            </Zoom>
            <Zoom in timeout={750} style={{ transitionDelay:'1000ms' }}>
            <div className={classes.solution}>
                <InfoItem 
                    title='Solution.'
                    info='ālthar develops a customized physicians methodology 
                    with surveys and building sessions to know the thinking, 
                    feeling, and doing of the target physicians.'
                />
            </div>
            </Zoom>
            <Zoom in timeout={750} style={{ transitionDelay:'1500ms' }} >
            <div className={classes.result}>
                <InfoItem 
                    title='Result.'
                    info='We found four key insights that change the entire 
                    medical and marketing plan from global. With a 
                    detailed level, and a year later our clients achieve the 
                    qualitative and quantitative goals. The product is the 
                    number one prescription product in the category.'
                />
            </div>
            </Zoom>
            <Slide direction="up" in timeout={1000} >
                <div className={classes.img_container}>
                    <img src={image} width={'100%'} className={classes.img} />
                </div>
            </Slide>
            {/* <div className={classes.line} style={{top:260, left:'40%', width:180}} />
            <div className={classes.line} style={{top:460, left:'40%', width:180}} />
            <div className={classes.line} style={{top:620, left:'40%', width:180}} /> */}
        </WorkModal>
    )
}

export default Physicians

const useStyles = makeStyles(theme => ({
    root:{
      position:'relative',
      padding:70,
      color:'white',
      [theme.breakpoints.down('sm')]:{
        padding:32,
        }
    },
    title:{
        marginBottom:20,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem',
            textAlign:'center',
            //background:'red',
            width:'100%',
            marginTop:40,
            }
    },
    challenge:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:400
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
  
    },
    result:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:400
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
     
    },
    solution:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:400
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
      
    },
    img:{
        transform:'scale(1.2)',
        /* [theme.breakpoints.down('lg')]:{
            transform:'rotate(8deg) scale(1)',
        }, */
        [theme.breakpoints.down('md')]:{
            transform:'scale(1)',
        },
        [theme.breakpoints.down('sm')]:{
            transform:'scale(0.8)',
            //background:'pink'
        },
    },
    img_container:{
        position:'absolute',
        top:200,
        right:'10%',
        [theme.breakpoints.down('lg')]:{
            right:'-5%'
        },
        [theme.breakpoints.down('md')]:{
            right:'-17%'
            //left:0,
            //background:'red',
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:0,
            right:'initial',
            margin:'auto',
            //height:700,
            //background:'blue'
        }
    },
    line:{
        position:'absolute',
        border:'1px solid white',
        [theme.breakpoints.down(1500)]:{
            display:'none'
        },
    }

  }))



