import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import{Typography, Grid} from '@material-ui/core';
import cx from 'classnames';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
  container:{
    padding:'0px 40px',
    [theme.breakpoints.down('sm')]: {
      padding:'0px 0px',
    },
  },
  title:{
    fontWeight:'700',
    fontSize:'1.2em',
    color:grey[900],
    textTransform:'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize:'1em',
    },
  },
  content:{
    color:grey[500],
    fontWeight:'500',
    fontSize:'1em'
  },
  subcontent:{
    color:grey[500],
    fontWeight:'500',
    fontSize:'1em'
  },
  icon:{
    width:'100%'
  },
  iconContainer:{
    width:'64px',
    height:'64px',
    margin:'auto',
    padding:'18px',
    borderRadius:'50%',
    border:'4px solid white',
    boxShadow:'0 6px 12px 0 rgba(82, 91, 115, .2)',
    transform:'scale(1)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover':{
        transform:'scale(1.1)',
    },
    [theme.breakpoints.down('sm')]: {
      width:'36px',
      height:'36px',
      padding:'16px',
    },
  },
  containerSelected:{
    transform:'scale(1.2)',
    animation:'bouncing2 2s linear infinite',
    [theme.breakpoints.down('sm')]: {
      transform:'scale(1.1)',
      animation:'bouncing3 2s linear infinite',
    },
  },
  '@keyframes bouncing2':{
    '0%':{transform:'scale(1)'},
    '50%':{transform:'scale(1.2)'},
    '100%':{transform:'scale(1)'}
  },
  '@keyframes bouncing3':{
    '0%':{transform:'scale(1)'},
    '50%':{transform:'scale(1.1)'},
    '100%':{transform:'scale(1)'}
  },
  
  iconSelectedA:{
    boxShadow:'0 6px 12px 0 rgba(66, 104, 255, .6)',
  },
  iconSelectedB:{
    boxShadow:'0 6px 12px 0 rgba(247, 35, 196, .6)',
  },
  iconSelectedC:{
    boxShadow:'0 6px 12px 0 rgba(0, 131, 255, .6)',
    
  },
  
  colorA:{
    background:'linear-gradient(135deg, #ff4dae, #4268ff)'
  },
  colorB:{
      background:'linear-gradient(135deg, #ff9f2e, #f723c4)'
  },
  colorC:{
      background:'linear-gradient(135deg, #00f2aa, #0083ff)'
  },
  colorD:{
      background:'linear-gradient(135deg, #ffe10a, #ff3e5c)'
  },
  
})

class WorkFeature extends Component{

    render(){

        const {classes, data, color, selected} = this.props;

        return(
          <div className={cx({
            [classes.container]:true,
            [classes.containerSelected]:selected
          })}>
            <Grid container justify='center' spacing={2}>
              <Grid item xs={12}>
                <div className={cx({
                        [classes.iconContainer]:true,
                        [classes.iconSelectedA]:selected && color === 0,
                        [classes.iconSelectedB]:selected && color === 1,
                        [classes.iconSelectedC]:selected && color === 2,
                        [classes.colorA]:color === 0,
                        [classes.colorB]:color === 1,
                        [classes.colorC]:color === 2,
                        [classes.colorD]:color === 3
                    })}>
                  <img src={data.icon} alt={''} className={classes.icon}/>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography align='center' className={classes.title}>
                  {data.title}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )
    }
}

export default withStyles(styles)(WorkFeature);