import logoA from '../../../assets/About/clients/amk.png';
import logoB from '../../../assets/About/clients/ascg.png';
import logoC from '../../../assets/About/clients/bjsystems.png';
import logoD from '../../../assets/About/clients/enertech.png';
import logoE from '../../../assets/About/clients/intelite.png';
import logoF from '../../../assets/About/clients/tec.png';
import logoG from '../../../assets/About/clients/trionix.png';
import logoH from '../../../assets/About/clients/modum.png';

import mission from '../../../assets/About/mision.png';
import vision from '../../../assets/About/vision.png';

import courage from '../../../assets/About/courage.png';
import character from '../../../assets/About/character.png';
import integrity from '../../../assets/About/integrity.png';
import innovation from '../../../assets/About/innovation.png';

import scrumIcon from '../../../assets/About/scrumIcon.png';
import agileIcon from '../../../assets/About/agileIcon.png';
import devopsIcon from '../../../assets/About/devopsIcon.png';


export const contentData = {
  spanish:{
    sectionA:{
      name:'Nosotros',
      subnameA:'xand',
      subnameB:'box',
      phrase:'Transformando y digitalizando tus negocios'
    },
    sectionB:{
      title:'Nosotros',
      company:{
        title: 'Empresa',
        content:'En Xandbox amamos crear y desarrollar software a la medida para solucionar un problema, innovar o transformar una experiencia de usuario, de empresa o de tecnología. Aplicamos métodos de diseño y un extenso portafolio de lenguajes de programación donde prima la usabilidad, la reducción de costos, el ahorro de tiempo, la planeación detallada y la acción masiva.'
      },
      mission:{
        title:'Misión',
        content:'Nuestra misión en Xandbox es mejorar el mundo, la vida y los negocios a través del diseño y programación de software. A diario brindamos soluciones innovadoras y creativas a la medida de las necesidades de nuestros clientes, empleando tecnologías y lenguajes de punta para agregar valor e incrementar las ventas y utilidades de nuestros clientes.'
      },
      vision:{
        title:'Visión',
        content:'En 2027 Xandbox será la fábrica de software original líder en el mercado hispanoamericano. Xandbox trascenderá, convirtiéndose en un referente tecnológico en diversas áreas del desarrollo de software (plataformas, aplicaciones, inteligencia artificial, big data, sistemas en la nube, realidad aumentada y virtual, entre otras).'
      },
      values:{
        title:'Valores',
        data:[
          {icon:courage, title:'Coraje'},
          {icon:character, title:'Carácter'},
          {icon:integrity, title:'Integridad'},
          {icon:innovation, title:'Innovación'},
        ]
      },
      content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla non metus. pulvinar. Sociis natoque penatibus et magnis dis parturient montes.',
      button:'Get a quote!',
      data:[
        {image:mission, title:'Mision', 
        content:'Trionix tiene como misión mejorar la calidad de vida y salud de la gente, así como incrementar productividad y minimizar riesgos de las industrias de Latinoamérica. Para ello brindamos una verdadera transformación tecnológica con nuestras soluciones y servicios, creando ecosistemas donde el sensor, la data, la red, la conectividad el software y demás elementos del IoT y sistemas inteligentes funcionen en armonía, al unísono, en tiempo real, y de manera ininterrumpida para resolver los problemas a los que nos enfrentemos, desde los comunes hasta los casi imposibles.'},
        {image:vision, title:'Vision', 
        content:'En 2027 Trionix será la compañía número uno en soluciones y servicios en Industria 4.0, Tecnología en Salud y Ciudades Inteligentes de América Latina. Trionix trascenderá, creando nuevos mundos, donde la data recopilada, analizada, sirva para predecir, cambiar y mejorar estrategias, tácticas, patrones y determinaciones en la vida de las personas e industrias.'},
      ]
    },
    sectionC:{
      url:'contact',
      subtitle:'¡Trabajemos JUNTOS!',
      title:'Xandbox es todo lo que necesitas para crear increíbles aplicaciones',
      button:'Contáctanos',
      data:[
        {color:'#000c3b', icon:'build', title:'Customer Support', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#003366', icon:'slideshow', title:'Sliders', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#3f5c94', icon:'star_border', title:'Winner', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#7089c5', icon:'edit', title:'Customizable', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'}
      ],
      
    },
    sectionD:{
      title:'Nuestras formas de trabajo',
      content:'Utilizamos y nos adaptamos a diferentes metodologías de trabajo.',
      data:[
        {icon:scrumIcon, title:'Scrum'},
        {icon:agileIcon, title:'Agile'},
        {icon:devopsIcon, title:'DevOps'},
      ]
    },
    sectionE:{
      title:"Ellos nos recomiendan",
      content:'El 100 % de nuestros clientes han quedado satisfechos con nuestro trabajo.',
      clients:[
        {image:logoA},
        {image:logoB},
        {image:logoC},
        {image:logoD},
        {image:logoE},
        {image:logoF},
        {image:logoG},
        {image:logoH},
      ]
    },
    sectionF:{
      content:'Porto is everything you need to create an awesome website!',
      subcontent:'The best HTML template for your new website.',
      button:'Get Started Now'
    }
  },
  english:{
    sectionA:{
      name:'About us',
      subnameA:'xand',
      subnameB:'box',
      phrase:'Transforming and digitizing your business'
    },
    sectionB:{
      title:'About us',
      company:{
        title: 'Company',
        content:'At Xandbox we love creating and developing software tailored to solve a problem, innovate or transform a user, business or technology experience. We apply design methods and an extensive portfolio of programming languages where usability, cost reduction, time saving, detailed planning and massive action prevail.'
      },
      mission:{
        title:'Mission',
        content:'Our mission at Xandbox is to improve the world, life and business through the design and programming of software. Every day we provide innovative and creative solutions tailored to the needs of our customers, using cutting edge technologies and languages to add value and increase sales and profits of our customers.'
      },
      vision:{
        title:'Vision',
        content:'In 2027 Xandbox will be the software factory leader in the Spanish-American market. Xandbox will become a technological reference in various areas of software development (platforms, applications, artificial intelligence, big data, cloud systems, augmented and virtual reality, among others).'
      },
      values:{
        title:'Values',
        data:[
          {icon:courage, title:'Courage'},
          {icon:character, title:'Character'},
          {icon:integrity, title:'Integrity'},
          {icon:innovation, title:'Innovation'},
        ]
      },
      content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla non metus. pulvinar. Sociis natoque penatibus et magnis dis parturient montes.',
      button:'Get a quote!',
      data:[
        {image:mission, title:'Mision', 
        content:'Trionix tiene como misión mejorar la calidad de vida y salud de la gente, así como incrementar productividad y minimizar riesgos de las industrias de Latinoamérica. Para ello brindamos una verdadera transformación tecnológica con nuestras soluciones y servicios, creando ecosistemas donde el sensor, la data, la red, la conectividad el software y demás elementos del IoT y sistemas inteligentes funcionen en armonía, al unísono, en tiempo real, y de manera ininterrumpida para resolver los problemas a los que nos enfrentemos, desde los comunes hasta los casi imposibles.'},
        {image:vision, title:'Vision', 
        content:'En 2027 Trionix será la compañía número uno en soluciones y servicios en Industria 4.0, Tecnología en Salud y Ciudades Inteligentes de América Latina. Trionix trascenderá, creando nuevos mundos, donde la data recopilada, analizada, sirva para predecir, cambiar y mejorar estrategias, tácticas, patrones y determinaciones en la vida de las personas e industrias.'},
      ]
    },
    sectionC:{
      url:'contact',
      subtitle:"LET'S WORK TOGETHER!",
      title:'Xandbox is everything you need to create incredible applications',
      button:'Contact us',
      data:[
        {color:'#000c3b', icon:'build', title:'Customer Support', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#003366', icon:'slideshow', title:'Sliders', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#3f5c94', icon:'star_border', title:'Winner', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#7089c5', icon:'edit', title:'Customizable', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'}
      ],
      
    },
    sectionD:{
      title:'Our ways of working',
      content:'We use and adapt to different work methodologies.',
      data:[
        {icon:scrumIcon, title:'Scrum'},
        {icon:agileIcon, title:'Agile'},
        {icon:devopsIcon, title:'DevOps'},
      ]
    },
    sectionE:{
      title:"They recommend us",
      content:'100 % of our customers have been satisfied with our work.',
      clients:[
        {image:logoA},
        {image:logoB},
        {image:logoC},
        {image:logoD},
        {image:logoE},
        {image:logoF},
        {image:logoG},
        {image:logoH},
      ]
    },
    sectionF:{
      content:'Porto is everything you need to create an awesome website!',
      subcontent:'The best HTML template for your new website.',
      button:'Get Started Now'
    }
  }
}