import imageA from '../../../assets/Home/technologies.jpg';
import imageB from '../../../assets/Home/clientmanagment.jpg';

import analysis from '../../../assets/Services/process/analysis.png';
import design from '../../../assets/Services/process/design.png';
import planning from '../../../assets/Services/process/planning.png';
import programming from '../../../assets/Services/process/programming.png';

import service_ai from '../../../assets/Services/services/ai.png';
import service_cloudservices from '../../../assets/Services/services/cloudservices.png';
import service_vr from '../../../assets/Services/services/vr.png';
import service_web from '../../../assets/Services/services/web.png';
import service_mobile from '../../../assets/Services/services/mobile.png';
import service_ecommerce from '../../../assets/Services/services/ecommerce.png';


export const contentData = {
    spanish:{
        video:{
            nameA:'xand',
            nameB:'box',
            subname:'TECHNOLOGIES',
            phrase:'Coding the future!'
        },
        sectionC:{
            title:'Creamos aplicaciones web y móviles que amarás',
            content:[
                'Ofrecemos a nuestros clientes soluciones de software a la medida que se adaptan a sus necesidades y les permiten agregar valor e impulsar sus negocios.'
            ],
            data:[
                {icon:'add', title:'Años de experiencia', content:'5'},
                {icon:'add', title:'Proyectos completados', content:'30'},
                {icon:'add', title:'Clientes', content:'24'},
                {icon:'', title:'Integrantes del equipo', content:'10'},
            ] 
        },
        sectionD:{
            title:'Nuestros servicios',
            content:[
                'Ofrecemos a nuestros clientes soluciones de software a la medida que se adaptan a sus necesidades y les permiten agregar valor e impulsar sus negocios.'
            ],
            data:[
                {image:service_web, button:'Conocer más', title:'Diseño web', content:'Creamos sitios web que impulsan tu negocio a partir de las últimas tecnologías de desarrollo web y análisis de datos.'},
                {image:service_mobile, button:'Conocer más', title:'Aplicaciones móviles', content:'Desarrollamos aplicaciones móviles con tecnologías nativas e híbridas, cubriendo las plataformas iOS y Android.'},
                {image:service_ecommerce, button:'Conocer más', title:'Comercio electrónico', content:'Crece y potencializa tus ventas a partir de una tienda en línea y toma decisiones estratégicas a partir de gráficas y estadísticas.'},
                {image:service_ai, button:'Conocer más', title:'Inteligencia artificial', content:'Creamos servicios y aplicaciones capaces de dar pronósticos, detectar patrones y crear chatbots que mejorarán tus negocios.'},
                {image:service_cloudservices, button:'Conocer más', title:'Servicios en la nube', content:'Almacena toda tu información y procesos en la nube con los estándares más altos en seguridad, y despreocúpate por el viejo almacenamiento físico.'},
                {image:service_vr, button:'Conocer más', title:'Realidad aumentada', content:'Creamos nuevas soluciones y aplicaciones más interactivas que están transformando la manera de atraer clientes y de llevar a cabo diferentes procesos.'},
            ],
            button:'Conocer más'
        },
        sectionE:{
            title:"We're not the only ones  excited happy about Porto Template...",
            content:'30,000 CUSTOMERS IN 100 COUNTRIES USE PORTO TEMPLATE. MEET OUR CUSTOMERS.',
            solutions:[
                {   url:'/technologies',
                    title:'Innovadores en la industria', 
                    subtitle:'Tecnología e Innovación', image:imageA, 
                    button:'Conocer más',
                    content:'El desarrollo de software nunca se detiene y constantemente nacen nuevos lenguajes de programación. Es por ello que nos mantenemos a la vanguardia en el uso de las últimas tecnologías para ofrecer siempre las mejores soluciones a nuestros clientes, en el menor tiempo posible y con la mayor calidad.'},
                {   url:'/services',
                    title:'Creamos las mejores soluciones y productos que hacen crecer negocios', 
                    subtitle:'Construyendo con pasión', image:imageB, 
                    button:'Conocer más',
                    content:'A partir de metodologías validadas para el desarrollo de software, nos comprometemos con nuestros clientes a entregar soluciones y productos que se adapten a sus necesidades. Por ello es muy importante la relación cliente-desarrollador en cada etapa del proceso, para que ambas partes se involucren y el cliente final esté satisfecho.'},
            ]
        },
        about:{
            title:'Creamos increíbles productos y te ayudamos a hacer crecer tu negocio',
            content:[
                'Ofrecemos a nuestros clientes soluciones de software a la medida que se adaptan a sus necesidades y les permiten agregar valor e impulsar sus negocios.'
            ],
            steps:[
                {icon:'chat_bubble_outline', title:'Strategy', content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit'},
                {icon:'assignment', title:'Plan Everything', content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit'},
                {icon:'local_cafe', title:'Work Hard', content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit'},
                {icon:'favorite_border', title:'Deliver Quality', content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit'},
            ] 
        },
        sectionA:{
            nameA:'xand',
            nameB:'box',
            subtitle:'Cool Styles',
            title:'Creamos aplicaciones web y móviles que amarás',
            steps:[
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            ],
            button:'Learn More'
        },
        sectionF:{
            url:'/services',
            title:'Nuestra forma de trabajo',
            content:'Procesos',
            button:'Conocer más',
            data:[
                {number:1,title:'Planeación', icon:planning,content:'Se identifica el problema que el cliente quiere resolver y se levantan los requerimientos del proyecto para dar una proyección de recursos, costos, tiempo y beneficios.'},
                {number:2,title:'Análisis', icon:analysis,content:'Se realiza una investigación de sistemas similares ya existentes en el mercado para ofrecer valores agregados o diferenciadores. Además, se hace una primera lluvia de ideas interna y externa.'},
                {number:3,title:'Diseño', icon:design,content:'En esta etapa primero se diseñan bosquejos a mano (whiteboarding) con el cliente, se establece el plan de trabajo, se crean los planos del proyecto (blueprints) y finalmente se crea el flujo de la aplicación final junto con diseño (wireframing).'},
                {number:4,title:'Desarrollo', icon:programming,content:'Con los diseños generados en el wireframing se empieza a programar la aplicación a nivel base de datos, backend y frontend. '},
            ]
        }
    },
    english:{
        video:{
            nameA:'xand',
            nameB:'box',
            subname:'TECHNOLOGIES',
            phrase:'Coding the future!'
        },
        sectionC:{
            title:'We create web and mobile applications that you will love',
            data:[
                {icon:'add', title:'Years of experience', content:'5'},
                {icon:'add', title:'Completed projects', content:'100'},
                {icon:'add', title:'Customers', content:'24'},
                {icon:'', title:'Team members', content:'10'},
            ] 
        },
        sectionD:{
            title:'Our services',
            data:[
                {image:service_web, button:'LEARN MORE', title:'Web design', content:'We create websites that drive your business using the latest web development technologies and data analysis.'},
                {image:service_mobile, button:'LEARN MORE', title:'Mobile apps', content:'We develop mobile applications with native and hybrid technologies, covering the iOS and Android platforms.'},
                {image:service_ecommerce, button:'LEARN MORE', title:'E-commerce', content:'Grow and maximize sales from your online store and use the built in analytics to make strategic decisions.'},
                {image:service_ai, button:'LEARN MORE', title:'Artificial intelligence', content:'We create services and applications capable of forecasting, detecting patterns and utilizing chatbots that will help improve your business.'},
                {image:service_cloudservices, button:'LEARN MORE', title:'Services in the cloud', content:'Store all your information and processes in the cloud with the highest security standards and safeguards.'},
                {image:service_vr, button:'LEARN MORE', title:'Augmented reality', content:'We create new solutions and interactive applications that are transforming the way to attract customers and carry out your day to day activities.'},
            ],
            button:'Conocer más'
        },
        sectionE:{
            title:"We're not the only ones  excited happy about Porto Template...",
            content:'30,000 CUSTOMERS IN 100 COUNTRIES USE PORTO TEMPLATE. MEET OUR CUSTOMERS.',
            solutions:[
                {   url:'/technologies',
                    title:'Innovators in the industry', 
                    subtitle:'TECHNOLOGY AND INNOVATION', image:imageA, 
                    button:'Learn more',
                    content:'Software development never stops and new programming languages are constantly being born. That is why we remain at the forefront in the use of the latest technologies to always offer the best solutions to our customers, in the shortest possible time and with the highest quality.'},
                {   url:'/services',
                    title:'We create the best solutions and products that make businesses grow', 
                    subtitle:'BUILDING WITH PASSION', image:imageB, 
                    button:'Learn more',
                    content:'Based on validated methodologies for software development, we commit ourselves to deliver solutions and products that adapt to our client’s needs. We believe the client-developer relationship is very important in each stage of the process so that both parties get involved and the final customer is satisfied.'},
            ]
        },
        about:{
            title:'We create amazing products and help you grow your business',
            content:[
                'We offer our customers customized software solutions that adapt to their needs and allow them to add value and boost their business.'
            ],
            steps:[
                {icon:'chat_bubble_outline', title:'Strategy', content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit'},
                {icon:'assignment', title:'Plan Everything', content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit'},
                {icon:'local_cafe', title:'Work Hard', content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit'},
                {icon:'favorite_border', title:'Deliver Quality', content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit'},
            ] 
        },
        sectionA:{
            nameA:'xand',
            nameB:'box',
            subtitle:'Cool Styles',
            title:'Creamos aplicaciones web y móviles que amarás',
            steps:[
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            ],
            button:'Learn More'
        },
        sectionF:{
            url:'/services',
            title:'Our way of working',
            content:'Processes',
            button:'Learn more',
            data:[
                {number:1,title:'Planning', icon:planning,content:'The problem that the client wants to solve is identified and the requirements of the project are raised to give a projection of resources, costs, time and benefits.'},
                {number:2,title:'Analysis', icon:analysis,content:'An investigation is made of similar systems already existing in the market to offer aggregate or differentiating values. In addition, a first internal and external brainstorm is made.'},
                {number:3,title:'Design', icon:design,content:'At this stage, first sketches are drawn by hand (whiteboarding) with the client, the work plan is established, blueprints are created and finally the flow of the final application is created together with design (wireframing).'},
                {number:4,title:'Developing', icon:programming,content:'With the designs generated in the wireframing, the application starts to be programmed at the database level, back-end and front-end.'},
            ]
        }
    }
}