import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  appbar:{
    background: `transparent`,
    boxShadow:'none',
    padding: `${theme.spacing.unit*4}px 100px`,
    transition: theme.transitions.create(['padding','box-shadow','background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing.unit*1}px ${theme.spacing.unit*1}px`,
    },
  },
  appbar2:{
    background: fade(theme.palette.primary.main, 0.9),
    padding: `${theme.spacing.unit*1}px 100px`,
    transition: theme.transitions.create(['padding','box-shadow','background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing.unit*1}px ${theme.spacing.unit*1}px`,
    },
  },
  toolbar:{
    color:'white',
    fontSize:'20px',
    fontWeight:'600',
    background:'transparent',

  },
  imgContainer:{
    width:'48px',
    height:'58px',
    background:'rgba(255,255,255,.9)',
    borderRadius:'50%',
    padding:'8px 16px',
    opacity:0.9,
    transition: theme.transitions.create(['width','height'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.down('sm')]: {
      width:'30px',
      height:'40px',
    },
  },
  imgContainer2:{
    width:'40px',
    height:'50px',
    background:'rgba(255,255,255,.9)',
    borderRadius:'50%',
    padding:'8px 16px',
    opacity:0.9,
    transition: theme.transitions.create(['width','height'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.down('sm')]: {
      width:'30px',
      height:'40px',
    },

  },
  menuButton:{
    color:'white',
  },
  menuIcon:{
    fontSize:'150%'
  }

})

export default styles;
