import React from "react"
import { makeStyles, Slide, Typography, Zoom } from "@material-ui/core"
import InfoItem from "../InfoItem"
import image from '../../../../../../../assets/Home/IMAGE-VIRTUAL-AND-LIVE.png'
import WorkModal from "../WorkModal"



const Virtual = ({open, onClose}) => {

    const classes = useStyles()

    return(
        <WorkModal open={open} onClose={onClose}>
            <Typography variant='h3' className={classes.title} >Virtual & Live Stand Alone</Typography>
            <Zoom in timeout={750} style={{ transitionDelay:'500ms' }}>
            <div className={classes.challenge}>
                <InfoItem 
                    title='Challenge.'
                    info="The pharmaceutical industry is one of the most 
                    regulated worldwide. That's why it's really hard to 
                    be creative and disruptive in medical meetings 
                    and CME programs. "
                />
            </div>
            </Zoom>
            <Zoom in timeout={750} style={{ transitionDelay:'1000ms' }}>
            <div className={classes.solution}>
                <InfoItem 
                    title='Solution.'
                    info='ālthar created a cognitive, experiential-based, fully 
                    interactive, technology-focused, slide-free 
                    methodology and structure to realize stand-alone 
                    meetings (summits).'
                />
            </div>
            </Zoom>
            <Zoom in timeout={750} style={{ transitionDelay:'1500ms' }} >
            <div className={classes.result}>
                <InfoItem 
                    title='Result.'
                    info='In 2021 nearly 5k physicians evaluated our events 
                    with NPS of 5. We obtain more than 94% positive 
                    reviews and recognition among our clients. We 
                    create more than 14k follow-up communications 
                    ignited by the attendees to our events. '
                />
            </div>
            </Zoom>
            <Slide direction="up" in timeout={1000} >
                <div className={classes.img_container}>
                    <img src={image} width={'100%'} className={classes.img} />
                </div>
            </Slide>
            {/* <div className={classes.line} style={{top:260, width:220}} />
            <div className={classes.line} style={{top:460, width:200}} />
            <div className={classes.line} style={{top:620, width:180}} /> */}
        </WorkModal>
    )
}

export default Virtual

const useStyles = makeStyles(theme => ({
    root:{
      position:'relative',
      padding:70,
      color:'white',
      [theme.breakpoints.down('sm')]:{
        padding:32,
        }
    },
    title:{
        marginBottom:20,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem',
            textAlign:'center',
            //background:'red',
            width:'100%',
            marginTop:40,
            marginBottom:0
            }
    },
    challenge:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:380
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
  
    },
    result:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:380
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
     
    },
    solution:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:340
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
      
    },
    img:{
        transform:'scale(1.2)',
        /* [theme.breakpoints.down('lg')]:{
            transform:'rotate(8deg) scale(1)',
        }, */
        [theme.breakpoints.down('md')]:{
            transform:'scale(0.8)',
        },
        /* [theme.breakpoints.down('sm')]:{
            transform:'scale(1)',
            //background:'pink'
        }, */
    },
    img_container:{
        position:'absolute',
        top:160,
        right:'10%',
        [theme.breakpoints.down('lg')]:{
            right:'-5%'
        },
        [theme.breakpoints.down('md')]:{
            right:'-10%'
            //left:0,
            //background:'red',
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:0,
            right:'initial',
            margin:'auto',
            //height:700,
            //background:'blue'
        }
    },
    line:{
        position:'absolute',
        border:'1px solid white',
        left:'34%',
        [theme.breakpoints.down('lg')]:{
            left:'40%'
        },
        [theme.breakpoints.down(1500)]:{
            display:'none'
        },
    }

  }))



