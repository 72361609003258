import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';

import InViewMonitor from 'react-inview-monitor';


//************ SECTIONS COMPONENTS *************//
import SectionA from './Sections/SectionA/SectionA.jsx';
import SectionB from './Sections/SectionB/SectionB.jsx';
import SectionC from './Sections/SectionC/SectionC.jsx';
import SectionD from './Sections/SectionD/SectionD.jsx';
import SectionE from './Sections/SectionE/SectionE.jsx';
import SectionF from './Sections/SectionF/SectionF.jsx';



class HomeDesktop extends Component{

  state = {
    ready: false,
    visited:{
      sectionA: false,
      sectionB: false,
      sectionC: false,
      sectionD: false,
      sectionE: false,
      sectionF: false,
    }
  }

  componentDidMount(){
    this.setState({ready:true})
  }

  onInview = (event, item) => {
    let temp = {...this.state.visited};
    temp[item] = true;
    this.setState({visited:{...temp}})
  }

  render(){
  
    const {classes, content, sendToPage, goWork} = this.props;
    const {ready, visited} = this.state;
    
    return(
      <div className={classes.root}>
        <SectionA ready={ready} content={content}/>
        <InViewMonitor onInView={(event) => this.onInview(event, 'sectionB')} >
          <SectionB content={content} visited={visited.sectionB} sendToPage={sendToPage} goWork={goWork} />
        </InViewMonitor>
        {/* <InViewMonitor onInView={(event) => this.onInview(event, 'sectionC')} >
          <SectionC content={content.sectionC} visited={visited.sectionC}/>
        </InViewMonitor>
        <SectionD content={content.sectionD} sendToPage={sendToPage}/>
        <SectionE content={content.sectionE} sendToPage={sendToPage}/>
        <SectionF content={content.sectionF} sendToPage={sendToPage}/> */}
      </div>
      
    )
  }
}

export default withStyles(styles)(HomeDesktop);
