export const ruleValidation = (value, rules, value2) => {

  let response = false;

  if(value !== null){
    switch (rules.type) {
      case 'distance':
        if(value.length > rules.min-1 && value.length < rules.max + 1){
          response = true;
        }
        break;
      case 'email':
        const mailformat = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/
        if (value.match(mailformat)){
          response = true;
        }
        break;
      case 'equals':
        response = value ===  value2;
        break;
      case 'select':
        if(value !== '' && value !== null){
          response = true;
        }else{
          response = false;
        }
        break;
      case 'rfc':
        const rfcFormat = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        if(value.match(rfcFormat)){
          response = true;
        };
        break;
      case 'none':
        response = true;
        break;
      default:
    }
  }

  return response;
};

export const isFormValid = (data) => {
  let isValid = true;
  Object.keys(data).forEach((item,key) => {
    if(data[item].isRequired && !data[item].isValid){
      isValid = false;
    }
  })
  return isValid;
}