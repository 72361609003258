//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        padding:'100px 0px',
        background:'#4b6bf5'
    },
    container:{
        boxSizing:'border-box',
        width:'100%',
        margin:'auto',
        padding:'8px 16px',
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    title:{
        fontWeight:'600',
        color:'white'
    },
    about_content_container:{
        margin:'24px'
    },
    about_content:{
        color:grey[700],
        fontWeight:'400',
        fontSize:'1.1em'
    },
    image:{
        width:'100%',
    },
    imageContainer:{
        margin:'40px 250px'
    },
    item_title:{
        color:'white',
        fontWeight:'600',
        maxWidth:'100px',
    },
    item_number:{
        color:'white',
        fontWeight:'600'
    },
    item_icon:{
        color:'white',
        fontWeight:'600',
        fontSize:'2.2em'
    },
    dataContainer:{
        margin:'80px 0px',
        marginBottom:'0px',
        [theme.breakpoints.only('md')]: {
            margin:'100px 150px',
        },
    }
    
})

export default styles;