import React, { Component, useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Zoom, Icon, IconButton, useMediaQuery, Hidden } from '@material-ui/core';
import bg from '../../../../../../assets/FONDO.jpg';
import planet from '../../../../../../assets/Home/PLANETA.png';
import {Slide, Grow} from '@material-ui/core';
import WorkCard from './WorkCard.jsx';
import InViewMonitor from 'react-inview-monitor';
import Service from './Service'

//works
import gluck from '../../../../../../assets/Home/IMAGEN-GLUK.png'
import celu from '../../../../../../assets/Home/IMAGEN-TELEPHARMACY.png'
import digital from '../../../../../../assets/Home/IMAGEN-DIGITAL-THERAP.png'
import elearning from '../../../../../../assets/Home/LMS-ELEARNING.png'
import vr from '../../../../../../assets/Home/VR.png'
import virtual from '../../../../../../assets/Home/IMAGE-VIRTUAL-AND-LIVE.png'
import rwe from '../../../../../../assets/Home/IMAGE-RWE.png'
import physicians from '../../../../../../assets/Home/IMAGEN-PHYSICIANS-JOURNEY.png'
import advisor from '../../../../../../assets/Home/IMAGE-ADVISORY-BOARD.png'


//services
import health from '../../../../../../assets/Home/HEALTH-ICON.svg'
import strategy from '../../../../../../assets/Home/STRATEGY-ICON.svg'
import technology from '../../../../../../assets/Home/TECHNOLOGY-ICON.svg'
import button_services from '../../../../../../assets/Home/BOTON-SERVICES.png'
import WorkModal from './WorkModal.jsx';
import LMS from './workmodals/LMS.jsx';
import Digital from './workmodals/Digital.jsx';
import Gluck from './workmodals/Gluck.jsx';
import Telepharmacy from './workmodals/Telepharmacy.jsx';
import Vr from './workmodals/Vr.jsx';
import Virtual from './workmodals/Virtual.jsx';
import Advisory from './workmodals/Advisory.jsx';
import Physicians from './workmodals/Physicians.jsx';
import RWE from './workmodals/RWE.jsx';

const SectionB = ({classes, sendToPage, goWork}) => {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const content = [
        {img:gluck, style:{transform:'scale(0.7) translate(-25%, -14%) rotate(5deg)'}, title:'gluck'},
        {img:celu, style:{transform:'rotate(-12deg) scale(0.85) translate(40px, -20%)'}, title:'telepharmacy'},
        {img:digital, style:{transform:'rotate(-15deg) scale(0.7) translate(-7%, -27%)'}, title:'digital'},
        {img:elearning, style:{transform:'rotate(10deg) scale(0.6) translate(-48%, -36%)'}, title:'lms'},
        {img:vr, style:{transform:'rotate(18deg) scale(0.9) translate(-14%, -7%)'}, title:'vr'},
        {img:virtual, style:{transform:'rotate(4deg) scale(0.7) translate(-26%, -26%)'}, title:'virtual'},
        {img:advisor, style:{transform:'rotate(-10deg) scale(0.65) translate(-34%, -34%)'}, title:'advisor'},
        {img:physicians, style:{transform:'rotate(10deg) scale(0.7) translate(-24%, -14%)'}, title:'physicians'},   
        {img:rwe, style:{transform:'rotate(-10deg) scale(0.72) translate(-24%, -34%)'}, title:'rwe'},
    ]

    const services = [
        {title:'Technology', icon:technology},
        {title:'Strategy', icon:strategy},
        {title:'Health', icon:health},
    ]

    const [title_inview, setTitleInview] = useState(false)
    const [work_inview, setWorkInview] = useState([false, false, false, false, false, false, false, false, false])
    const [selected_view, setSelectedView] = useState(0)
    const [service_view, setServiceView] = useState(0)
    const [modals, setModals] = useState({
        gluck:false,
        telepharmacy:false,
        digital:false,
        lms:false,
        vr:false,
        virtual:false,
        advisor:false,
        physicians:false,
        rwe:false
    })

    /* const onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
      } */

    const onWorkInview = (ind) =>{
        let temp = [...content]
        temp[ind] = true
        setWorkInview(temp)
    }

    const onGoNext = () =>{
        if(sm ){
            console.log('sm')
            console.log(selected_view)
            setSelectedView(prev => prev +1)
        }
        /* else if(xs){
            console.log('xs')
            console.log(selected_view)
            setSelectedView(prev => prev +1)
        } */
        else{
            setSelectedView(prev => prev +3)
        }
        
    }

    const onGoBack = () =>{
        if(sm ){
            //console.log(selected_view)
            setSelectedView(prev => prev -1)
        }
        /* else if(xs){
            setSelectedView(prev => prev -1)
        } */
        else{
            setSelectedView(prev => prev -3)
        }
        
    }

    const onHandleModal = (key, status) => {
        let temp = {...modals}
        temp[key] = status
        setModals(temp)
    }

    
  

    return(
        
        <div className={classes.section} id='work' >
            <Gluck open={modals.gluck} onClose={()=>onHandleModal('gluck', false)} />
            <Telepharmacy open={modals.telepharmacy} onClose={()=>onHandleModal('telepharmacy', false)} />
            <LMS open={modals.lms} onClose={()=>onHandleModal('lms', false)} />
            <Digital open={modals.digital} onClose={()=>onHandleModal('digital', false)} />
            <Vr open={modals.vr} onClose={()=>onHandleModal('vr', false)} />
            <Virtual open={modals.virtual} onClose={()=>onHandleModal('virtual', false)} />
            <Advisory open={modals.advisor} onClose={()=>onHandleModal('advisor', false)} />
            <Physicians open={modals.physicians} onClose={()=>onHandleModal('physicians', false)} />
            <RWE open={modals.rwe} onClose={()=>onHandleModal('rwe', false)} />
            {/* <div style={{width:'100%'}} >
                <img  src={bg} />
            </div> */}
            <img src={planet} className={classes.planet} />
            <div className={classes.work_mask} >
                
                    <InViewMonitor onInView={()=>setTitleInview(true)} 
                    //onNotInView={()=>setTitleInview(false)} repeatOnInView 
                    >
                        <Slide direction="left" in={title_inview} timeout={1000}>
                            <Typography variant='h4' align='center' className={classes.work_title} >We design work that truly <span style={{color:'#00ADB5'}} >makes</span> a difference.</Typography>
                        </Slide>
                    </InViewMonitor>
                    <div className={classes.carrousel} >

                        {selected_view <= 0 ? <div style={{width:58}} ></div> :
                        <IconButton className={classes.button_arrow} style={{marginRight:20}} onClick={onGoBack}  >
                            <Icon style={{fontSize:32}} >navigate_before</Icon>
                        </IconButton>}
                    
                        <Grid container spacing={8} justifyContent='center' alignItems='center'>            
                            <Grid item md={4} sm={6} xs={12}   >
                                <InViewMonitor onInView={() => onWorkInview(selected_view)} >
                                    <Zoom in={work_inview[selected_view]} timeout={750} style={{ transitionDelay:`${500}ms` }} >
                                        <div>
                                            <WorkCard {...content[selected_view]} onClick={()=>onHandleModal(content[selected_view].title, true)}   />
                                        </div>
                                    </Zoom>
                                </InViewMonitor>
                            </Grid>
                            {sm ? null : 
                            <Grid item md={4} sm={6} xs={12}   >
                                <InViewMonitor onInView={() => onWorkInview(selected_view + 1)} >
                                    <Zoom in={work_inview[selected_view + 1]} timeout={750} style={{ transitionDelay:`${1000}ms` }} >
                                        <div>
                                            <WorkCard {...content[selected_view + 1]} onClick={()=>onHandleModal(content[selected_view + 1].title, true)}   />
                                        </div>
                                    </Zoom>
                                </InViewMonitor>
                            </Grid>}
                            {sm ? null :
                            <Grid item md={4} sm={6} xs={12}   >
                                <InViewMonitor onInView={() => onWorkInview(selected_view + 2)} >
                                    <Zoom in={work_inview[selected_view + 2]} timeout={750} style={{ transitionDelay:`${1500}ms` }} >
                                        <div>
                                            <WorkCard {...content[selected_view + 2]} onClick={()=>onHandleModal(content[selected_view + 2].title, true)} />
                                        </div>
                                    </Zoom>
                                </InViewMonitor>
                            </Grid>}
                            
                            {/* {content.map((item, ind) => {
                                if(ind <= selected_view*3 -1){
                                    return(
                                        <Grid item md={4} sm={6} xs={12}  key={ind} >
                                            <InViewMonitor onInView={() => onWorkInview(ind)} >
                                                <Zoom in={work_inview[ind]} timeout={750} style={{ transitionDelay:`${500*ind}ms` }} >
                                                    <div>
                                                        <WorkCard {...item} onClick={() => goWork('/work', ind)}  />
                                                    </div>
                                                </Zoom>
                                            </InViewMonitor>
                                        </Grid>
                                    )
                                }
                            }   
                            )} */}
                        </Grid>

                        {(selected_view >= 8 && sm) || (selected_view >=6 && !sm) ? <div style={{width:58}} ></div> : 
                        <IconButton className={classes.button_arrow} style={{marginLeft:20}} onClick={onGoNext}  >
                            <Icon style={{fontSize:32}} >navigate_next</Icon>
                        </IconButton>}
                                        
                        {/* selected_view >= 9 && xs ? null : selected_view >= 8 && sm ? null : selected_view >= 6 ? null : */}
                </div> 
                {/* <Grid container spacing={8} justifyContent='center'  style={{marginTop:70}}>
                    {content.map((item, ind) => 
                        <Grid item md={4} sm={6} xs={12}  key={ind} >
                            <InViewMonitor onInView={() => onWorkInview(ind)} >
                                <Zoom in={work_inview[ind]} timeout={750} style={{ transitionDelay:`${500*ind}ms` }} >
                                    <div>
                                        <WorkCard {...item} onClick={() => goWork('/work', ind)}  />
                                    </div>
                                </Zoom>
                            </InViewMonitor>
                        </Grid>
                    )}
                </Grid> */}
            </div>
            <div className={classes.services_section} >
                <div style={{ width:'80%'}} >
                    <div className={classes.services_text} >
                        <Typography variant='h4' align='center'  className={classes.services_title} >
                            We partner with our clients to solve complex
                            problems through human-centered design.
                        </Typography>
                    </div>
                    <div className={classes.services_container}>
                        <Hidden smDown >
                            <Grid container spacing={4} className={classes.services_grid} alignItems='center' style={{marginTop:20}} >
                                {services.map((item, ind) => 
                                    <Grid item key={ind.toString()} >
                                        <Service {...item} onClick={(event) => sendToPage(event,'/services')} />
                                    </Grid>
                                )}                
                            </Grid>  
                        </Hidden>
                        <Hidden mdUp>
                            <Grid container justifyContent='space-between' className={classes.services_grid} alignItems='center' style={{marginTop:20}} 
                               wrap='nowrap' >
                                <Grid item>
                                    {service_view <= 0 ? <div style={{width:58}} ></div> :
                                    <IconButton className={classes.button_arrow} style={{marginRight:20}} onClick={()=>setServiceView(prev=>prev-1)}  >
                                        <Icon style={{fontSize:32}} >navigate_before</Icon>
                                    </IconButton>}
                                </Grid>
                                <Grid item  >
                                    <Service {...services[service_view]} onClick={(event) => sendToPage(event,'/services')} />
                                </Grid>
                                <Grid item>
                                    {service_view >= 2 ? <div style={{width:58}} ></div> : 
                                    <IconButton className={classes.button_arrow} style={{marginLeft:20}} onClick={()=>setServiceView(prev=>prev+1)}  >
                                        <Icon style={{fontSize:32}} >navigate_next</Icon>
                                    </IconButton>}
                                </Grid>             
                            </Grid>
                        </Hidden>         
                        {/* <div style={{display:'flex', justifyContent:'center'}} >
                            <div className={classes.button_container} onClick={(event) => sendToPage(event,'/services')} >
                                
                                <Typography variant='h6' color='primary' >View more</Typography>
                            </div>
                        </div> */}
                        
                    </div>
                </div>
            </div>
            
        </div>
        
    )
    
}

export default withStyles(styles)(SectionB);