import axios from 'axios';

export const mailServer = {
    instance: axios.create({
        baseURL: "https://1iyrebz8r7.execute-api.us-east-1.amazonaws.com",
        headers: {
        'X-Api-Key': 'aHmKRnYlFT53jdF1mCum87AhdTW09XY1sP2cyYth',
        },
    }),
    /* credentials: {
        key: 'agbCMI6hTOBFFt0Uqa2g2z1lyGHGM9N5wHrBrq45'
    } */
}