import React, {Component} from 'react';
import {
  Hidden,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import HeaderDesktop from './HeaderDesktop/HeaderDesktop.jsx';
import HeaderMobile from './HeaderMobile/HeaderMobile.jsx';
import {contentData} from './content.jsx';

class Header extends Component{

  render(){

    const {mode, onChange, sections, menu, urls, history, language, onChangeLanguage} = this.props;
    const content = contentData['spanish'];

    let desktopView = (
      <Hidden smDown>
        <HeaderDesktop
          mode={mode}
          onChange={onChange}
          sections={sections}
          menu={menu}
          urls={urls}
          history={history}
          content={content}
          language={language}
          onChangeLanguage={onChangeLanguage}
        />
      </Hidden>
    )

    let mobileView = (
      <Hidden mdUp>
        <HeaderMobile
          mode={mode}
          onChange={onChange}
          sections={sections}
          menu={menu}
          urls={urls}
          history={history}
          language={language}
          onChangeLanguage={onChangeLanguage}
        />
      </Hidden>
    )


    return(
      <div style={{maxWidth:'100%', overflowX:'hidden'}}>
        {desktopView}
        {mobileView}
      </div>
    )
  }
}

export default withStyles(styles)(Header);
