import SectionA from "./sections/SectionA"
import React, { useState } from "react"
import SectionB from "./sections/SectionB"
import SectionC from "./sections/SectionC"
import SectionD from "./sections/SectionD"
import { useRef } from "react"
import CongratulationsSection from "./sections/CongratulationsSection"

const Resources = () => {

    const [show, setShow] = useState(false)
    
    const refC = useRef()
    const congratsRef = useRef()

    

    const onGoDown = () => {
        refC.current.scrollIntoView({behavior:'smooth'})    
    }

    const onGoDownCongrats = () => {
        console.log('Estamos para bajo')
        congratsRef.current.scrollIntoView({behavior:'smooth'})    
    }

    return(
        <div>
            {/*<SectionA />*/}
            <div>
                <SectionB onGoDown={onGoDown} />
            </div>
            <div ref={refC}>
                <SectionC onGoDown={onGoDownCongrats} onShowLastView={() => setShow(true)}/>
            </div>
            <div ref={congratsRef}>
                {show && <CongratulationsSection  />}
            </div>
            
            
            
            {/* <SectionD /> */}
        </div>
    )
}

export default Resources