//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';
//import bg from '../../../../../../assets/FONDO GALAXIA.jpg';

const styles = (theme) => ({
    section:{
        position:'relative',
        overFlowX:'hidden',
        color:'white',
        paddingBottom:60
        //backgroundImage:`url(${bg})`,
        //backgroundPosition:'center',
        //background:'red'
    },
    background_cont:{
        position:'absolute',
        top:0,
        left:0,
        height:'100%'
    },
    image:{
        height:'100%',
        width:'100%'
    },
    "@keyframes planetAnimation": {
        "0%": {
          transform: "rotate(360deg)"
        },
        "100%": {
          transform: "rotate(-360deg)"
        }
      },
    planet:{
        position:'absolute',
        transform:'translate(0px, -50%)',
        top:'55%',
        right:'-20%',
        zIndex:1,
        //animation:`$planetAnimation 50s linear infinite`,
    },
    work_mask:{
        //height:'100vh',
        position:'relative',
        //height:`calc(100vh - 76px)`,
        zIndex:10,
        width:'100%',
        background:'rgba(34, 40, 49, 0.8)',
        //opacity:0.8,
        //position:'absolute',
        //top:0,
        //left:0,
        padding:'8vh 60px ',
        boxSizing:'border-box',
        /* display:'flex',
        justifyContent:'center',
        alignItems:'center', */
        [theme.breakpoints.down('md')]:{
            padding:'100px 30px ',
            height:'100%'
            }
    },
    work_title:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.2rem'
            },
        [theme.breakpoints.down('xs')]:{
            fontSize:'1.6rem'
            }
    },
    services_title:{
        maxWidth:1000, 
        margin:'auto',
        fontSize:'4vh',
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.2rem'
            },
        [theme.breakpoints.down('xs')]:{
            fontSize:'1.6rem'
            }
        /* [theme.breakpoints.down('md')]:{
            fontSize:'5vh'
            }, */
        /* [theme.breakpoints.down('sm')]:{
            fontSize:'1.8rem'
            } */
    },
    services_text:{
        position:'relative',
        zIndex:10,
        //margin:'100px 0px'
    },
    services_container:{
        position:'relative',
        zIndex:10,
        borderRadius:70,
        //background:theme.palette.primary.main,
        background:'rgba(34, 40, 49, 0.8)',
        marginTop:'9vh',
        padding:'3%',
        textAlign:'center',
        [theme.breakpoints.down('sm')]:{
            borderRadius:32,
            margin:'30px',
            padding:'30px 20px'
            },
        [theme.breakpoints.down('xs')]:{
            margin:'30px 0px',
            padding:'30px 10px'
            },
        
    },
    services_grid:{
        justifyContent:'space-between',
        /* [theme.breakpoints.down('sm')]:{
            justifyContent:'center'
            } */
    },
    services_section:{
        //height:'100vh',
        height:`calc(100vh - 76px)`,
        //background:'red',
        //padding:'0px 60px',
        boxSizing:'border-box',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.down('md')]:{
            height:'100%',
            padding:'60px 0px'
            }
    },
    button_container:{
        //position:'relative',
        //cursor:'pointer'
        boxShadow:'0px 0px 54px 10px rgba(255,255,255,1)',
        borderRadius:28,
        padding:'0px 32px',
        background:'white',
        color:theme.palette.primary.main,
        cursor:'pointer',
        marginTop:40,
        //margin:'auto'
    },
    button_text:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)',
        zIndex:10
    },
    button_arrow:{
        width:16,
        height:16,
        padding:10,
        borderRadius:'50%',
        border:'1px solid white',
        display:'flex',
        color:'white',
        boxSizing:'content-box',
        justifyContent:'center',
        alignItems:'center',
        '&:disabled':{
            borderColor:grey[500],
            color:grey[500]
        }
    },
    carrousel:{
        display:'flex', 
        alignItems:'center',
        //background:'red',
        marginTop:70
    }
    
})

export default styles;