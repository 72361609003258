import React from "react"
import { makeStyles, Typography } from "@material-ui/core"


const InfoItem = ({title, info}) => {

    const classes = useStyles()

    return(
        <div >
            <Typography  className={classes.title} >{title}</Typography>
            <Typography style={{fontSize:'2vh'}} >{info}</Typography>
        </div>
    )
}

export default InfoItem

const useStyles = makeStyles(theme => ({
    title:{
      fontSize:'3vh',
      fontWeight:600,
      marginBottom:16,
      marginTop:20,
      [theme.breakpoints.down('sm')]:{
        marginBottom:0
    }
    },
}))
