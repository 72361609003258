import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Icon, Slide, Grow } from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';


class SectionC1 extends Component{

    state = {
        title:[false,false],
        items:[false,false,false,false]
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.props.content !== nextProps.content ||
                JSON.stringify(this.state) !== JSON.stringify(nextState)
    }

    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    }
  


    render(){

        const {classes, content} = this.props;
        const {title, items} = this.state;

        return(
            <div className={classes.section}>
                <div className={classes.container}>
                    <Grid container  alignItems='center'>
                        <Grid item xs={12}> 
                            <Grid container direction='column' alignItems='center'>
                                <Grid item>
                                    <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                                        <Slide direction="left" in={title[0]} timeout={1000}>
                                            <Typography variant='h4' align='center' className={classes.title}>
                                                {content.title}
                                            </Typography>
                                            
                                        </Slide>
                                    </InViewMonitor>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='column' alignItems='center'>
                                <Grid item>
                                    <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                                        <Slide direction="right" in={title[1]} timeout={1000}>
                                            <div className={classes.divider}/>
                                        </Slide>
                                    </InViewMonitor>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <Grid item xs={12}>  
                            <div className={classes.dataContainer}>
                                <Grid container>
                                    {
                                        content.data.map((item,key)=>{
                                            return(
                                                <Grid item key={key.toString()} xs={3}>
                                                    <InViewMonitor onInView={(event) => this.onInview(event, 'items', key)} >
                                                        <Grow in={items[key]} timeout={700 + 250*(key)}>
                                                            <Grid container direction='column' spacing={1}>
                                                                <Grid item>
                                                                    <Grid container justify='center' alignItems='center'>
                                                                        <Grid item>
                                                                            <Typography variant='h4' align='center' className={classes.item_number}>
                                                                                {item.content}
                                                                            </Typography>
                                                                        </Grid>
                                                                        {
                                                                            item.icon ? (
                                                                                <Grid item>
                                                                                    <Icon className={classes.item_icon}>
                                                                                        {item.icon}
                                                                                    </Icon>
                                                                                </Grid>
                                                                            ) : (
                                                                                null
                                                                            )
                                                                        }
                                                                        
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container justify='center'>
                                                                        <Grid item>
                                                                            <Typography 
                                                                            variant='body1' 
                                                                            align='center' 
                                                                            className={classes.item_title}
                                                                            >
                                                                                {item.title}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    
                                                                </Grid>
                                                            </Grid>
                                                        </Grow>
                                                    </InViewMonitor>
                                                </Grid>
                                            )
                                            
                                        })
                                    }
                                </Grid>
                            </div> 
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionC1);