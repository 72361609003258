import React from 'react'
import { TextField, withStyles } from '@material-ui/core'
import { ruleValidation } from './customFunctions'

const CssTextField = withStyles({
    root: {
      '& .MuiInputBase-root':{
         color:'white',
         background:'rgba(255,255,255, 0.45)',
         border:'none'
        //fontSize:'1.5rem'
      },
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:16,
        
        '& fieldset': {
          borderColor: 'transparent',
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
      },
      '& .MuiFormLabel-root':{
        color:'white',
        
        
      },
      '& .MuiInputLabel-outlined':{
        transform: 'translate(16px, 20px) scale(1)'
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
        transform: 'translate(16px, -12px) scale(0.7)'
      },
      '& .MuiOutlinedInput-input':{
        //paddingLeft:24
      },
      '& .MuiFilledInput-root':{
        //backgroundColor:'#F6F6F6',
        //backgroundColor:'white',
        //borderRadius:5,
      },
      '& .MuiFilledInput-underline:before':{
        borderBottom:'none'
      },
      '& .MuiFilledInput-underline:after':{
        borderBottom:'none'
      },
      /* '& .MuiFilledInput-underline.Mui-error:after':{
        borderColor:'#f44336'
      } */
    },
  })(TextField);

const InputFormFilled = props => {

    const {data, onChange, startAdornment, endAdornment, onKeyUp, onKeyDown, onKeyPress, noLabel, variant} = props

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited} = data

    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(noLabel){
      delete interConfig.label
      delete interConfig.placeholder
    }
    if(!isError) interConfig.helperText = ''


    return(
        <div>
            <CssTextField  value={value} variant={variant ? variant : 'outlined'} onKeyPress={onKeyPress}
            error={isError} {...interConfig} onChange={onInnerChange} 
            InputProps={
                {
                    startAdornment:startAdornment ? startAdornment : undefined,
                    endAdornment:endAdornment ? endAdornment : undefined,
                    onKeyUp:onKeyUp ? onKeyUp : undefined,
                    onKeyDown:onKeyDown ? onKeyDown : undefined,
                }}
            />
        </div>
    )
}

export default InputFormFilled