import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Fade } from '@material-ui/core';

//services
import health from '../../../../../../assets/Home/HEALTH-ICON.svg'
import strategy from '../../../../../../assets/Home/STRATEGY-ICON.svg'
import technology from '../../../../../../assets/Home/TECHNOLOGY-ICON.svg'
import ServiceCard from './ServiceCard.jsx';
import up from '../../../../../../assets/CLICK-UP.png';
import down from '../../../../../../assets/CLICK-DOWN.png';


const SectionA = ({classes}) =>{

    const services = [
        {title:'Technology', icon:technology, subtitle:'Digital', 
        content:[
            'Web Development & Optimization',
            'Apps Development',
            'Bots Development',
            'LMS & e-Learning',
            'Content Platforms',
            'KOL Management',
            'Social Media Management',
            'VR & AR',
            'e-Sampling'
        ]},
        {title:'Strategy', icon:strategy, subtitle:'Marketing',
        content:[
            'Understand your Market',
            'Build your Brand',
            'Launch Successfully',
            'Optimize Values',
            'Communication Campaign',
            'Value Proposition Frames',
            'Competitive Frames',
        ]},
        {title:'Health', icon:health, subtitle:'Medical',
        content:[
            'Medical Marketing',
            'Pharmacovigilance',
            'Pharmacoeconomics',
            'KOL Management',
            'Market Access',
            'MSL Training',
            'Adboards',
            'RWD / RWE',
        ]},
    ]

    return(
        <div className={classes.root}>

            <div className={classes.background}/>
            <div className={classes.textContainer}>
                <div style={{width:'100%'}}>
                    <Grid container direction='column' alignItems='center' spacing={1}>
                        <Grid item>
                            <Fade in timeout={1500}>
                                <Typography variant='h3' className={classes.title} >
                                    OUR SERVICES
                                </Typography>
                            </Fade>    
                        </Grid>
                        <Grid item>
                            <Fade in timeout={3000}>
                                <div className={classes.divider} />                   
                            </Fade>  
                        </Grid>
                    </Grid>
                </div>            
            </div>
            <div className={classes.services_container} >
                <Grid container justifyContent='center' spacing={4}  >
                    {services.map((item, ind) =>
                        <Grid item key={item.title} md={4} sm={6} xs={12}   >
                            <ServiceCard {...item} />
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    )
    
}

export default withStyles(styles)(SectionA);