import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import{Typography, Grid, Fade, Button } from '@material-ui/core';
import doctors_want from '../../../../assets/Resources/LOGO-QQLM23.png'
import plus_button from '../../../../assets/ICONO-MAS-2.png'




const SectionA = () =>{

    const classes = useStyles()

    const scrolltoId = () =>{
        var ele = document.getElementById("resources2");
        /* window.scrollTo({
        top: access.scrollTop,
        left: access.scrollLeft}); */
        window.scrollTo(ele.offsetLeft,ele.offsetTop)
    }

    return(
        <div className={classes.root}>
            {/*<div className={classes.background}/>
            <div className={classes.textContainer}>
                <div style={{width:'100%'}}>
                    <Grid container direction='column' alignItems='center' spacing={1}>
                        <Grid item>
                            <Fade in timeout={1500}>
                                <div>
                                    <Typography variant='h3' className={classes.title} >
                                        OUR RESOURCES.
                                    </Typography>
                                    
                                </div>
                            </Fade>    
                        </Grid>
                        <Grid item>
                            <Fade in timeout={3000}>
                               <div className={classes.divider} />                    
                            </Fade>  
                        </Grid>
                    </Grid>
                </div>            
            </div>
            <div className={classes.services_container} >
                <Grid container justifyContent='center' spacing={5}>
                    <Grid item md={6} xs={12}>
                        <div className={classes.card} >
                            <div className={classes.card_content} >
                                <Typography className={classes.academy} >academy</Typography>                      
                            </div>  
                            <div className={classes.card_button} >
                                <Button className={classes.soon_button}>
                                    <Typography variant='h6' >Soon</Typography>
                                </Button>    
                            </div>           
                        </div>
                    </Grid>
                    <Grid item md={6}  xs={12}>
                        <div className={classes.card} >
                            <div className={classes.card_content} >
                                <img src={doctors_want} width={'100%'} />                     
                            </div>  
                            <div className={classes.card_button} style={{bottom:'7%', cursor:'pointer'}} onClick={scrolltoId}  >
                                <img src={plus_button}  />    
                            </div> 
                        </div>
                    </Grid>
                </Grid>
    </div>*/}
        </div>
    )
    
}

export default SectionA;


const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        //overflow:'hidden'
        minHeight:120,
        //background:'red'
        [theme.breakpoints.down('sm')]:{
            height:'100%',
            minHeight:80,
        },

    },
    academy:{
        fontSize:'10vh',
        [theme.breakpoints.down('md')]:{
            fontSize:'3rem'
            },
        [theme.breakpoints.down('xs')]:{
            fontSize:'10vw'
            }
    },
    background:{
        position:'absolute',
        //background:'red',
        //backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(${background})`,
        width:'100%',
        height: '100%',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        zIndex:-1,
    },
    textContainer:{
        width:'100%',
        position:'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color:'white',
        zIndex:10,
        padding:'20vh 0px 0px',
        [theme.breakpoints.down('sm')]:{
            padding:'100px 32px 0px',
            boxSizing:'border-box',
            textAlign:'center',
            //background:'red'
            }
        //paddingTop:'300px'
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem'
            },
        [theme.breakpoints.down('xs')]:{
            fontSize:'2rem'
            }
    },
    divider:{
        height:16,
        width:100,
        borderRadius:24,
        background:theme.palette.primary.main,
        margin:'12px 0px'
    },
    services_container:{
        padding:'50px 100px',
        //background:'red',
        boxSizing:'border-box',
        [theme.breakpoints.down('xs')]:{
            padding:'50px',
            }
    },
    card:{
        background:'rgb(34, 40, 49, 0.8)',
        borderRadius:60,
        //padding:'100px 70px 12px',
        paddingTop:'50vh',
        color:'white',
        position:'relative'
    },
    card_content:{
        position:'absolute',
        top:'45%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    },
    card_button:{
        position:'absolute',
        bottom:'10%',
        left:'50%',
        transform:'translateX(-50%)'
    },
    soon_button:{
        border:`1px solid ${theme.palette.primary.main}`,
        color:theme.palette.primary.main,
        padding:'8px 100px',
        borderRadius:32,
        textTransform:'none',
        [theme.breakpoints.down('xs')]:{
            width:'100%',
            padding:'8px 40px'
            }

    }
}))