import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid } from '@material-ui/core';
import {Slide, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';


import WorkFeature from '../../../../../../components/About/WorkFeature/WorkFeature.jsx';
import scrum from '../../../../../../assets/About/scrum.png'
import agile from '../../../../../../assets/About/agile.png'
import devops from '../../../../../../assets/About/devops.png'


const intervalTime = 4000;
const maxCount = 2;

class SectionB extends Component{

    state = {
      title:[false,false, false],
      count:0
    }

    shouldComponentUpdate(nextProps, nextState){
      return this.props.content !== nextProps.content ||
              JSON.stringify(this.state) !== JSON.stringify(nextState)
    }

    componentDidMount(){
      this.timer = setInterval(this.changeImageByTime,intervalTime)
    }

    componentWillUnmount(){
      clearInterval(this.timer)
    }

    changeImageByTime = () => {
      let temp = this.state.count;
      temp = temp === maxCount ? 0 : temp + 1;
      this.setState({count:temp})
    }

    directChangeImage = (number) => {
      clearInterval(this.timer)
      this.timer = setInterval(this.changeImageByTime,intervalTime)
      this.setState({count:number})
    }

    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    }

    render(){

        const {classes, content} = this.props;
        const {title, count} = this.state;

        let image2show = null;
        switch(count){
          case 0:
            image2show = agile;
            break;
          case 1:
            image2show = scrum;
            break;
          case 2:
            image2show = devops;
            break;
          default:
            break;
        }

        return(
            
            <div  className={classes.section}>
                
              <div className={classes.container}>
              
                <Grid container align='center'>
                  <Grid item xs={12}>  
                    <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                      <Slide direction='left' in={title[0]} timeout={1000}>
                        <Typography variant='h4' align='center' className={classes.title}>
                            {content.title}
                        </Typography>
                      </Slide>
                    </InViewMonitor>
                  </Grid>
                  <Grid item xs={12}>
                    <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                      <Slide direction='right' in={title[1]} timeout={1000}>
                          <div className={classes.divider}/>
                      </Slide>
                    </InViewMonitor>
                  </Grid>
                  <Grid item xs={12}>
                  <InViewMonitor onInView={(event) => this.onInview(event, 'title', 2)} >
                    <Grow in={title[2]} timeout={1000}>
                      <div className={classes.content_container}>
                        <Typography align='center' className={classes.content}>
                          {content.content}
                        </Typography>
                      </div>
                    </Grow>
                  </InViewMonitor>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.subcontainer}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={4}>
                              <div onClick={() => this.directChangeImage(0)}>
                                <WorkFeature data={content.data[1]} color={1} selected={count === 0}/>      
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div onClick={() => this.directChangeImage(1)}>
                                <WorkFeature data={content.data[0]} color={0} selected={count === 1}/>      
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div onClick={() => this.directChangeImage(2)}>
                                <WorkFeature data={content.data[2]} color={2} selected={count === 2}/>      
                              </div>
                            </Grid>
                            
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.circle}>
                            <img src={image2show} alt={''} className={classes.image}/>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                    
                </Grid>
              </div>
            </div>
            
        )
    }
}

export default withStyles(styles)(SectionB);