import React, { useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, IconButton, Icon, useMediaQuery, Zoom } from '@material-ui/core';
import {Slide, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';
import cx from 'classnames';


/* import TechItem from '../../../../../../components/Technologies/TechItem/TechItem.jsx';
import webdev from '../../../../../../assets/Technologies/webdev.png';
import Gluck from './services/Gluck.jsx';
import Telepharmacy from './services/Telepharmacy.jsx';
import Digital from './services/Digital.jsx';
import Vr from './services/Vr.jsx';
import Advisory from './services/Advisory.jsx';
import LMS from './services/LMS.jsx';
import Virtual from './services/Virtual.jsx';
import Physicians from './services/Physicians.jsx';
import RWE from './services/RWE.jsx';
import WorkCard from './WorkCard.jsx'; */

//works
import gluck from '../../../../../../assets/Home/IMAGEN-GLUK.png'
import celu from '../../../../../../assets/Home/IMAGEN-TELEPHARMACY.png'
import digital from '../../../../../../assets/Home/IMAGEN-DIGITAL-THERAP.png'
import elearning from '../../../../../../assets/Home/LMS-ELEARNING.png'
import vr from '../../../../../../assets/Home/VR.png'
import virtual from '../../../../../../assets/Home/IMAGE-VIRTUAL-AND-LIVE.png'
import rwe from '../../../../../../assets/Home/IMAGE-RWE.png'
import physicians from '../../../../../../assets/Home/IMAGEN-PHYSICIANS-JOURNEY.png'
import advisor from '../../../../../../assets/Home/IMAGE-ADVISORY-BOARD.png'

import LMS from './workmodals/LMS.jsx';
import Digital from './workmodals/Digital.jsx';
import Gluck from './workmodals/Gluck.jsx';
import Telepharmacy from './workmodals/Telepharmacy.jsx';
import Vr from './workmodals/Vr.jsx';
import Virtual from './workmodals/Virtual.jsx';
import Advisory from './workmodals/Advisory.jsx';
import Physicians from './workmodals/Physicians.jsx';
import RWE from './workmodals/RWE.jsx';
import WorkCard from './WorkCard.jsx';


const SectionB = ({classes, work, onGoToWork}) => {

    //const [selected, setSelected] = useState(0)

    /* state = {
      title:[false,false, false],
      techs:[false,false,false,false,false,false,false,false]
    }

    shouldComponentUpdate(nextProps, nextState){
      return this.props.content !== nextProps.content ||
              JSON.stringify(this.state) !== JSON.stringify(nextState)
    } */

    /* onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    } */

    //const {classes, content} = this.props;  
    //const {title, techs} = this.state;

    /* const works = [
      <Gluck />,
      <Telepharmacy />,
      <Digital />,
      <LMS />,
      <Vr />,
      <Virtual />,
      <Advisory />,
      <Physicians />,
      <RWE />
    ] */

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    const [selected_view, setSelectedView] = useState(0)
    const [work_inview, setWorkInview] = useState([false, false, false, false, false, false, false, false, false])
    const [modals, setModals] = useState({
        gluck:false,
        telepharmacy:false,
        digital:false,
        lms:false,
        vr:false,
        virtual:false,
        advisor:false,
        physicians:false,
        rwe:false
    })

    const onWorkInview = (ind) =>{
        let temp = [...content]
        temp[ind] = true
        setWorkInview(temp)
    }

    const onGoNext = () =>{
        if(sm ){
            console.log('sm')
            console.log(selected_view)
            setSelectedView(prev => prev +1)
        }
        /* else if(xs){
            console.log('xs')
            console.log(selected_view)
            setSelectedView(prev => prev +1)
        } */
        else{
            setSelectedView(prev => prev +3)
        }
        
    }

    const onGoBack = () =>{
        if(sm ){
            //console.log(selected_view)
            setSelectedView(prev => prev -1)
        }
        /* else if(xs){
            setSelectedView(prev => prev -1)
        } */
        else{
            setSelectedView(prev => prev -3)
        }
        
    }

  const onHandleModal = (key, status) => {
      let temp = {...modals}
      temp[key] = status
      setModals(temp)
  }


  const content = [
    {img:gluck, style:{transform:'scale(0.7) translate(-25%, -14%) rotate(5deg)'}, title:'gluck'},
    {img:celu, style:{transform:'rotate(-12deg) scale(0.85) translate(40px, -20%)'}, title:'telepharmacy'},
    {img:digital, style:{transform:'rotate(-15deg) scale(0.7) translate(-7%, -27%)'}, title:'digital'},
    {img:elearning, style:{transform:'rotate(10deg) scale(0.6) translate(-48%, -36%)'}, title:'lms'},
    {img:vr, style:{transform:'rotate(18deg) scale(0.9) translate(-14%, -7%)'}, title:'vr'},
    {img:virtual, style:{transform:'rotate(4deg) scale(0.7) translate(-26%, -26%)'}, title:'virtual'},
    {img:advisor, style:{transform:'rotate(-10deg) scale(0.65) translate(-34%, -34%)'}, title:'advisor'},
    {img:physicians, style:{transform:'rotate(10deg) scale(0.7) translate(-24%, -14%)'}, title:'physicians'},   
    {img:rwe, style:{transform:'rotate(-10deg) scale(0.72) translate(-24%, -34%)'}, title:'rwe'},
  ]

      return(
          
          <div  className={classes.section}>
            <Gluck open={modals.gluck} onClose={()=>onHandleModal('gluck', false)} />
            <Telepharmacy open={modals.telepharmacy} onClose={()=>onHandleModal('telepharmacy', false)} />
            <LMS open={modals.lms} onClose={()=>onHandleModal('lms', false)} />
            <Digital open={modals.digital} onClose={()=>onHandleModal('digital', false)} />
            <Vr open={modals.vr} onClose={()=>onHandleModal('vr', false)} />
            <Virtual open={modals.virtual} onClose={()=>onHandleModal('virtual', false)} />
            <Advisory open={modals.advisor} onClose={()=>onHandleModal('advisor', false)} />
            <Physicians open={modals.physicians} onClose={()=>onHandleModal('physicians', false)} />
            <RWE open={modals.rwe} onClose={()=>onHandleModal('rwe', false)} />        
            {/* <div className={classes.carrousel} >
              {works[work]}
              <div className={classes.carr_bar}>
                <Grid container justifyContent='center' spacing={1}>
                  {works.map((item, ind) => 
                    <Grid item key={ind.toString()}>
                      <div className={cx({[classes.carr_item]:true, [classes.carr_item_selected]:work === ind})}
                        onClick={()=>onGoToWork(ind)}
                      ></div>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div> */}
            <div className={classes.carrousel} >
                {selected_view <= 0 ? <div style={{width:58}} ></div> :
                <IconButton className={classes.button_arrow} style={{marginRight:20}} onClick={onGoBack}  >
                    <Icon style={{fontSize:32}} >navigate_before</Icon>
                </IconButton>}

                <Grid container spacing={8} justifyContent='center' alignItems='center'>            
                    <Grid item md={4} sm={6} xs={12}   >
                        <InViewMonitor onInView={() => onWorkInview(selected_view)} >
                            <Zoom in={work_inview[selected_view]} timeout={750} style={{ transitionDelay:`${500}ms` }} >
                                <div>
                                    <WorkCard {...content[selected_view]} onClick={()=>onHandleModal(content[selected_view].title, true)}   />
                                </div>
                            </Zoom>
                        </InViewMonitor>
                    </Grid>
                    {sm ? null : 
                    <Grid item md={4} sm={6} xs={12}   >
                        <InViewMonitor onInView={() => onWorkInview(selected_view + 1)} >
                            <Zoom in={work_inview[selected_view + 1]} timeout={750} style={{ transitionDelay:`${1000}ms` }} >
                                <div>
                                    <WorkCard {...content[selected_view + 1]} onClick={()=>onHandleModal(content[selected_view + 1].title, true)}   />
                                </div>
                            </Zoom>
                        </InViewMonitor>
                    </Grid>}
                    {sm ? null :
                    <Grid item md={4} sm={6} xs={12}   >
                        <InViewMonitor onInView={() => onWorkInview(selected_view + 2)} >
                            <Zoom in={work_inview[selected_view + 2]} timeout={750} style={{ transitionDelay:`${1500}ms` }} >
                                <div>
                                    <WorkCard {...content[selected_view + 2]} onClick={()=>onHandleModal(content[selected_view + 2].title, true)} />
                                </div>
                            </Zoom>
                        </InViewMonitor>
                    </Grid>}
                </Grid>

                {(selected_view >= 8 && sm) || (selected_view >=6 && !sm) ? <div style={{width:58}} ></div> : 
                <IconButton className={classes.button_arrow} style={{marginLeft:20}} onClick={onGoNext}  >
                    <Icon style={{fontSize:32}} >navigate_next</Icon>
                </IconButton>}
                </div> 
          </div>
            
        )
    
}

export default withStyles(styles)(SectionB);


{/* <Grid container align='center'>
                <Grid item xs={12}>  
                  <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                    <Slide direction='left' in={title[0]} timeout={1000}>
                      <Typography variant='h4' align='center' className={classes.title}>
                          {content.title}
                      </Typography>
                    </Slide>
                  </InViewMonitor>
                </Grid>
                <Grid item xs={12}>
                  <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                    <Slide direction='right' in={title[1]} timeout={1000}>
                        <div className={classes.divider}/>
                    </Slide>
                  </InViewMonitor>
                </Grid>
                <Grid item xs={12}>
                <InViewMonitor onInView={(event) => this.onInview(event, 'title', 2)} >
                  <Grow in={title[2]} timeout={1000}>
                    <div className={classes.content_container}>
                      <Typography align='center' className={classes.content}>
                        {content.content}
                      </Typography>
                    </div>
                  </Grow>
                </InViewMonitor>
                </Grid>
                <Grid item xs={12}>
                  <InViewMonitor onInView={(event) => this.onInview(event, 'techs', 0)} >
                      <div className={classes.subcontainer}>
                          <Grow in={techs[0]} timeout={1000}>
                              <div className={classes.javascript}>
                                  <TechItem data={content.data[0]}/>
                              </div>
                          </Grow>
                          <Grow in={techs[0]} timeout={1000}>
                              <div className={classes.react}>
                                  <TechItem data={content.data[1]}/>
                              </div>
                          </Grow>
                          <Grow in={techs[0]} timeout={1000}>
                              <div className={classes.angular}>
                                  <TechItem data={content.data[2]}/>
                              </div>
                          </Grow>
                          <Grow in={techs[0]} timeout={1000}>
                              <div className={classes.php}>
                                  <TechItem data={content.data[3]}/>
                              </div>
                          </Grow>
                          <Grow in={techs[0]} timeout={1000}>
                              <div className={classes.python}>
                                  <TechItem data={content.data[4]} right={true}/>
                              </div>
                          </Grow>
                          <Grow in={techs[0]} timeout={1000}>
                              <div className={classes.node}>
                                  <TechItem data={content.data[5]} right={true}/>
                              </div>
                          </Grow>
                          <Grow in={techs[0]} timeout={1000}>
                              <div className={classes.ruby}>
                                  <TechItem data={content.data[6]} right={true}/>
                              </div>
                          </Grow>
                          <Grow in={techs[0]} timeout={1000}>
                              <img src={webdev} alt={''} className={classes.image}/>
                          </Grow>
                      </div>
                  </InViewMonitor>
                </Grid>
              </Grid> */}