import { useEffect, useState } from "react"
import { isFormValid, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm, sleep } from "../../../../shared/utility"
import axios from 'axios';
import report from '../../../../assets/Resources/Reporte_althar.pdf'
import FileSaver from 'file-saver'
import { mailServer } from "../../../../config";


/* const mailServer = {
    instance: axios.create({
        baseURL: "https://jna02sfp70.execute-api.us-east-1.amazonaws.com",
        headers: {
        'X-Api-Key': 'agbCMI6hTOBFFt0Uqa2g2z1lyGHGM9N5wHrBrq45',
        },
    }),
    credentials: {
        key: 'agbCMI6hTOBFFt0Uqa2g2z1lyGHGM9N5wHrBrq45'
    }
} */

const useSectionC = ({ onGoDown, onShowLastView}) => {

    //const {id} = useParams()
    //console.log(id)

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [snack, setSnack] = useState(false)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    const [user, setUser] = useState(null)

    const [modals, setModals] = useState({
        report:false,
    })

    const [active_not, setActiveNot] = useState(true)

    useEffect(() => {
        actions.onInitModule()
    }, [])

  

    const actions = {
        onInitModule: async() => {
            setError(null)
            setActiveNot(true)
            setForm(JSON.parse(JSON.stringify(formData)))
            /* try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const _user = await private_server.get(`/user/${id}`)
                setUser(_user.data.data.user)
                let _form = onInitForm(form, _user.data.data.user, content.form)
                //_form.user_type_id.options = catalogs.user_types
                _form.mobile_country_code.options = [{value:"+52", label:"+52"}]
                setForm(_form)
                setLoading(false)       
            } catch (error) {
                setLoading(false)
                console.log(error)
                const _error = onGetErrorMessage(error)
                onError(_error ? _error.message : null)
                //setError(_error ? _error.message : null)
            } */
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)
        },
        onUpdateModal: (_key, _value) => {
            let _modals = {...modals, [_key]:_value}
            setModals(_modals)
        },
        onScrollToSubmit: () =>{
            var ele = document.getElementById("resources4");
            /* window.scrollTo({
            top: access.scrollTop,
            left: access.scrollLeft}); */
            window.scrollTo(ele.offsetLeft,ele.offsetTop)
        },
        onDownloadReport: () => {
         
            //const pdf = await private_server.get(`/order/${id}/invoice/download/pdf/me`, {responseType: 'blob'})
            //window.open('/assets/Reporte_althar.pdf', '_blank')
            FileSaver.saveAs('/assets/Reporte_althar.pdf', `ālthar ¿Qué quieren los médicos? 2023`)
        },
        onSubmit: async () => {
            //actions.onDownloadReport()
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
    
            //Extract data
            let data2send = onGetFormData(form)
            data2send.accept_notifications = active_not ? 'si' : 'no'
            data2send.type = 'Solicitud de reporte'
            console.log(data2send)
            setSending(true)
            try {
                //updateAuthorizationHeader(private_server)
                //await private_server.patch(`/user/${id}`, data2send)
                await mailServer.instance.post("/dev/contact-email", data2send);
                
                actions.onInitModule()
                //actions.onUpdateModal('report', true)
                
                actions.onDownloadReport()
                onShowLastView()
                await sleep(500)
                onGoDown()
                
            } catch (error) {
                console.log(error) 
                setError('There was an error')
            }
            setSending(false)
            

        },
        onHandleActiveNot: () => setActiveNot(val => !val),
        onClearError: ()=> setError(null)
    }

    const system = {loading, sending}
   

    return {system, form, user, actions, modals, active_not}
}

export default useSectionC

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label:'Nombre',
            helperText: 'Required field',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label:'Apellidos',
            helperText: 'Required field',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label:'Mail profesional',
            helperText: 'Email inválido',
        },
        rules: {
            type: 'email',
        }
    },
    /* company: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'company',
            type: 'text',
            fullWidth: true,
            label:'Nombre de empresa',
            helperText: 'Required field',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    position: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'position',
            type: 'text',
            fullWidth: true,
            label:'Cargo',
            helperText: 'Required field',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    company_size: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'company_size',
            type: 'text',
            fullWidth: true,
            label:'Tamaño de empresa (número de empleados)',
            //multiline: true,
            //rows:7,
            helperText: 'Required field',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    }, */
    /* phone_number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'phone_number',
            type: 'number',
            fullWidth: true,
            label: 'Phone number',
            helperText: 'Invalid phone number',
        },
        rules: {
            type: 'phone',
        }
    },
    topic: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'topic',
            type: 'text',
            fullWidth: true,
            label:'Topic'
        },
        rules: {
            type: 'distance',
            min: 0, max: 256
        }
    },
    message: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'message',
            type: 'text',
            fullWidth: true,
            multiline: true,
            rows:4,
            label:'Message'
        },
        rules: {
            type: 'distance',
            min: 0, max: 256
        }
    }, */
}