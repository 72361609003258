import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory'
import Principal from '../layouts/Principal/Principal.jsx';
import Terms from '../views/Legal/Terms'
import Privacy from '../views/Legal/Privacy.jsx';

const history =  createBrowserHistory()

const PublicRouters = (props) => {

  return(
    <Router history={history}>
        <Switch>
          <Route path='/terms' component={Terms} />
          <Route path='/privacy' component={Privacy} />
          <Route
            path={'/'}
            component={() => {
              return(
                <Principal {...props} />
              )
            }}
          />      
        </Switch>
    </Router>
  );
}

export default PublicRouters;
