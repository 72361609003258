//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        margin:'100px 0px',
    },
    container:{
        boxSizing:'border-box',
        width:'100%',
        margin:'auto',
        padding:'8px 16px',
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px',
    },
    content_container:{
        maxWidth:'800px',
        margin:'40px 8px',
        color:grey[500],
    },
    content:{
        color:grey[700],
        fontWeight:'400',
        fontSize:'1.1em'
    },
    subcontainer:{
        position:'relative',
        padding:'0px 0px',
    },
    circle:{
        position:'relative',
        zIndex:-1,
        borderRadius:'50%',
        width:'700px',
        height:'700px',
        border:`6px solid ${grey[300]}`
    },
    itemContainerA:{
        position:'absolute',
        margin:0,
        left:'65%',
        top:'21%',
        [theme.breakpoints.only('md')]: {
            left:'69%',
            top:'21%',
        },
        
        
    },
    itemContainerB:{
        position:'absolute',
        margin:0,
        left:'13.5%',
        top:'21%',
        [theme.breakpoints.only('md')]: {
            left:'4%',
            top:'21%',
        },
    },
    itemContainerC:{
        position:'absolute',
        margin:0,
        left:'37.8%',
        bottom:'-5.5%',
        [theme.breakpoints.only('md')]: {
            left:'34.1%',
            bottom:'-5.5%',
        },
    },
    
    image:{
        width:'100%',
    },
    program:{

    },
    javascript:{
        position:'absolute',
        top:'5%',
        left:'10%'
    },
    react:{
        position:'absolute',
        top:'28%',
        left:'10%'
    },
    angular:{
        position:'absolute',
        top:'50%',
        left:'8%'
    },
    php:{
        position:'absolute',
        top:'72%',
        left:'13%'
    },
    python:{
        position:'absolute',
        top:'5%',
        left:'77%'
    },
    node:{
        position:'absolute',
        top:'37%',
        left:'81%'
    },
    ruby:{
        position:'absolute',
        top:'70%',
        left:'78%'
    }
    
    
    
    
})

export default styles;