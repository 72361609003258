//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';
import background from '../../assets/FONDO.jpg';

const styles = (theme) => ({
  root:{
    flexGrow:1,
    position:'relative',
    overflowX:'hidden',
    backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(${background})`,
  },
  buttonContainer:{
    position:'fixed',
    zIndex:10000,
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
  },
  button:{
    background: grey[900],
    color:'white',
    '&:hover':{
      background: grey[800],
      color:'white',
    }
  }
})

export default styles;
