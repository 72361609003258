import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import button from '../../../../../../assets/ICONO-MAS.png'


const WorkCard = ({img, style, onClick}) => {

    const classes = useStyles()

    return(
        <div className={classes.root} >
            <div className={classes.img_container} >
                <img src={img} style={style} />
            </div>
            <div className={classes.mask} />
            {/* <div className={classes.button} onClick={onClick} >
                <Typography variant="subtitle2" noWrap >View more</Typography>
            </div> */}
            <div onClick={onClick} className={classes.button_container} >
                <img src={button} style={{cursor:'pointer'}} />
            </div>
        </div>
    )
}

export default WorkCard

const useStyles = makeStyles(theme => ({
    root:{
      borderRadius:60,
      background:theme.palette.primary.main,
      position:'relative',
      overflow:'hidden',
      paddingTop:'78%',
      [theme.breakpoints.down('sm')]:{
        borderRadius:32
        }
     
      //width:'100%'
      //width:400,
      //height:314
    },
    img_container:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        //background:'rgba(34, 40, 49, 0.4)',
        //opacity:0.4
     
    },
    mask:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        background:'rgba(34, 40, 49, 0.4)',
    },
    button:{
        background:'white',
        borderRadius:24,
        opacity:0.9,
        position:'absolute',
        bottom:28,
        left:'50%',
        transform:'translate(-50%, 0px)',
        color:theme.palette.primary.main,
        padding:'6px 32px',
        cursor:'pointer',
        boxShadow:'0px 0px 55px 3px rgba(0,0,0,0.5)'
    },
    button_container:{
        position:'relative',
        display:'flex',
        justifyContent:'center',
        paddingBottom:12
    }

  }))