import { Collapse, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import up from '../../../../../../assets/CLICK-UP.png';
import down from '../../../../../../assets/CLICK-DOWN.png';

const ServiceCard = ({title, icon, subtitle, content}) => {

    const classes = useStyles()

    const [checked, setChecked] = useState(false)

    return(
        
        
        <div className={classes.card_root} >
            <div className={classes.icon_container}>
                <img src={icon} width={'100%'} height={'100%'}  />
            </div>
            <Typography  style={{margin:'16px 0px', fontSize:'4vh'}} >{title}</Typography>
            <Collapse in={checked} timeout={750}>         
                {/* <Typography variant='subtitle1' align="left"  style={{margin:'40px 0px 20px'}} >{subtitle}</Typography> */}
                <div>
                    {content.map((item, ind) => 
                        <Typography align="left"  className={classes.item_list} key={item} >• {item}</Typography>
                    )}
                </div>
            </Collapse>   
            <div className={classes.arrow_container} onClick={()=>setChecked(!checked)} >
                <img src={checked ? up : down} />
            </div>
        </div>
        
        
        
    )
}

export default ServiceCard

const useStyles = makeStyles(theme => ({
    card_root:{
      borderRadius:60,
      background:'rgba( 34, 40, 49, 0.7)',
      
      padding:'40px 32px',
      color:'white',
      textAlign:'center',
      //paddingBottom:'100%'
      
      //marginBottom:50
      //overflow:'hidden',
      //paddingTop:'78%',
    },
    item_list:{
        fontSize:'1.5rem',
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
            }
    },
    icon_container:{
        margin:'auto',
        background:'white',
        borderRadius:'50%',
        //width:140,
        //height:140,
        width:'12vh',
        height:'12vh',
        padding:'4%',
        boxSizing:'border-box',   
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.down('sm')]:{
            width:100,
            height:100,
            padding:20,
            }

      },
      circle:{
          border:'0.5px solid white',
          borderRadius:'50%',
          margin:'8px auto 0px'
      },
      arrow_container:{
        margin:'20px auto 0px',
        cursor:'pointer'
      }
    

  }))

  {/* <Collapse in={checked} collapsedSize={2000}>
            <div className={classes.root} >
                <div className={classes.icon_container}>
                    <img src={icon}  />
                </div>
                <Typography  style={{margin:'20px 0px', fontSize:'2.2rem'}} >{title}</Typography>
                <div className={classes.arrow_container}>
                    <img src={down} />
                </div>
                <Typography variant='h6' align="left"  style={{margin:'40px 0px 20px'}} >{subtitle}</Typography>
                <div>
                    {content.map((item, ind) => 
                        <Typography align="left" style={{fontSize:'2rem'}} key={item} >• {item}</Typography>
                    )}
                </div>
            </div>
        </Collapse> */}