import { Dialog, Grow, Icon, IconButton, makeStyles, Typography, Button } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import React from "react";


const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        //borderRadius:32, 
        background:'#222831',
        color:'white',
        //padding:'100px 70px',
        padding:'10vh 4vw',
        position:'relative',
        //height:'100vh',
        boxSizing:'border-box',
        overflowX:'hidden',
        [theme.breakpoints.down('sm')]:{
            padding:theme.spacing(3),
            //margin:8
        },
        
    },
    icon:{
        color:'white',
        background:red[700],
        borderRadius:'50%',
        fontSize:54,
        padding:8
    },
    message:{
        paddingTop:16,
        paddingBottom:16,
        maxWidth:300
    },
    close_button:{
        position:'absolute', right:50, top:50,
        background:theme.palette.primary.main,
        color:'white',
        padding:6,
        zIndex:50,
        [theme.breakpoints.down('sm')]:{
            right:30,
            top:30
        },
    },

    //modal content
    root:{
        //height:'100vh',
        width:'100%',
        height:'100%',
        background:theme.palette.secondary.main,
        color:'white',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.down('md')]:{
            padding:'0px 50px',
            boxSizing:'border-box'
        },
        [theme.breakpoints.down('sm')]:{
            //height:'100%',
            padding:'100px 0px'
        }
    },
    title:{
        fontSize:'9vh', fontWeight:600, 
        maxWidth:1400, marginBottom:'10vh',
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'2rem'
        }
    },
  
    button:{
        background:theme.palette.primary.main,
        borderRadius:40,
        color:'white',
        textTransform:'none',
        padding:'8px 32px',
        '&:hover':{
            background:theme.palette.primary.main,
            opacity:0.8
        }
    },
    button_text:{
        fontSize:'5vh', fontWeight:600,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2rem'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'1.5rem'
        },
    },
    icon_container:{
        background:'white',
        padding:4,
        borderRadius:'50%',
        width:40,
        height:40,
        marginLeft:12,
        marginRight:-8,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    icon2:{
        fontSize:'2.5rem'
    }
}))

const ReportModal = ({open, onClose, onClick}) => {

    const classes = useStyles()

    return(
        <Dialog open={open}  fullScreen classes={{ paper: classes.paper }} 
        //className={classes.root}
        TransitionComponent={Transition} scroll='body'>
            
            <IconButton className={classes.close_button} onClick={onClose} ><Icon style={{fontWeight:900}} >close</Icon></IconButton>
            <div className={classes.root}  >
                <div style={{textAlign:'center'}} >
                    <Typography className={classes.title} >¿Qué quieren los médicos? 2023</Typography>
                    <div>
                        <Button onClick={onClick} className={classes.button} endIcon={<div className={classes.icon_container} >
                            <Icon color='primary' className={classes.icon2} >download</Icon></div>} 
                        >
                            <Typography className={classes.button_text} >Descarga aquí el reporte</Typography>
                        </Button> 
                    </div>
                </div>
            </div>
            
        </Dialog>
    )
}

export default ReportModal