import { makeStyles, Typography } from "@material-ui/core"
import React from "react"

const Terms = () => {

    const classes = useStyles()

    return(
        <div className={classes.root} >
            <div style={{textAlign:'center', marginBottom:100}}>
                <Typography variant="h6" color='primary' >althar.com</Typography>
                <Typography variant="h4" >Términos y Condiciones.</Typography>
                <div className={classes.divider}></div>
            </div>
            <Typography paragraph >
                CONTRATO DE ADHESIÓN RESPECTO A LA PÁGINA DE INTERNET “althar.com” QUE CELEBRAN, POR UNA PARTE, AMK Healthcare
                México SA de CV, EN ADELANTE “ālthar<sup>®</sup>” Y, POR OTRA PARTE, EL “Usuario”, SUJETÁNDOSE AMBAS PARTES A LO ESTABLECIDO
                EN EL TEXTO DEL PRESENTE CONTRATO.
            </Typography>
            <Typography paragraph color='primary' className={classes.subtitle} >
                althar.com
            </Typography>
            <Typography paragraph color='primary' className={classes.subtitle} >
                Términos y condiciones de uso
            </Typography>
            <Typography paragraph >
                1.- Al ingresar y utilizar este portal de internet, cuyo nombre de dominio es althar.com propiedad de ālthar<sup>®</sup>, usted (en adelante
                referido como el “Usuario” o los “Usuarios”) está aceptando los términos y las condiciones contenidos en este Convenio y declara
                expresamente su aceptación, utilizando para tal efecto medios electrónicos, en términos de lo dispuesto por el artículo 1803 y
                1834bis del Código Civil Federal; los artículos 80, 81, 89 y demás relativos y aplicables del Código de Comercio; y demás relativos
                y aplicables de la legislación aplicable.
            </Typography>
            <Typography paragraph >
                2.- Si el Usuario no acepta en forma absoluta y completa los términos y condiciones de este Convenio, deberá abstenerse de
                acceder, usar, ver e interactuar con althar.com.
            </Typography>
            <Typography paragraph >
                3.- Para el caso de que el Usuario continúe el uso de althar.com dicha acción se considerará como su absoluta y expresa
                aceptación de los términos y condiciones aquí estipulados.
            </Typography>
            <Typography paragraph >
                4.- La sola utilización de la página de internet le otorga e implica al Usuario la aceptación plena e incondicional de todas y cada
                una de las condiciones generales y particulares incluidas en estos términos y condiciones de uso en la versión publicada y
                administrada por ālthar<sup>®</sup>, en el momento mismo en que el Usuario acceda a la página. Cualquier modificación a los presentes
                términos y condiciones será realizada cuando ālthar<sup>®</sup> lo considere apropiado, siendo exclusiva responsabilidad del Usuario
                asegurarse de tomar conocimiento de tales modificaciones. Ambas partes acuerdan –al no existir error, dolo, mala fe o cualquier
                otro vicio de la voluntad que pudiera nulificar la validez del presente instrumento– sujetarse al tenor de lo estipulado en las
                siguientes cláusulas:
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                C L Á U S U L A S
            </Typography>
            <Typography paragraph color='primary' className={classes.subtitle} >
                1.- Licencia
            </Typography>
            <Typography paragraph >
                1.1.- Por virtud de la celebración de este Convenio, ālthar<sup>®</sup> otorga y concede al Usuario el derecho no exclusivo, revocable y no
                transferible de ver y usar althar.com de conformidad con los términos y condiciones que se estipulan en este Convenio.
            </Typography>
            <Typography paragraph >
                1.2.- Para los efectos del presente Convenio, las partes acuerdan que por Usuario se entenderá a cualquier persona de cualquier
                naturaleza que ingrese a althar.com y/o a cualquiera de las subpáginas que despliegan su contenido y/o a la persona de cualquier
                naturaleza que se dé de alta y/o use cualquiera de los servicios que se ofrecen a través de dicho sitio de internet.
            </Typography>
            <Typography paragraph >
                1.3.- El Usuario sólo podrá imprimir y/o copiar cualquier información contenida o publicada en althar.com exclusivamente para su
                uso personal, no comercial. En caso de ser persona moral, se sujetará a lo dispuesto por el artículo 148, fracción IV de la Ley
                Federal del Derecho de Autor. Se le recuerda al Usuario que todas las fotografías, obras artísticas, marcas y/o catálogos de las
                mismas se encuentran debidamente protegidas en términos de la legislación en materia de propiedad intelectual y son propiedad
                de sus respectivos titulares y que ālthar<sup>®</sup> puede haber utilizado imágenes, propiedad de terceros, por lo que la propiedad de ellas
                les corresponde a sus respectivos titulares y no se usan en términos de lo dispuesto por el artículo 62 del Reglamento de la Ley de
                la Propiedad Industrial.
            </Typography>
            <Typography paragraph >
                1.4.- La reimpresión, republicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por cualquier otro medio
                de cualquier información, documento o gráfico de o que aparezca en althar.com en todo o en parte, para cualquier uso distinto al
                personal no comercial le está expresamente prohibido al Usuario, a menos que ālthar<sup>®</sup> le haya otorgado su autorización
                previamente y por escrito.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                2.- Reglas para el uso de althar.com
            </Typography>
            <Typography paragraph >
                Independientemente de las demás obligaciones que se contengan en el texto íntegro del presente instrumento, el Usuario y ālthar<sup>®</sup>,
                están de acuerdo en que el uso de althar.com se sujetará a las siguientes reglas:
            </Typography>
            <Typography paragraph >
                2.1.- Información contenida en althar.com: el Usuario reconoce y acepta que la información publicada o contenida en althar.com
                generada por ālthar<sup>®</sup> o por los proveedores, Usuarios y/o profesionales será claramente identificada de forma tal que se
                reconozca que la misma proviene de ālthar<sup>®</sup> o de los proveedores.
            </Typography>
            <Typography paragraph >
                2.2.- La información, consejos, conceptos y opiniones publicadas en althar.com no necesariamente reflejan la posición de ālthar<sup>®</sup>
                ni de sus empleados, oficiales, directores, accionistas, licenciatarios y concesionarios. Por esta razón, ālthar<sup>®</sup> no se hace
                responsable de ninguna información, consejos, opiniones y conceptos que se emitan en althar.com. Asimismo, althar.com no se
                hace responsable de la información contenida en la página de internet, incluidas las subpáginas, en el entendido de que es bajo
                el propio riesgo y responsabilidad del Usuario el uso y seguimiento de las mismas. Sin embargo, si una parte del contenido le afecta
                directamente a Usuarios o terceros, podrán solicitar que este sea dado de baja del sitio de web, según el procedimiento
                establecido en el punto 5.9.
            </Typography>
            <Typography paragraph >
                2.3.- ālthar<sup>®</sup> se reserva el derecho de bloquear el acceso o remover, en forma parcial o total, toda información, comunicación o
                material que a su exclusivo juicio pueda resultar: (i) abusivo, difamatorio u obsceno; (ii) fraudulento, artificioso o engañoso; (iii)
                violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o cualquier derecho de propiedad intelectual de
                un tercero; (iv) ofensivo; o (v) que de cualquier forma contravenga lo establecido en este Convenio. Si el Usuario desea obtener
                mayor información de un tema en específico proveído por ālthar<sup>®</sup> o los proveedores, el Usuario deberá consultarlo directamente
                con cada uno de ellos, según corresponda y/o con un especialista en la materia.
            </Typography>
            <Typography paragraph >
                2.4.- El Usuario reconoce que ālthar<sup>®</sup> no controla o censura previamente el contenido disponible en althar.com que sea proveído
                al mismo por proveedores independientes ajenos a ālthar<sup>®</sup> y/o otros Usuarios; entiéndase por esto, los tips, consejos, imágenes,
                recomendaciones y opiniones. ālthar<sup>®</sup> no tiene control editorial sobre el contenido y quedará exento de cualquier responsabilidad
                por información o material generado y/o provisto por terceros. Por tal motivo, ālthar<sup>®</sup> no asume ninguna responsabilidad por el
                contenido provisto a la página de internet por proveedores independientes ajenos a ālthar<sup>®</sup>. Bajo ninguna circunstancia ālthar<sup>®</sup>
                será responsable de cualquier daño y/o perjuicio, directo o indirecto, causado en virtud de la confianza del Usuario en información
                obtenida a través de esta página de internet.
            </Typography>
            <Typography paragraph >
                2.5.- ālthar<sup>®</sup> se reserva el derecho de suprimir o modificar el contenido de la página de internet que, a exclusivo juicio de ālthar<sup>®</sup>,
                no cumpla con los estándares de ālthar<sup>®</sup> o que pudiera resultar contrario al ordenamiento jurídico vigente. Sin embargo, ālthar<sup>®</sup>
                no será responsable por cualquier falla o tardanza que se genere al eliminar tal material.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                3.- Aviso de Privacidad sobre el uso de información y base de datos
            </Typography>
            <Typography paragraph >
                3.1.- A través de althar.com puede obtener diversa información del Usuario que puede compilarse y fijarse en una base de datos
                para lo cual ālthar<sup>®</sup> publica su “Aviso de Privacidad” en el sitio de althar.com, el cual se recomienda consultar en caso de dudas
                acerca del tratamiento de los datos personales por parte ālthar<sup>®</sup>.
            </Typography>
            <Typography paragraph >
                3.2.- Si ālthar<sup>®</sup> decide cambiar su Aviso de Privacidad, anunciará dichos cambios en althar.com, de manera que el Usuario siempre
                pueda saber qué información se recopila, cómo podría ser utilizada y si será revelada a alguien.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                4.- Formatos
            </Typography>
            <Typography paragraph >
                4.1.- Los Usuarios reconocen que al proporcionar la información de carácter personal requerida en alguno de los servicios que se
                prestan en este sitio de internet, otorgan a ālthar<sup>®</sup> la autorización señalada en el artículo 109 de la Ley Federal del Derecho de
                Autor.
            </Typography>
            <Typography paragraph >
                4.2.- El Usuario acepta y reconoce que los datos que proporcione serán los correctos y que, en caso de haber proporcionado
                información errónea, inapropiada o falsa a ālthar<sup>®</sup>, que llevara al Usuario a hacerse de una cosa y/o a obtener un lucro indebido,
                estaría cometiendo una conducta ilegal, por lo que desde ahora ālthar<sup>®</sup> se reserva el derecho de iniciar las acciones a las que
                haya lugar ante las autoridades competentes.
            </Typography>
            <Typography paragraph >
                4.3.- En todos los casos, el Usuario responderá, bajo protesta de decir la verdad, de la veracidad de la información proporcionada
                a ālthar<sup>®</sup>, sacando a esta en paz y a salvo por cualquier información proporcionada por el Usuario de forma falsa o incorrecta, o
                bien, cuando no tenga derecho o legitimación para hacerlo.
            </Typography>
            <Typography paragraph >
                4.4.- Queda establecido que es decisión del Usuario otorgar la información personal a ālthar<sup>®</sup>. Por lo anterior, el Usuario es
                responsable de la veracidad y confiabilidad sobre dicha información. En caso de que el Usuario decida enviar información
                personal a través de los medios dispuestos para tal efecto, el Usuario, con base en lo establecido en el artículo 76bis de la Ley
                Federal de Protección al Consumidor, otorga su consentimiento expreso de manera electrónica para que ālthar<sup>®</sup> comparta su
                información con terceras personas. Asimismo, el Usuario declara que no se encuentra registrado en el Registro Público de
                Consumidores de la Procuraduría Federal del Consumidor y, en caso de encontrarse inscrito en el mismo, para efectos de lo
                dispuesto en los artículos 16, 18 y 18bis de la Ley Federal de Protección al Consumidor, el Usuario, en este acto, otorga su
                consentimiento expreso y aceptación para recibir información con carácter o fines mercadotécnicos o publicitarios. Del mismo
                modo, el Usuario faculta a ālthar<sup>®</sup>, de la forma más amplia que legalmente proceda, a utilizar la información personal para: (1)
                resolver controversias o disputas relacionadas con althar.com y violaciones al presente Convenio; (2) para posibilitar a ālthar<sup>®</sup>
                operar correctamente; y (3) en los casos en que la ley así lo requiera. El Usuario faculta a ālthar<sup>®</sup>, de la forma más amplia que
                legalmente proceda, así como a proveedores y asesores (según se definen más adelante) y demás terceros con quien contrate, a
                utilizar la información personal e información agregada del Usuario (incluyendo, sin limitación alguna, información sobre el número
                de ocasiones que ha visto e/o ingresado a las ligas de otros sitios o páginas electrónicas a través de los anuncios y demás
                información que se publique en althar.com).
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                5.- Derechos de autor y propiedad industrial
            </Typography>
            <Typography paragraph >
                5.1.- ālthar<sup>®</sup>, althar.com y sus logotipos y todo el material que aparece en la página de internet referida son marcas, nombres de
                dominio y/o nombres comerciales propiedad de sus respectivos titulares protegidos por los tratados internacionales y leyes
                aplicables en materia de propiedad intelectual y derechos de autor.
            </Typography>
            <Typography paragraph >
                5.2.- Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, fotografías,
                imágenes, programas, aplicaciones o en general cualquier información contenida o publicada en althar.com se encuentran
                debidamente protegidos a favor de ālthar<sup>®</sup>, de sus afiliados, proveedores y/o de sus respectivos propietarios, de conformidad con
                la legislación aplicable en materia de propiedad intelectual.
            </Typography>
            <Typography paragraph >
                5.3.- Se prohíbe expresamente al Usuario modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres
                comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de información contenida
                en althar.com.
            </Typography>
            <Typography paragraph >
                5.4.- En caso de que el Usuario transmita y/o publique en althar.com cualquier información, programas, aplicaciones, software o,
                en general, cualquier material que requiera ser licenciado a través de althar.com, el Usuario le otorga a ālthar<sup>®</sup> en este acto una
                licencia perpetua, universal, gratuita, no exclusiva, mundial y libre de regalías, incluyendo entre los derechos otorgados el derecho
                de sublicenciar, vender, reproducir, distribuir, transmitir, crear trabajos derivados, exhibirlos y ejecutarlos públicamente. No se
                interpretará en este sentido la información proveniente de otros sitios web a los que ālthar<sup>®</sup> haga referencia con objetivos
                meramente informativos.
            </Typography>
            <Typography paragraph >
                5.5.- Lo establecido en el inciso anterior se aplicará igualmente a cualquier otra información que el Usuario envíe, trasmita o
                publique en althar.com (incluyendo, sin limitación alguna, ideas para renovar o mejorar el sitio althar.com, sea que éstas hayan sido
                incluidas en cualquier espacio de althar.com, en virtud de otros medios o modos de transmisión ya conocidos o en el futuro
                desarrollados.
            </Typography>
            <Typography paragraph >
                5.6.- Por lo anterior, el Usuario renuncia expresamente en este acto a intentar cualquier acción, demanda o reclamación en contra
                de ālthar<sup>®</sup>, sus afiliados o proveedores por cualquier actual o eventual violación de cualquier derecho de autor o propiedad
                intelectual derivado de la información, programas, aplicaciones, software, ideas y demás material que el propio Usuario envíe a
                althar.com.
            </Typography>
            <Typography paragraph >
                5.7.- Asimismo, el Usuario será el único responsable del contenido que publique dentro de althar.com, ya que declara ser el titular
                de los derechos patrimoniales de autor sobre el mismo o contar con la autorización de su legítimo titular para incluirlo dentro de
                althar.com. Por lo anterior, se compromete a sacar en paz y a salvo a ālthar<sup>®</sup> de cualquier reclamación, queja, demanda o
                denuncia que cualquier tercero inicie en virtud de la originalidad o titularidad de dicho contenido
            </Typography>
            <Typography paragraph >
                5.8.- Es política de ālthar<sup>®</sup> actuar contra a las violaciones que en materia de propiedad intelectual se pudieran generar u originar
                según lo estipulado en la legislación aplicable y en otras leyes de propiedad intelectual aplicables, incluyendo la eliminación o el
                bloqueo del acceso a material que se encuentra sujeto a actividades que infrinjan el derecho de propiedad intelectual de terceros.
            </Typography>
            <Typography paragraph >
                5.9.- En el caso de que algún Usuario o un tercero considere que cualquiera de los contenidos que se encuentren o sean
                introducidos en althar.com, y/o cualquiera de sus servicios, violen sus derechos de propiedad intelectual, deberán enviar una
                notificación a althar.com, a la dirección que arriba aparece, en la que indiquen cuando menos: (i) los datos personales verídicos
                (nombre, dirección, número de teléfono y dirección de correo electrónico del reclamante); (ii) la firma autógrafa con los datos
                personales del titular de los derechos de propiedad intelectual; (iii) la indicación precisa y completa del (los) contenido(s)
                protegido(s) mediante los derechos de propiedad intelectual supuestamente infringidos, así como de su localización en althar.com;
                (iv) una declaración expresa y clara de que la introducción del (los) contenido(s) indicado(s) se ha realizado sin el consentimiento
                del titular de los derechos de propiedad intelectual supuestamente infringidos; (v) una declaración expresa, clara y bajo la
                responsabilidad del reclamante de que la información proporcionada en la notificación es exacta y de que la introducción del (los)
                contenido(s) constituye una violación de sus derechos de propiedad intelectual.
            </Typography>
            <Typography paragraph >
                Para reportar cualquier infracción sobre los contenidos de la página web, por favor envíe un correo a info@althar.com, con el
                asunto: Infracción contenidos. 
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                6.- Material Publicitario
            </Typography>
            <Typography paragraph >
                6.1.- El Usuario reconoce y acepta que ālthar<sup>®</sup> es una organización independiente de terceros patrocinadores y anunciantes cuya
                información, imágenes, anuncios y demás Material Publicitario o promocional (el “Material Publicitario”) pudiera estar publicado en
                althar.com.
            </Typography>
            <Typography paragraph >
                6.2.- El Usuario reconoce y acepta que el Material Publicitario no forma parte del contenido principal que se publica en althar.com.
                Asimismo, el Usuario reconoce y acepta en este acto que el Material Publicitario se encuentra protegido por las leyes que en
                materia de propiedad intelectual e industrial resulten aplicables.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                7.- Negación de garantías
            </Typography>
            <Typography paragraph >
                7.1.- El Usuario está de acuerdo con que el uso de althar.com se realiza bajo su propio riesgo, que los servicios que se prestan y
                ofrecen en althar.com se prevén sobre una base “tal cual” y “según sean y estén disponibles”. ālthar<sup>®</sup> no garantiza que althar.com
                satisfaga al cien por ciento los requerimientos del Usuario o que los servicios de althar.com se mantengan siempre ininterrumpidos,
                en tiempo, seguros o libres de errores.
            </Typography>
            <Typography paragraph >
                7.2.- ālthar<sup>®</sup> no garantiza o avala en ninguna forma la veracidad, precisión, legalidad, moralidad o ninguna otra característica del
                contenido del material que se publique en althar.com. ālthar<sup>®</sup> se libera de cualquier responsabilidad y condiciones, tanto expresas
                como implícitas, en relación con los servicios e información contenida o disponible en o a través de althar.com, incluyendo, sin
                limitación alguna:
            </Typography>
            <Typography paragraph >
                7.2.1.- La disponibilidad de uso de althar.com, por motivo de problemas técnicos imputables a los sistemas de comunicación y
                transmisión de datos.
            </Typography>
            <Typography paragraph >
                7.2.2.- La ausencia de virus, errores, desactivadores o cualesquier otro material contaminante o con funciones destructivas en la
                información o programas disponibles en o a través de althar.com, o en general, cualquier falla en althar.com.
            </Typography>
            <Typography paragraph >
                7.2.3.- No obstante lo anterior, ālthar<sup>®</sup> o sus proveedores podrán actualizar el contenido de althar.com constantemente, por lo que
                se pide al Usuario tomar en cuenta que alguna información publicitada o contenida en o a través de althar.com puede haber
                quedado obsoleta y/o contener imprecisiones o errores tipográficos u ortográficos.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                8.- Limitaciones a la responsabilidad
            </Typography>
            <Typography paragraph >
                8.1.- Hasta el máximo permitido por las leyes aplicables, ālthar<sup>®</sup> no será responsable, en ningún caso, por daños directos,
                especiales, incidentales, indirectos, en consecuencia, derivados, o relacionados de manera enunciativa más no limitativa con:
            </Typography>
            <Typography paragraph >
                8.1.1.- El uso o ejecución de althar.com con el retraso o falta de disponibilidad de uso ālthar<sup>®</sup>.
            </Typography>
            <Typography paragraph >
                8.1.2.- La proveeduría de o falta de proveeduría de servicios de cualesquier información, servicios y soluciones digitales, de
                información médica, diseños, consultoría, contenidos o publicidad en o a través de althar.com.
            </Typography>
            <Typography paragraph >
                8.1.3.- De la actualización o falta de actualización de la información.
            </Typography>
            <Typography paragraph >
                8.1.4.- Que la información haya sido alterada o modificada, en todo o en parte, luego de haber sido incluida en althar.com. 
            </Typography>
            <Typography paragraph >
                8.1.5.- Cualquier otro aspecto o característica de la información contenida o publicada en althar.com o a través de las ligas que
                eventualmente se incluyan en el mismo.
            </Typography>
            <Typography paragraph >
                8.1.6.- La proveeduría o falta de proveeduría que los demás servicios, todos los supuestos anteriores serán vigentes, aun en los
                casos en que se le hubiere notificado o avisado a ālthar<sup>®</sup> acerca de la posibilidad de que se ocasionaran dichos daños.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                9.- Modificaciones a althar.com
            </Typography>
            <Typography paragraph >
                9.1.- ālthar<sup>®</sup> podrá, en cualquier momento y cuando lo considere conveniente, sin necesidad de aviso al Usuario, realizar
                correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, servicios, áreas, bases de datos y
                demás elementos de althar.com sin que ello de lugar ni derecho a ninguna reclamación o indemnización, ni que lo mismo implique
                reconocimiento de responsabilidad alguna a favor del Usuario.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                10.- Modificaciones al Convenio
            </Typography>
            <Typography paragraph >
                10.1.- ālthar<sup>®</sup> se reserva el derecho de modificar los términos y condiciones de este Convenio en cualquier tiempo, siendo efectivas
                dichas modificaciones de forma inmediata:
            </Typography>
            <Typography paragraph >
                10.1.1.- Por la publicación en althar.com del Convenio modificado o:
            </Typography>
            <Typography paragraph >
                10.1.2.- Por la notificación al Usuario sobre dichas modificaciones. El Usuario está de acuerdo con revisar este Convenio
                periódicamente a fin de estar al tanto de dichas modificaciones. No obstante lo anterior, cada ocasión en que el Usuario tenga
                acceso a althar.com se considerará como aceptación absoluta de las modificaciones del Convenio.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                11.- Excluyente de responsabilidad
            </Typography>
            <Typography paragraph >
                11.1.- Ocasionalmente, ālthar<sup>®</sup> podrá adicionar los términos y condiciones del presente Convenio con provisiones adicionales
                relativas a áreas específicas o nuevos servicios que se proporcionen en o a través de althar.com (“Términos Adicionales”), los
                cuales serán publicados en las áreas específicas o nuevos servicios de althar.com para su lectura y aceptación. El Usuario
                reconoce y acepta que dichos Términos Adicionales forman parte integrante del presente Convenio para todos los efectos legales
                a que haya lugar.
            </Typography>
            <Typography paragraph >
                11.2.- ālthar<sup>®</sup> no tiene obligación de controlar y no controla la utilización que los Usuarios hacen del portal, de los servicios y de los
                contenidos.
            </Typography>
            <Typography paragraph >
                11.3.- En particular, althar.com no garantiza que el Usuario utilice el portal, los servicios y los contenidos de conformidad con estas
                Condiciones Generales, y en su caso, las Condiciones Particulares aplicables, ni que lo hagan de forma diligente y prudente.
                althar.com tampoco tiene la obligación de verificar y no verifica la identidad de el Usuario, ni la veracidad, vigencia, exhaustividad
                y/o autenticidad de los datos que el Usuario proporciona sobre sí mismo a otros Usuarios.
            </Typography>
            <Typography paragraph >
                11.4.- ālthar<sup>®</sup> NO ASUME RESPONSABILIDAD ALGUNA POR LOS DAÑOS Y PERJUICIOS DE TODA NATURALEZA QUE PUDIERAN
                DERIVARSE DE LA UTILIZACIÓN DE LOS SERVICIOS Y DE LOS CONTENIDOS POR PARTE DE LOS USUARIOS O QUE PUEDAN
                DERIVARSE DE LA FALTA DE VERACIDAD, VIGENCIA, EXHAUSTIVIDAD Y/O AUTENTICIDAD DE LA INFORMACIÓN QUE LOS
                USUARIOS PROPORCIONAN A OTROS USUARIOS ACERCA DE SÍ MISMOS Y, EN PARTICULAR, AUNQUE NO DE FORMA
                EXCLUSIVA, POR LOS DAÑOS Y PERJUICIOS DE TODA NATURALEZA QUE PUEDAN DERIVARSE DE LA SUPLANTACIÓN DE LA
                PERSONALIDAD DE UN TERCERO EFECTUADA POR UN USUARIO EN CUALQUIER CLASE DE COMUNICACIÓN REALIZADA A
                TRAVÉS DEL PORTAL.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                12.- Cesión de derechos
            </Typography>
            <Typography paragraph >
                ālthar<sup>®</sup> podrá, en cualquier tiempo y cuando así lo estime conveniente, ceder total o parcialmente sus derechos y obligaciones
                derivados del presente Convenio. En virtud de dicha cesión, ālthar<sup>®</sup> quedará liberado de cualquier obligación a favor del Usuario
                establecida en el presente Convenio.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                13.- Indemnización
            </Typography>
            <Typography paragraph >
                El Usuario está de acuerdo con indemnizar a ālthar<sup>®</sup>, sus afiliados, proveedores, vendedores y asesores de cualquier acción,
                demanda o reclamación (incluso de honorarios de abogados y de costas judiciales) derivadas de cualesquier incumplimiento por
                parte del Usuario al presente Convenio, incluyendo, sin limitación de alguna de las derivadas:
            </Typography>
            <Typography paragraph >
                13.1.- De cualquier aspecto relativo al uso de althar.com.
            </Typography>
            <Typography paragraph >
                13.2.- De la información contenida o disponible en o a través de althar.com.
            </Typography>
            <Typography paragraph >
                13.3.- De injurias, difamación o cualesquier otra conducta violatoria del presente Convenio por parte del Usuario en el uso de
                althar.com.
            </Typography>
            <Typography paragraph >
                13.4.- De violación a las leyes aplicables o tratados internacionales relativos a los derechos de autor o propiedad intelectual,
                contenidos o disponibles en o a través de althar.com.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                14.- Terminación.
            </Typography>
            <Typography paragraph >
                ālthar<sup>®</sup> se reserva el derecho a su exclusiva discreción y sin necesidad de aviso o notificación al Usuario para:
            </Typography>
            <Typography paragraph >
                14.1.- Terminar definitivamente el presente Convenio.
            </Typography>
            <Typography paragraph >
                14.2.- Descontinuar o dejar de publicar definitivamente althar.com, sin responsabilidad alguna para ālthar<sup>®</sup>, sus afiliados o
                proveedores.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                15.- Subsistencia
            </Typography>
            <Typography paragraph >
                15.1.- Estos términos y condiciones, así como los Términos Adicionales constituyen el acuerdo íntegro entre las partes y sustituyen
                cualquier otro acuerdo o convenio celebrado con anterioridad. Cualesquier cláusula o provisión del presente Convenio, así como
                de los Términos Adicionales legalmente declarada inválida, será eliminada o modificada a elección de ālthar<sup>®</sup>, a fin de corregir su
                vicio o defecto. Sin embargo, el resto de las cláusulas o provisiones mantendrán su fuerza, obligatoriedad y validez.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                16.- No renuncia de derechos
            </Typography>
            <Typography paragraph >
                16.1.- La inactividad por parte de ālthar<sup>®</sup>, sus afiliados o proveedores al ejercicio de cualquier derecho o acción derivados del
                presente Convenio en ningún momento deberá interpretarse como renuncia a dichos derechos o acciones.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                17.- Legislación aplicable y jurisdicción
            </Typography>
            <Typography paragraph >
                17.1.- En caso de que se presente una controversia que se derive del presente contrato o se relacione con él, las partes se obligan
                a tratar de llegar a una transacción amistosa de esa controversia mediante la mediación; esta tendrá lugar de conformidad con
                el Reglamento de Mediación de la Cámara Nacional de Comercio de la Ciudad de México, vigente al momento del inicio de la
                mediación. De no ser así, las partes podrán acudir ante los tribunales de la Ciudad de México, Distrito Federal, México, renunciando
                a cualquier otro fuero que pudiera corresponderles, quedando este Convenio a la interpretación de acuerdo con las leyes de la
                República Mexicana.
            </Typography>
     
        </div>
    )
}

export default Terms

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.secondary.main,
        padding:'100px 100px',
        color:'white',
        textAlign:'justify',
      
      [theme.breakpoints.down('sm')]:{
        padding:'50px 32px'
        },
      
    },
    divider:{
        height:16,
        width:100,
        borderRadius:24,
        background:theme.palette.primary.main,
        margin:'20px auto'
    },
    subtitle:{
        fontWeight:600
    }
    

  }))