import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Fade } from '@material-ui/core';

class SectionA extends Component{

    render(){

        const {classes,content} = this.props;

        return(
            <div className={classes.root}>

                <div className={classes.background}/>
                <div className={classes.textContainer}>
                    <div style={{width:'100%'}}>
                        <Grid container direction='column' alignItems='center' >
                            <Grid item>
                                <Fade in timeout={1500}>
                                    <Typography variant='h3' className={classes.title} >
                                    OUR WORK
                                    </Typography>
                                </Fade>    
                            </Grid>
                            <Grid item  >
                                <Fade in timeout={3000}>
                                    <div className={classes.divider} />                   
                                </Fade>  
                            </Grid>
                        </Grid>
                    </div>            
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionA);