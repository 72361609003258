import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Fade,
} from '@material-ui/core';
import LoaderIcon from 'react-loader-spinner'

class CircularLoader extends Component{

  onClose = () => {
    console.log('Cerrar')
  }

  render(){

    const {open, classes} = this.props;
    
    return(
      <Fade in={open} timeout={700}>
        <div className={classes.spinnerContainer}>
          <LoaderIcon 
            type="ThreeDots"
            color="#33ccff"
            width='64px'
            height='64px'
          />  
        </div>
      </Fade>
      
    )
  }
}

export default withStyles(styles)(CircularLoader);
