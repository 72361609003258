//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        //margin:'100px 0px',
    },
    carrousel:{
        position:'relative',
        background:theme.palette.secondary.main,
        //minHeight:`calc(100vh - 76px)`,
        height:'75vh',
        //marginTop:76,
        //height:'100%',
        overFlow:'hidden',
        /* [theme.breakpoints.down('lg')]: {
            height:'120vh'
        }, */
        display:'flex', 
        alignItems:'center',
        padding:'0px 20px',
        boxSizing:'border-box',
        //background:'red',
        //marginTop:70,
        [theme.breakpoints.down('sm')]: {
            height:'100%',
            padding:'60px 20px'
        },
    },
    carr_bar:{
        position:'absolute',
        bottom:50,
        left:'50%',
        transform:'translate(-50%, 0px)',
        [theme.breakpoints.down('sm')]: {
            bottom:20,
        },
        //margin:'auto'
    },
    carr_item:{
        height:12,
        width:52,
        borderRadius:24,
        background:'white',
        opacity:0.5,
        cursor:'pointer',
        [theme.breakpoints.down('sm')]: {
            width:32,
            //height:12,
            //borderRadius:'50%'
        },
    },
    carr_item_selected:{
        opacity:1,
        cursor:'auto'
    },
    button_arrow:{
        width:16,
        height:16,
        padding:10,
        borderRadius:'50%',
        border:'1px solid white',
        display:'flex',
        color:'white',
        boxSizing:'content-box',
        justifyContent:'center',
        alignItems:'center',
        '&:disabled':{
            borderColor:grey[500],
            color:grey[500]
        }
    },
    /* container:{
        boxSizing:'border-box',
        width:'1200px',
        margin:'auto',
        padding:'16px',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'950px',
        },
    }, */
    /* divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px',
    },
    content_container:{
        maxWidth:'800px',
        margin:'40px',
        color:grey[500],
    },
    content:{
        color:grey[700],
        fontWeight:'400',
        fontSize:'1.1em'
    },
    subcontainer:{
        position:'relative',
        padding:'0px 0px',
    },
    circle:{
        position:'relative',
        zIndex:-1,
        borderRadius:'50%',
        width:'700px',
        height:'700px',
        border:`6px solid ${grey[300]}`
    },
    itemContainerA:{
        position:'absolute',
        margin:0,
        left:'65%',
        top:'21%',
        [theme.breakpoints.only('md')]: {
            left:'69%',
            top:'21%',
        },
        
        
    },
    itemContainerB:{
        position:'absolute',
        margin:0,
        left:'13.5%',
        top:'21%',
        [theme.breakpoints.only('md')]: {
            left:'4%',
            top:'21%',
        },
    },
    itemContainerC:{
        position:'absolute',
        margin:0,
        left:'37.8%',
        bottom:'-5.5%',
        [theme.breakpoints.only('md')]: {
            left:'34.1%',
            bottom:'-5.5%',
        },
    },
    
    image:{
        width:'600px',
        margin:'auto',
        [theme.breakpoints.only('md')]: {
            width:'450px',
        },
    },
    javascript:{
        position:'absolute',
        top:'5%',
        left:'10%'
    },
    react:{
        position:'absolute',
        top:'28%',
        left:'10%'
    },
    angular:{
        position:'absolute',
        top:'50%',
        left:'8%'
    },
    php:{
        position:'absolute',
        top:'72%',
        left:'13%'
    },
    python:{
        position:'absolute',
        top:'5%',
        left:'77%'
    },
    node:{
        position:'absolute',
        top:'37%',
        left:'81%'
    },
    ruby:{
        position:'absolute',
        top:'70%',
        left:'78%'
    } */
    
    
    
    
})

export default styles;