import React, {Component} from 'react';
import {
  Hidden
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {contentData} from './content.jsx';
import FooterDesktop from './FooterDesktop/FooterDesktop.jsx';
import FooterMobile from './FooterMobile/FooterMobile.jsx';

class Footer extends Component{


  render(){
    const {onChange, language} = this.props;
    const content = contentData[language];

    return(
      <div>
        {/* <Hidden mdUp>
          <FooterMobile content={content} onChange={onChange}/>
        </Hidden>
        <Hidden smDown>
          <FooterDesktop content={content} onChange={onChange}/>
        </Hidden> */}
        <FooterDesktop content={content} onChange={onChange}/>
      </div>
    )
  }
}

export default withStyles(styles)(Footer);
