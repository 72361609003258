import React, { Component } from 'react';
import { withStyles} from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid } from '@material-ui/core';
import imageA from '../../../../../../assets/Home/xandbox.png';
import {Slide, Grow} from '@material-ui/core';

class SectionB extends Component{

    shouldComponentUpdate(nextProps, nextState){
        return this.props.content !== nextProps.content ||
                this.props.visited !== nextProps.visited
    }

    render(){

        const {classes, content, visited} = this.props;

        const timein = 1500;

        return(
            
            <div className={classes.section}>
                          
                <div className={classes.container}>
                
                    <Grid container direction='column' align='center'>
                        <Grid item>  
                            <Slide direction="left" in={visited} timeout={timein}>
                                <Typography variant='h4' align='center' className={classes.about_title}>
                                    {content.about.title}
                                </Typography>
                            </Slide>
                        </Grid>
                        <Grid item>
                            <Slide direction="right" in={visited} timeout={timein}>
                                <div className={classes.divider}/>
                            </Slide>
                        </Grid>
                        <Grid item>  
                            <Grow in={visited} timeout={timein+500}>
                                <div className={classes.about_content_container}>
                                    <Typography variant='body1' align='center' className={classes.about_content}>
                                        {content.about.content}
                                    </Typography>
                                </div>
                            </Grow>
                        </Grid>
                        <Grid item>  
                            <Grow in={visited} timeout={timein+500}>
                                <div className={classes.imageContainer}>
                                    <img src={imageA} alt={''} className={classes.image}/>
                                </div>
                            </Grow>
                            
                        </Grid>
                    </Grid>
                        
                </div>
            </div>
            
        )
    }
}

export default withStyles(styles)(SectionB);