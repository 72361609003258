import { Dialog, Grow, Icon, IconButton, makeStyles, Typography, Zoom } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import React from "react";
import InfoItem from "./InfoItem";

const Transition = React.forwardRef(function Transition(props, ref) {return <Grow ref={ref} {...props} timeout={500} />;});

const useStyles = makeStyles(theme => ({
    paper:{
        //borderRadius:32, 
        background:'#222831',
        color:'white',
        //padding:'100px 70px',
        padding:'12vh 4vw',
        position:'relative',
        //height:'100vh',
        boxSizing:'border-box',
        overflowX:'hidden',
        [theme.breakpoints.down('sm')]:{
            padding:theme.spacing(3),
            //margin:8
        },
        
    },
    root:{
        //padding:'100px'
        //padding:40
        //background:'red'
    },
    icon:{
        color:'white',
        background:red[700],
        borderRadius:'50%',
        fontSize:54,
        padding:8
    },
    message:{
        paddingTop:16,
        paddingBottom:16,
        maxWidth:300
    },
    close_button:{
        position:'absolute', right:70, top:30,
        background:theme.palette.primary.main,
        color:'white',
        padding:6,
        zIndex:50,
        [theme.breakpoints.down('sm')]:{
            top:20,
            right:20
        },
    }
}))

const WorkModal = ({open, onClose, children}) => {

    const classes = useStyles()

    return(
        <Dialog open={open}  fullScreen classes={{ paper: classes.paper }} className={classes.root}
        TransitionComponent={Transition} scroll='body'>
            
            <IconButton className={classes.close_button} onClick={onClose} ><Icon style={{fontWeight:900}} >close</Icon></IconButton>
            {children}
            
        </Dialog>
    )
}

export default WorkModal