import { fade } from '@material-ui/core/styles/colorManipulator';
import {blueGrey,grey} from '@material-ui/core/colors'

const styles = (theme) => ({
  root:{
    position:'relative',
    //height:'30vh',
    background:'rgb(34, 40, 49)',
    //background:'red',
    overflow:'hidden',
    [theme.breakpoints.down('sm')]:{
      height:'100%',
      paddingBottom:70
    },
    //background:'red'
    //borderTop:`2px solid ${grey[200]}`
  },
  container:{
    padding:'32px 60px',
    color:'rgba(255,255,255,0.45)',
    [theme.breakpoints.down('sm')]:{
      padding:32
    },
    //borderBottom:`1px solid ${grey[200]}`
  },
  contact_box:{
    cursor:'pointer',

  },
  info_container:{
    position:'relative',
    zIndex:10,
    //background:'red'
  },
  planet_container:{
    position:'absolute',
    right:'-10%',
    //transform:'translate(-50%, 45%)',
    bottom:'-50%',
    width:600,
    zIndex:0,
    [theme.breakpoints.down('sm')]:{
      width:500,
      bottom:'-10%',
      display:'none'
      //zIndez:-1
    },
    /* [theme.breakpoints.down('xs')]:{
      width:'90%',
      bottom:'-15%',
      //zIndez:-1
    }, */
    //background:'red'

  },
  button:{
    boxShadow:'0px 0px 40px 0px rgba(0,173,181,1)',
    borderRadius:28,
    padding:'0px 32px',
    background:'white',
    color:theme.palette.primary.main,
    cursor:'pointer',
    position:'relative',
    zIndex:2
  },
  line:{
    width:'100%',
    background:theme.palette.primary.main,
    height:0.5,
    margin:'20px 0px'
  },
  link:{
    textDecoration:'none',
    color:'rgba(255,255,255,0.45)'
  }


  /* svg:{
    height:'200px',
    background:'red',

  },
  textContent:{
    color:'rgba(255,255,255,0.75)',
    fontSize:'16px',
    fontWeight:'500'
  },
  icon:{
    color:'white',
    fontSize:'48px'
  },
  socialIcon:{
    '&:hover':{
      cursor:'pointer'
    }
  },
  copyFooter:{
    background: fade(theme.palette.primary.main,0.85),
    color:'white',
    fontWeight:'500',
    fontSize:'12px',
    width:'100%',
    padding:'8px 0px'
  },
  title:{
    color:'#58595b',
    fontSize:'1.4em',
    position:'relative',
    fontWeight:'400',
    padding:'16px 0'
  },
  title2:{
    color:'#808285',
    fontSize:'1.4em',
    position:'relative',
    fontWeight:'700',
    padding:'16px 0'
  },
  titleLine:{
    width:'40px',
    minHeight:'4px',
    background:grey[300],
  },
  content:{
    color:grey[700],
    maxWidth:'350px'
  },
  
  navlinkB:{
    background:'#4b6bf5',
    padding:'5px 8px',
    borderRadius:'4px',
    textDecoration:'none',
    textTransform:'uppercase',
    color:'white',
    fontSize:'0.6em',
    fontWeight:'600',
    '&:hover':{
      cursor:'pointer'
    }
  },
  legalContainer:{
    padding:'16px 0px'
  },
  legal:{
    fontSize:'0.7em',
    color:grey[500],
    fontWeight:'600'
  },
  imageContainer:{
    boxSizing:'border-box',
    background:blueGrey[200],
    padding:'3px 6px',
    borderRadius:'4px'
  },
  facebook:{
    '&:hover':{
      background:'#3b5998',
      cursor:'pointer'
    }
  },
  instagram:{
    '&:hover':{
      background:'#dd2a7b',
      cursor:'pointer'
    }
  },
  linkedin:{
    '&:hover':{
      background:'#0e76a8',
      cursor:'pointer'
    }
  },
  twitter:{
    '&:hover':{
      background:'#38A1F3',
      cursor:'pointer'
    }
  },
  image:{
    width:'12px',
    height:'12px',
  }, */
  
})

export default styles;
