import React, { Component } from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import PublicRouters from './routes/router.jsx';
import Principal from './layouts/Principal/Principal.jsx';
import createBrowserHistory from 'history/createBrowserHistory';
import PublicRouters from './routes/router';
const history =  createBrowserHistory();


const theme = createMuiTheme({
  palette: {
    primary: { main: '#00adb5' }, // Purple and green play nicely together. #091635 '#4ac4f3 #00359B'
    secondary: {main: '#222831'} //2196F3
  },
  typography: {
    useNextVariants: true,
    fontFamily:[
      //'Montserrat',
      'Octarine',
      /* 'Titillium Web',
      'Nunito Sans',
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"', */
    ].join(','),
  },
  overrides:{
    MuiTypography:{
      h1:{
        fontSize:'5rem',
        fontWeight:600
      },
      h2:{
        fontSize:'4rem',
        fontWeight:600
      },
      h3:{
        fontSize:'3.2rem',
        fontWeight:600
      },
      h4:{
        fontWeight:600,
        fontSize:'2.5rem'
      },
      h5:{
          fontSize:'2.2rem',
          fontWeight:600
      },
      h6:{
        fontSize:'2rem',
        fontWeight:600
      },
      subtitle1:{
        fontSize:'1.5rem',
        fontWeight:600,
      },
      subtitle2:{
        fontSize:'1.2rem',
        fontWeight:600,
      },
      body1:{
          fontSize:'1.2rem',
      },
      body2:{
          fontSize:'1rem',
          fontWeight:500,
      },
      caption:{
        fontSize:'0.875rem',
        fontWeight:500,
      },
      button:{
        fontSize:'1.125rem'
      },
    }
  }
});



class App extends Component {




  render() {

    return (
      <MuiThemeProvider theme={theme}>
        <div sytle={{flexGrow:1, overflowX:'hidden'}}>
          <PublicRouters history={history}/>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;


/* return (
  <MuiThemeProvider theme={theme}>
    <div sytle={{flexGrow:1, overflowX:'hidden'}}>
      <Principal history={history}/>
    </div>
  </MuiThemeProvider>
); */
