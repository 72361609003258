import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import CarouselSection from '../../../../../../components/Projects/CarouselSection/CarouselSection.jsx';

class SectionC extends Component{

    render(){

        const {classes, content} = this.props;

        return(
            <div className={classes.section}>
                <div className={classes.container}>
                    <CarouselSection 
                        grey
                        title={content.title} 
                        {...content}
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionC);