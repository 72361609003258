//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';
//
const styles = (theme) => ({
    root:{
        height:'100%',
        position:'relative',
        overflow:'hidden',
        //background:'red'
    },
    videoContainer:{    
        //position:'absolute',
        top:0,
        bottom:0,
        width:'100%',
        //height:'100vh',
        overflow:'hidden',
        "&:after": {
            //position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            background: 'rgba(0,0,0,0.6)',
            //background:'red'
        },

        //new
        position:'relative',
        height:'100vh',
        //overflow:'hidden',
        [theme.breakpoints.down('md')]:{
            //position:'absolute',
            height:'100%'
        }
    },
    video:{
        //position:'absolute',
        height:'100%',
        width:'100%',
        objectFit:'cover',
        [theme.breakpoints.down('md')]:{
            height:'auto'
            //position:'relative',
            //height:'100%'
        }
        //height:'100%'
        //height:'100vh',
        //minHeight:'100vh',
        //zIndex:-1,
    },
    video_mask:{
        position:'absolute',
        bottom:0,
        left:0,
        width:'100%',
        height:'100%',
        background:'rgba(0,0,0,0.6)',
        //background:'red',
        zIndex:20,
        
    },
    textContainer:{
        width:'100%',
        position:'relative',
        height:'100vh',
        //display: 'flex',
        //alignItems: 'center',
        //justifyContent: 'center',
        padding:'15% 80px 40px',
        color:'white',
        zIndex:10,
        //height:'100%',
        boxSizing:'border-box',
        //background:'red',
        [theme.breakpoints.down('sm')]:{
            //height:'100%',
            height:'100%',
            display:'flex',
            alignItems:'center',
            padding:'140px 32px'
        }
    },
    divider:{
        height:16,
        width:500,
        borderRadius:24,
        background:theme.palette.primary.main,
        margin:'40px 0px',
        [theme.breakpoints.down('sm')]:{
            width:300
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        }
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'4rem'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'3.2rem'
        }
    },
    text1:{
        fontSize:'2rem',
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.6rem'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'1.2rem'
        }
    },
    text2:{
        fontSize:'2.2rem',
        fontWeight:600,
        marginTop:32, maxWidth:750,
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.6rem'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'1.4rem'
        }
    }
    /* textTitleA:{
        letterSpacing:theme.spacing.unit*4,
        color:'white',
        fontWeight:'300',
        fontSize:'7em'
    },
    textTitleB:{
        letterSpacing:theme.spacing.unit*4,
        color:'white',
        fontWeight:'600',
        fontSize:'7em'
    },
    textSubtitle:{
        letterSpacing:theme.spacing.unit*2,
        color:'white',
        fontWeight:'600'
    },
    textContent:{
        letterSpacing:theme.spacing.unit*0.2,
        color:'white',
        fontWeight:'400'
    }, */
    
})

export default styles;