//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';
//
import background from '../../../../../../assets/Technologies/background_Mobile/background.jpg'


const styles = (theme) => ({
    root:{
        height:'100vh',
        position:'relative',
        overflow:'hidden'
    },
    background:{
        position:'absolute',
        backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(${background})`,
        width:'100%',
        height: '100vh',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        zIndex:-1,
    },
    textContainer:{
        width:'100%',
        position:'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color:'white',
        zIndex:10,
        height:'100vh'
    },
    textTitle:{
        letterSpacing:theme.spacing.unit*1.2,
        color:'white',
        fontWeight:'700',
        fontSize:'3em'
    },
    textSubtitleA:{
        letterSpacing:theme.spacing.unit*0.7,
        color:'white',
        fontWeight:'300',
        fontSize:'3em',
    },
    textSubtitleB:{
        letterSpacing:theme.spacing.unit*0.7,
        color:'white',
        fontWeight:'600',
        fontSize:'3em',
    },
    textContent:{
        letterSpacing:theme.spacing.unit*0.2,
        color:'white',
        fontWeight:'400',
        padding:'0px 16px'
    },
    
})

export default styles;