import React, {Component} from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import cx from 'classnames';
import moment from 'moment-timezone'
import logo from '../../../assets/LOGO-ALTHAR.png';
import planet from '../../../assets/Home/PLANETA.png';
import contact from '../../../assets/Contact/ICONO-CONTACT.png';

class Footer extends Component{


  render(){

    const {classes, content, onChange, sections, history} = this.props;

    return(
      <div className={classes.root}>
          <div className={classes.container}>
            <div style={{display:'flex', justifyContent:'center'}} >
              <div className={classes.contact_box} onClick={() => onChange('/contact')} >
                <img src={contact} height={140} />
              </div>
            </div>
            <div className={classes.info_container}>
              <a className={classes.link} href='/terms' target='_blank'  >
                <Typography>Terms and Conditions</Typography>
              </a>
              <a className={classes.link} href='/privacy' target='_blank'  >
                <Typography>Privacy Policy</Typography>
              </a>
              <div className={classes.line}></div>
              <Typography>© 2023 All rights reserved. ālthar is a registered trademark.</Typography>   
            </div>  
            <div className={classes.planet_container}>
              <img src={planet} width={'100%'} />
            </div>        
          </div>
      </div>
    )
  }
}

export default withStyles(styles)(Footer);


{/* <Grid container alignItems='center' justifyContent='center' spacing={5}>
              <Grid item>
                <img src={logo} width={100} />
              </Grid>
              <div className={classes.planet_container}>
                <img src={planet} width={'100%'} />
              </div>
              {content.content.links.map((item,key) => {
                  return(
                    <Grid item md='auto' xs={12} key={key.toString()}>
                      <div onClick={() => onChange(item.link)} >
                        <Typography className={classes.navlink} >{item.name}</Typography>
                      </div>
                    </Grid>
                  )
                })
              }
              <Grid item md='auto' xs={12}></Grid>
              <Grid item>
                <div className={classes.button} onClick={() => onChange('/contact')}>
                  <Typography variant='subtitle1' >Contact us</Typography>
                </div>
              </Grid>
            </Grid> */}


{/* <Grid container alignItems='center'>
              <Grid item xs={1}>
              <Grid container>
                <Grid item>
                    <Typography className={classes.title}> 
                      xand
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.title2}> 
                      box
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{flex:1}}>
                <Grid container spacing={8}>
                  {
                    content.content.links.map((item,key) => {
                      
                      return(
                        <Grid item key={key.toString()}>
                          <div onClick={() => onChange(item.link)} className={classes.navlink}>{item.name}</div>
                        </Grid>
                      )
                    })
                  }
                  <Grid item>
                    <div onClick={() => onChange(content.content.quotation.link)} className={classes.navlinkB}>{content.content.quotation.name}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  {
                    content.content.socialmedia.map((item,key)=>{
                      return(
                        <Grid item key={key.toString()}>
                          <div className={cx({
                            [classes.imageContainer]:true,
                            [classes.facebook]: item.name === 'facebook',
                            [classes.twitter]: item.name === 'twitter',
                            [classes.instagram]: item.name === 'instagram',
                            [classes.linkedin]: item.name === 'linkedin'
                          })}>
                            <img src={item.icon} alt='' className={classes.image}/>
                          </div>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            </Grid> */}
