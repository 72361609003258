import React from "react"
import { makeStyles, Slide, Typography, Zoom } from "@material-ui/core"
import InfoItem from "../InfoItem"
import image from '../../../../../../../assets/Home/VR.png'
import WorkModal from "../WorkModal"



const Vr = ({open, onClose}) => {

    const classes = useStyles()

    return(
        <WorkModal open={open} onClose={onClose}>
            <div className={classes.root} >
                <Typography variant='h3' className={classes.title} >VR for Oncology Management</Typography>
                <Zoom in timeout={750} style={{ transitionDelay:'500ms' }}>
                <div className={classes.challenge}>
                    <InfoItem 
                        title='Challenge.'
                        info='A Mexican private oncology institute was in need 
                        of adding value for its patients in chemotherapy 
                        schemes. The current program provokes sad 
                        and rejection feelings according to our research.'
                    />
                </div>
                </Zoom>
                <Zoom in timeout={750} style={{ transitionDelay:'1000ms' }}>
                <div className={classes.solution}>
                    <InfoItem 
                        title='Solution.'
                        info='We create, design and develop the first Latin
                        American virtual reality program for oncology 
                        patients in chemotherapy.'
                    />
                </div>
                </Zoom>
                <Zoom in timeout={750} style={{ transitionDelay:'1500ms' }} >
                <div className={classes.result}>
                    <InfoItem 
                        title='Result.'
                        info='100% adherence to the chemotherapy program. 
                        Pain scales with a 30% reduction and stress 
                        levels of 40% reduction (cortisol measured).'
                    />
                </div>
                </Zoom>
                
                <Slide direction="up" in timeout={1000} >
                    <div className={classes.img_container}>
                        <img src={image} width={'100%'} className={classes.img} />
                    </div>
                </Slide>
            </div>
            {/* <div className={classes.line} style={{top:260, right:'28%', width:250}} />
            <div className={classes.line} style={{top:470, right:'28%', width:230}} />
            <div className={classes.line} style={{top:650, right:'28%', width:210}} /> */}
        </WorkModal>
    )
}

export default Vr

const useStyles = makeStyles(theme => ({
    root:{
      position:'relative',
      //padding:70,
      color:'white',
      textAlign:'right',
      alignItems:'end',
      display:'flex',
      flexDirection:'column',
      [theme.breakpoints.down('sm')]:{
        //padding:32,
        textAlign:'left',
        alignItems:'start'
        //display:'initial'
        }
    },
    title:{
        textAlign:'right',
        marginBottom:20,
        maxWidth:500,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem',
            textAlign:'center',
            //background:'red',
            maxWidth:'100%',
            marginTop:40,
            }
    },
    challenge:{
        maxWidth:420,
        marginTop:20,
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
  
    },
    result:{
        maxWidth:420,
        marginTop:20,
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
     
    },
    solution:{
        maxWidth:420,
        marginTop:20,
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
      
    },
    img:{
        transform:'rotate(-12deg) scale(1.2)',
        [theme.breakpoints.down('md')]:{
            transform:'rotate(-12deg) scale(1)',
        },
        [theme.breakpoints.down('sm')]:{
            transform:'rotate(0deg) scale(0.8)',
            //background:'pink'
        },
    },
    img_container:{
        position:'absolute',
        top:60,
        left:'20%',
        /* [theme.breakpoints.down('lg')]:{
            top:300
        }, */
        [theme.breakpoints.down('md')]:{
            left:0,
            //background:'red',
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:0,
            left:'initial',
            margin:'auto',
            //height:700,
            //background:'blue'
        }
    },
    line:{
        position:'absolute',
        border:'1px solid white',
        [theme.breakpoints.down(1500)]:{
            display:'none'
        },
    }

  }))



