//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';


const styles = (theme) => ({
    section:{

    },
    container:{

    },
    
})

export default styles;