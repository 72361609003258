import React from "react"
import { makeStyles, Slide, Typography, Zoom } from "@material-ui/core"
import InfoItem from "../InfoItem"
import image from '../../../../../../../assets/Home/IMAGEN-TELEPHARMACY.png'
import WorkModal from "../WorkModal"



const Telepharmacy = ({open, onClose}) => {

    const classes = useStyles()

    return(
        <WorkModal open={open} onClose={onClose}>
            <div className={classes.root} >
                <Typography variant='h3' className={classes.title} >Telepharmacy</Typography>
                <Zoom in timeout={750} style={{ transitionDelay:'500ms' }}>
                <div className={classes.challenge}>
                    <InfoItem 
                        title='Challenge.'
                        info='Help Celu–an ālthar-in-residence-startup–
                        build a venture strategy for its prescription 
                        home-delivery system and virtual franchise 
                        model.'
                    />
                </div>
                </Zoom>
                <Zoom in timeout={750} style={{ transitionDelay:'1000ms' }}>
                <div className={classes.solution}>
                    <InfoItem 
                        title='Solution.'
                        info='A refined brand vision, strategy, and identity 
                        across channels, as well as a mobile-first PWA 
                        and app, a private dashboard for Celu 
                        customers and a suite of physical products.'
                    />
                </div>
                </Zoom>
                <Zoom in timeout={750} style={{ transitionDelay:'1500ms' }} >
                <div className={classes.result}>
                    <InfoItem 
                        title='Result.'
                        info='Celu now delivers thousands of prescriptions 
                        every month.'
                    />
                </div>
                </Zoom>
                
                <Slide direction="up" in timeout={1000} >
                    <div className={classes.img_container}>
                        <img src={image} width={'100%'} className={classes.img} />
                    </div>
                </Slide>
            </div>
        </WorkModal>
    )
}

export default Telepharmacy

const useStyles = makeStyles(theme => ({
    root:{
      position:'relative',
      //padding:70,
      color:'white',
      textAlign:'right',
      alignItems:'end',
      display:'flex',
      flexDirection:'column',
      [theme.breakpoints.down('sm')]:{
        //padding:32,
        textAlign:'left',
        alignItems:'start'
        //display:'initial'
        }
    },
    title:{
        textAlign:'right',
        marginBottom:20,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem',
            textAlign:'center',
            //background:'red',
            width:'100%',
            marginTop:40,
            marginBottom:0
            }
    },
    challenge:{
        maxWidth:420,
        marginTop:20,
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
  
    },
    result:{
        maxWidth:420,
        marginTop:20,
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
     
    },
    solution:{
        maxWidth:420,
        marginTop:20,
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
      
    },
    img:{
        transform:'rotate(-12deg) scale(1.2)',
        [theme.breakpoints.down('md')]:{
            transform:'rotate(-12deg) scale(1)',
        },
        [theme.breakpoints.down('sm')]:{
            transform:'rotate(4deg) scale(0.8)',
            //background:'pink'
        },
    },
    img_container:{
        position:'absolute',
        top:60,
        left:'20%',
        /* [theme.breakpoints.down('lg')]:{
            top:300
        }, */
        [theme.breakpoints.down('md')]:{
            left:0,
            //background:'red',
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:0,
            left:'initial',
            margin:'auto',
            //height:700,
            //background:'blue'
        }
    },
    line:{
        position:'absolute',
        border:'1px solid white',
        [theme.breakpoints.down(1660)]:{
            display:'none'
        },
    }

  }))



