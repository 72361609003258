//import { fade } from '@material-ui/core/styles/colorManipulator';
import {blue, grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        margin:'100px 0px',
    },
    container:{
        boxSizing:'border-box',
        width:'1200px',
        margin:'auto',
        padding:'16px',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'100%',
        },
    },
    title:{
        fontWeight:'600',
        maxWidth:'500px',
    },
    text:{
        color:grey[500]
    },
    content:{
        minHeight:'30%',
        padding:'80px 40px 0px 40px'
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300],
    },
    tag:{ 
        width:'50px',
        minHeight:'10px',
        background:grey[300],
        borderRadius:32,
        cursor:'pointer',
        [theme.breakpoints.down('sm')]: {
            width:40,
        },
    },
    activeTag:{
        background:theme.palette.secondary.main
    },
    fixedImage:{
        width:'100%',
        height:'auto'
    },
    fixedImageMobile:{
        width:'80%',
        height:'auto'
    },
    imageMonitor:{
        position:'absolute',
        width:'93%',
        height:'63%',
        transform:'translate(-104%, 7%)',
        zIndex:-1,
    },
    imageMonitor2:{
        width: '93%',
        height: '63%',
        zIndex: -1,
        position: 'absolute',
        transform: 'translate(-104%, 13%)',
        objectFit: 'cover'
    },
    imageLaptop:{
        position:'absolute',
        width:'75%',
        height:'79%',
        objectFit:'fill',
        transform:'translate(-116.5%, 6%)',
        zIndex:-1
    },
    imageTablet:{
        position:'absolute',
        width:'67.5%',
        height:'48.5%',
        transform:'translate(-136%,14%)',
        objectFit:'fill',
        zIndex:-1
    },
    imagePhone:{
        width: '34%',
        height: '65%',
        position: 'absolute',
        transform: 'translate(-143%,7%)',
        objectFit: 'fill',
        zIndex: -1
    },
    hidden:{
        opacity: 0,
        display:'none',
    },
    show:{
        opacity:1,
        display:'block',
        transition: 'display 0s, opacity 1s ease-out'
    }
})

export default styles;