import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Slide} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';
import StepProcess from '../../../../../../components/Home/StepProcess/StepProcess.jsx';
class SectionE extends Component{

    state = {
      title:[false,false],
      steps:[false,false,false,false]
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.props.content !== nextProps.content ||
               JSON.stringify(this.state) !== JSON.stringify(nextState)
    }

    onInview = (event, key, pos) => {
      let temp = [...this.state[key]];
      temp[pos] = true;
      this.setState({[key]:[...temp]})
    }

    render(){

        const {classes, content} = this.props;
        const {steps, title} = this.state;

        return(
          <div className={classes.section}>
            <div className={classes.container}>
              <Grid container direction='column' align='center'>
                <Grid item>  
                  <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                    <Slide direction='left' in={title[0]} timeout={1000}>
                      <Typography variant='h4' align='center' className={classes.about_title}>
                          {content.title}
                      </Typography>
                    </Slide>
                  </InViewMonitor>
                </Grid>
                <Grid item>
                  <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                    <Slide direction='right' in={title[1]} timeout={1000}>
                    <div className={classes.divider}/>
                    </Slide>
                  </InViewMonitor>
                    
                </Grid>
                <Grid item>  
                    <div className={classes.about_content_container}>
                        <Typography variant='body1' align='center' className={classes.about_content}>
                            {content.content}
                        </Typography>
                    </div>
                </Grid>
              </Grid>
              <div className={classes.subcontainer}>
                <div className={classes.lineContainer}>
                  <div className={classes.line}/>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{height:'100px'}}/>
                  </Grid>
                  {
                    content.data.map((item,key) => {
                      const pos = key%2 === 0 ? 'left' : 'right'; 
                      return (
                        <Grid item xs={12} key={(key*2).toString()}>
                          <InViewMonitor onInView={(event) => this.onInview(event, 'steps', key)} >
                            <StepProcess
                              data={{
                                number:item.number,
                                icon: item.icon,
                                title:item.title,
                                content:item.content,
                              }}
                              color={key % 4}
                              position={pos}
                              visible={steps[key]}
                              ismobile
                            />
                          </InViewMonitor>
                        </Grid>
                        
                      )
                    })
                  }
                  
                  <Grid item xs={12}>
                    <div style={{height:'50px'}}/>
                  </Grid>
                </Grid>
              </div>
              
            </div>
          </div>
        )
    }
}

export default withStyles(styles)(SectionE);