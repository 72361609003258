//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';
import background from '../../../../../../assets/Home/background1.jpg';

const styles = (theme) => ({
    background:{
        position:'absolute',
        backgroundImage: `url(${background})`,
        width:'100%',
        height: '100%',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        zIndex:-1,
        "&:after": {
            position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            background: 'rgba(0,0,0,0.8)'
        }
    },
    section:{
        padding:'100px 0px',
        background:grey[50]
    },
    container:{
        boxSizing:'border-box',
        width:'1000px',
        margin:'auto',
        padding:'16px',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'100%',
        },
    },
    subcontainer:{
        position:'relative',
    },
    lineContainer:{
        position:'absolute',
        width:'100%',
        height:'100%',
        margin:'auto',
    },
    line:{
        width:'2px',
        height:'100%',
        background:grey[300],
        zIndex:10,
        margin:'auto'
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    about_title:{
        fontWeight:'700',
        maxWidth:'500px'
    },
    about_content_container:{
        maxWidth:'550px',
        margin:'24px'
    },
    about_content:{
        color:grey[200],
        fontWeight:'700',
        fontSize:'6em'
    },
    button:{
        background:'#4b6bf5',
        color:'white',
        padding:'12px 28px',
        borderRadius:'24px',
        textTransform:'none',
        fontSize:'1em',
        fontWeight:'600',
        '&:hover':{
            background:'#4b6bf5',
            boxShadow:'0 10px 20px rgba(75, 107, 245, 0.16), 0 6px 6px rgba(75, 107, 245, 0.23)'
        }
    },
})

export default styles;