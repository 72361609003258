import React from "react"
import { makeStyles, Slide, Typography, Zoom } from "@material-ui/core"
import InfoItem from "../InfoItem"
import image from '../../../../../../../assets/Home/IMAGE-ADVISORY-BOARD.png'
import WorkModal from "../WorkModal"



const Advisory = ({open, onClose}) => {

    const classes = useStyles()

    return(
        <WorkModal open={open} onClose={onClose}>
            <div className={classes.root} >
                <Typography variant='h3' className={classes.title} >Advisory Boards</Typography>
                <Zoom in={open} timeout={750} style={{ transitionDelay:'500ms' }}>
                <div className={classes.challenge}>
                    {/* <InfoItem 
                        title='Challenge.'
                        info='To know the state-of-the-art and real situation 
                        of the cuadro básico approval processes in the 
                        current turbulent healthcare system in Mexico.'
                    /> */}
                    <div >
                        <Typography className={classes.subtitle} >Challenge.</Typography>
                        <Typography style={{fontSize:'2vh'}} >To know the state-of-the-art and real situation 
                        of the <span style={{fontStyle:'italic'}} >cuadro básico</span> approval processes in the 
                        current turbulent healthcare system in Mexico.</Typography>
                    </div>
                </div>
                </Zoom>
                <Zoom in={open} timeout={750} style={{ transitionDelay:'1000ms' }}>
                <div className={classes.solution}>
                    <InfoItem 
                        title='Solution.'
                        info='We design and develop a special advisory 
                        board, with a very interactive methodology, 
                        taking care of being in compliance with pharma 
                        regulations.'
                    />
                </div>
                </Zoom>
                <Zoom in={open} timeout={750} style={{ transitionDelay:'1500ms' }} >
                <div className={classes.result}>
                    <InfoItem 
                        title='Result.'
                        info='Based on the results, our clients had been able 
                        to include their products with very innovative 
                        risk-sharing programs and new 
                        pharmacoeconomic models.'
                    />
                </div>
                </Zoom>
                <Slide direction="up" in timeout={1000} >
                    <div className={classes.img_container}>
                        <img src={image} width={'100%'} className={classes.img} />
                    </div>
                </Slide>
            </div>
        </WorkModal>
    )
}

export default Advisory

const useStyles = makeStyles(theme => ({
    root:{
      position:'relative',
      //padding:70,
      paddingTop:20,
      color:'white',
      textAlign:'right',
      alignItems:'end',
      display:'flex',
      flexDirection:'column',
      [theme.breakpoints.down('md')]:{
        padding:32
        },
      [theme.breakpoints.down('sm')]:{
        padding:0,
        textAlign:'left',
        alignItems:'start'
        //display:'initial'
        }
    },
    title:{
        textAlign:'right',
        marginBottom:20,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem',
            textAlign:'center',
            //background:'red',
            width:'100%',
            marginTop:40,
            }
    },
    subtitle:{
        fontSize:'3vh',
        fontWeight:600,
        marginBottom:16,
        [theme.breakpoints.down('sm')]:{
          marginBottom:0
      }
      },
    challenge:{
        maxWidth:460,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:400
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
  
    },
    result:{
        maxWidth:460,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:400
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
     
    },
    solution:{
        maxWidth:460,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:400
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
      
    },
    img:{
        transform:'scale(1.4)',
        [theme.breakpoints.down('lg')]:{
            transform:'scale(1)',
        },
        [theme.breakpoints.down('sm')]:{
            transform:'scale(0.8)',
        },
        /* [theme.breakpoints.down('sm')]:{
            transform:'rotate(4deg) scale(0.8)',
            //background:'pink'
        }, */
    },
    img_container:{
        position:'absolute',
        top:150,
        left:'5%',
        [theme.breakpoints.down('lg')]:{
            left:0
        },
        [theme.breakpoints.down('md')]:{
            left:'-25%',
            //background:'red',
        },
        [theme.breakpoints.down('sm')]:{
            left:0,
            position:'relative',
            top:0,
            left:'initial',
            margin:'auto',
            //height:700,
            //background:'blue'
        }
    },
    line:{
        position:'absolute',
        border:'1px solid white',
        right:'32%',
        [theme.breakpoints.down('lg')]:{
            right:'36%',
        },
        [theme.breakpoints.down(1510)]:{
            display:'none'
        },
    }

  }))



