import { Icon, makeStyles, Typography, Button} from "@material-ui/core"
import React from "react"
import useSectionC from "./useSectionC"


const SectionD = () => {

    const classes = useStyles()
    const { actions, form, active_not, user, system, modals} = useSectionC({})

    return(
        <div className={classes.root} id='resources4' >
            <div style={{textAlign:'center'}} >
                <Typography className={classes.title} >¿Qué quieren los médicos? 2023</Typography>
                <div>
                    <Button onClick={actions.onDownloadReport} className={classes.button} endIcon={<div className={classes.icon_container} ><Icon color='primary' className={classes.icon} >download</Icon></div>} 
                     >
                        <Typography className={classes.button_text} >Descarga aquí el reporte</Typography>
                    </Button> 
                </div>
            </div>
        </div>
    )
}

export default SectionD

const useStyles = makeStyles(theme => ({
    root:{
        height:'100vh',
        background:theme.palette.secondary.main,
        color:'white',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.down('md')]:{
            padding:'0px 50px'
        },
        [theme.breakpoints.down('sm')]:{
            height:'100%',
            padding:'100px 50px'
        }
    },
    title:{
        fontSize:'9vh', fontWeight:600, 
        maxWidth:1400, marginBottom:'10vh',
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'2rem'
        }
    },
  
    button:{
        background:theme.palette.primary.main,
        borderRadius:40,
        color:'white',
        textTransform:'none',
        padding:'8px 32px',
        '&:hover':{
            background:theme.palette.primary.main,
            opacity:0.8
        }
    },
    button_text:{
        fontSize:'5vh', fontWeight:600,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2rem'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'1.5rem'
        },
    },
    icon_container:{
        background:'white',
        padding:4,
        borderRadius:'50%',
        width:40,
        height:40,
        marginLeft:12,
        marginRight:-8,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    icon:{
        fontSize:'2.5rem'
    }
      
}))

