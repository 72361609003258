//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';
//
import background from '../../../../../../assets/About/background.jpg';


const styles = (theme) => ({
    root:{
        position:'relative',
        overflow:'hidden'
    },
    background:{
        position:'absolute',
        backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(${background})`,
        width:'100%',
        height: '100%',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        zIndex:-1,
    },
    textContainer:{
        width:'100%',
        position:'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color:'white',
        zIndex:10,
        padding:'200px 0px',
        paddingTop:'300px'
    },
    textTitle:{
        letterSpacing:theme.spacing.unit*2,
        color:'white',
        fontWeight:'700'
    },
    textSubtitleA:{
        letterSpacing:theme.spacing.unit*1,
        color:'white',
        fontWeight:'300'
    },
    textSubtitleB:{
        letterSpacing:theme.spacing.unit*1,
        color:'white',
        fontWeight:'600'
    },
    textContent:{
        letterSpacing:theme.spacing.unit*0.2,
        color:'white',
        fontWeight:'400'
    },
    
})

export default styles;