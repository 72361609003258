import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Grid} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';

import SolutionWrapper from '../../../../../../components/Home/SolutionWrapper/SolutionWrapper.jsx';



class SectionD extends Component{

    state = {
      wrappers: [false,false]
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.props.content !== nextProps.content ||
                JSON.stringify(this.state) !== JSON.stringify(nextState)
    }

    onInview = (event, key, pos) => {
      let temp = [...this.state[key]];
      temp[pos] = true;
      this.setState({[key]:[...temp]})
    }

    render(){

        const {classes, content, sendToPage} = this.props;
        const {wrappers} = this.state;
        return(
          <div className={classes.section}>
            <Grid container>
              {content.solutions.map((item,key)=>{
                let position = key % 2 === 0 ? 'left' : 'right';
                return(
                  <Grid item xs={12} key={key.toString()}>
                    <InViewMonitor onInView={(event) => this.onInview(event, 'wrappers', key)} >
                      <SolutionWrapper
                        subtitle={item.subtitle}
                        title={item.title}
                        content={item.content}
                        image={item.image}
                        button={item.button}
                        url={item.url}
                        position={position}
                        visited={wrappers[key]}
                        onClick={sendToPage}
                      />
                    </InViewMonitor>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        )
    }
}

export default withStyles(styles)(SectionD);