import facebook from '../../assets/Home/Social/facebook.png';
import twitter from '../../assets/Home/Social/twitter.png';
import linkedin from '../../assets/Home/Social/linkedin.png';
import instagram from '../../assets/Home/Social/instagram.png';

export const contentData = {
  spanish:{
    content:{
      title:'Xandbox',
      links:[
        {name:'Home', link:'/home'},
        {name:'Servicios', link:'/services'},
        {name:'Tecnologías', link:'/technologies'},
        {name:'Nosotros', link:'/about'},
      ],
      quotation:{
        name:'Solicita tu cotización', link:'/contact'
      },
      socialmedia:[
        {icon:twitter,name:'twitter', link:''},
        {icon:facebook,name:'facebook', link:''},
        {icon:instagram,name:'instagram', link:''},
        {icon:linkedin,name:'linkedin', link:''},
      ]
    },
    contentB:{
      title:'Secciones',
      content:['Home','Soluciones','Proyectos','Nosotros']
    },
    contentC:{
      title:'Contáctanos',
      content:[
        
      ]
    }
  },
  english:{
    content:{
      title:'Xandbox',
      links:[
        {name:'Home', link:'/home'},
        {name:'Work', link:'/work'},
        {name:'Services', link:'/services'},  
        //{name:'Contact', link:'/contact'},
      ],
      quotation:{
        name:'Request your quote', link:'/contact'
      },
      socialmedia:[
        {icon:twitter,name:'twitter', link:''},
        {icon:facebook,name:'facebook', link:''},
        {icon:instagram,name:'instagram', link:''},
        {icon:linkedin,name:'linkedin', link:''},
      ]
    },
    contentB:{
      title:'Secciones',
      content:['Home','Soluciones','Proyectos','Nosotros','Contacto']
    },
    contentC:{
      title:'Contáctanos',
      content:[
        
      ]
    }
  }
}