//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';
import background from '../../assets/FONDO.jpg';

const styles = (theme) => ({
  container:{
    display:'flex',
    verticalAlign:'middle',
    height:'100vh',
    overflow:'hidden',
    backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(${background})`,
  }, 
  logo:{
    width:'400px',
    [theme.breakpoints.down('sm')]:{
      width:200
    }
  },
  spinnerContainer:{
    marginTop:'40px'
  },
    
})

export default styles;