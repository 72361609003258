import React, {Component} from 'react'
import {
  Grid,
  Popper,
  ClickAwayListener,
  Paper,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {grey} from '@material-ui/core/colors'

import mxFlag from '../../../assets/Flags/mexico.png'
import usFlag from '../../../assets/Flags/united-states.png'

const flags = [
  {language:'spanish',code:'ESP',image:mxFlag},
  {language:'english',code:'ENG',image:usFlag},
]

const styles = (theme) => ({
  root:{
    position:'relative',
    zIndex:theme.zIndex.drawer+1
  },
  image:{
    width:32
  },
  button:{
    '&:hover':{
      cursor:'pointer'
    }
  },
  popper:{
    borderRadius:'4px',
    zIndex:theme.zIndex.drawer+1
  },
  paper:{
  },
  textItem:{
    fontWeight:'500',
    color:grey[700]
  },
  menuItem:{
    padding:'8px',
    '&:hover':{
      background:'rgba(0,0,0,0.1)',
      cursor:'pointer'
    }
  }
})

class LanguageButton extends Component {

  state = {
    language:'spanish',
    anchorEl:null,
    open:false
  }

  handleClick = (event) => {
    this.setState({
      anchorEl:event.currentTarget,
      open:true
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl:null,
      open:false
    })
  }

  onChange = (language) => {
    this.setState({language:language})
    this.setState({
      anchorEl:null,
      open:false
    })
    this.props.onChange(language)
  }

  render(){

    const {anchorEl, open} = this.state
    const {classes, language} = this.props

    let imageSrc = null
    switch (language) {
      case 'spanish':
        imageSrc=mxFlag
        break
      case 'english':
          imageSrc=usFlag
          break
      default:
        break;
    }

    return(
      <div className={classes.root}>
        <div className={classes.button} onClick={this.handleClick}>
          <img src={imageSrc} alt='' className={classes.image}/>
        </div>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={'bottom-start'}
          className={classes.popper}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={this.handleClose}>
              <Grid container direction='column' spacing={1}>
                {flags.map((item,key)=>{
                  return(
                    <Grid item key={key.toString()}>
                      <div 
                        className={classes.menuItem}
                        onClick={() => this.onChange(item.language)} 
                      >
                        <Grid container alignItems='center' spacing={1}>
                          <Grid item>
                            <img src={item.image} alt='' className={classes.image}/>
                          </Grid>
                          <Grid item xs>
                            <Typography variant='body2' className={classes.textItem}>{item.code}</Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  )
                })}

              </Grid>

            </ClickAwayListener>

          </Paper>
        </Popper>
      </div>
    )
    
  }
}

export default withStyles(styles)(LanguageButton)
