import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Button } from '@material-ui/core';
import cx from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';


const styles = (theme) => ({
    root:{margin:'0px 0'},
    icon:{
        fontSize:'48px',
        color:fade(theme.palette.secondary.main,0.9),
    [theme.breakpoints.down('md')]:{
        fontSize:'38px',
    },
    },
    title:{
        fontWeight:'600',
        color:grey[900],
        fontSize:'1.3em'
        
    },
    content:{
        fontWeight:'400',
        color:grey[700],
        fontSize:'0.95em',
    },
    container:{
        padding:'72px 60px',
        [theme.breakpoints.only('md')]: {
            padding:'36px 30px',
        },
    },
    imageContainer:{
        marginBottom:'8px',
        width:'28px',
        height:'28px',
        padding:theme.spacing.unit*3,
        borderRadius:'50%',
        backgroundColor:'#4b6bf5',
    },
    image:{
        width:'100%'
    },
    colorA:{
        background:'linear-gradient(135deg, #ff4dae, #4268ff)'
    },
    colorB:{
        background:'linear-gradient(135deg, #ff9f2e, #f723c4)'
    },
    colorC:{
        background:'linear-gradient(135deg, #00f2aa, #0083ff)'
    },
    colorD:{
        background:'linear-gradient(135deg, #ffe10a, #ff3e5c)'
    },
    button:{
        fontSize:'0.7em',
        color:grey[900],
        fontWeight:'700',
        letterSpacing: '0.1em',
        padding:0,
        paddingTop:theme.spacing.unit*1,
        paddingBottom:theme.spacing.unit*0.5,
        borderRadius:0,
        borderBottom:`2px solid ${grey[400]}`,
        '&:hover':{
            background:'transparent',
            borderBottom:`2px solid ${grey[700]}`,
        }
    },
    paper:{
        borderRadius:0,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        minHeight:'480px',
        [theme.breakpoints.only('md')]: {
            minHeight:'400px',
        },
    }
})

class SimpleIconCard extends Component{

  render(){

    const {classes, data, onClick } = this.props;

    return(
      <div className={classes.root}>
        <Paper className={classes.paper}>
            <div className={classes.container}>
                <Grid container direction='column' alignItems='center' spacing={4}>
                    <Grid item>
                        <div 
                            className={cx({
                                [classes.imageContainer]:true,
                                [classes.colorA]:data.color === 0,
                                [classes.colorB]:data.color === 1,
                                [classes.colorC]:data.color === 2,
                                [classes.colorD]:data.color === 3
                            })}
                        >
                            <img src={data.icon} alt='' className={classes.image}/>
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant='h5' align='center' className={classes.title}>
                            {data.title}
                        </Typography>
                    </Grid> 
                    <Grid item>
                        <Typography variant='h6' align='center' className={classes.content}>
                            {data.content}
                        </Typography>
                    </Grid> 
                    <Grid item>
                        <Button className={classes.button} onClick={onClick}>
                            {data.button}
                        </Button>
                    </Grid>
                    <Grid item style={{flex:1, flexDirection:'column'}}> 
                    </Grid>
                </Grid>
            </div>
            
        </Paper>
        
      </div>
    )
  }
}

export default withStyles(styles)(SimpleIconCard);
