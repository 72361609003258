import React from "react"
import { makeStyles, Slide, Typography, Zoom } from "@material-ui/core"

import image from '../../../../../../../assets/Home/IMAGEN-GLUK.png'
import WorkModal from "../WorkModal"
import InfoItem from "../InfoItem"



const Gluck = ({open, onClose }) => {

    const classes = useStyles()

    return(
        <WorkModal open={open} onClose={onClose} >
            <div >
                <Typography variant='h3' className={classes.title} >gluck!</Typography>
                <Zoom in={open} timeout={750} style={{ transitionDelay:'500ms' }}>
                <div className={classes.challenge}>
                    <InfoItem 
                        title='Challenge.'
                        info='Design a glucometer easy-to-use, 
                        easy-to-carry, and with an engaging 
                        appearance for the patients to 
                        increase self-monitoring, without fear,
                        stigma, or social restrictions.'
                    />
                </div>
                </Zoom>
                <Zoom in={open} timeout={750} style={{ transitionDelay:'1000ms' }}>
                <div className={classes.solution}>
                    <InfoItem 
                        title='Solution.'
                        info='gluck!, the first glucometer with a 
                        real user-friendly experience, 
                        screenless, buttonless, with 
                        AI-based hardware 
                        development, and with an 
                        exterior design that provokes 
                        showing off.'
                    />
                </div>
                </Zoom>
                <Zoom in={open} timeout={750} style={{ transitionDelay:'1500ms' }} >
                <div className={classes.result}>
                    <InfoItem 
                        title='Result.'
                        info='gluck! has proven 57% more 
                        adherence in self-monitoring rates 
                        with type 2 diabetes patients.
                        Patients feel supported by AI advice. 
                        Patients also manifest that they feel 
                        gluck! as a fashion device that doesn’t 
                        generate social rejection; on the 
                        contrary, people around love gluck!'
                    />
                </div>
                </Zoom>
                <Slide direction="up" in={open} timeout={1000} >
                    <div className={classes.img_container}>
                        <img src={image} width={'100%'} className={classes.img} />
                    </div>
                </Slide>
            </div>
        </WorkModal>
    )
}

export default Gluck

const useStyles = makeStyles(theme => ({
    root:{
      /* position:'relative',
      padding:70,
      color:'white',
      [theme.breakpoints.down('sm')]:{
        padding:32,
        } */
        overflowX:'hidden'
    },
    title:{
        marginBottom:20,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem',
            textAlign:'center',
            //background:'red',
            width:'100%',
            marginTop:40,
            }
    },
    challenge:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:380
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
  
    },
    result:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:380
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
     
    },
    solution:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:340
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
      
    },
    img:{
        transform:'scale(1.2)',
        [theme.breakpoints.down('lg')]:{
            transform:'scale(1)',
        },
        [theme.breakpoints.down('md')]:{
            transform:'scale(0.8)',
        },
        [theme.breakpoints.down('sm')]:{
            transform:'scale(1)',
            //background:'pink'
        },
    },
    img_container:{
        position:'absolute',
        top:'25%',
        right:0,
        [theme.breakpoints.down('lg')]:{
            //right:'-17%'
        },
        [theme.breakpoints.down('md')]:{
            right:'-10%'
            //left:0,
            //background:'red',
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:0,
            right:'initial',
            margin:'auto',
            //height:700,
            //background:'blue'
        }
    },
    line:{
        position:'absolute',
        border:'1px solid white',
        left:'34%',
        [theme.breakpoints.down('lg')]:{
            left:'45%'
        },
        [theme.breakpoints.down(1530)]:{
            display:'none'
        },
    }

  }))



