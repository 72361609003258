import React from "react"
import { makeStyles, Slide, Typography, Zoom } from "@material-ui/core"

import image from '../../../../../../../assets/Home/LMS-ELEARNING.png'
import WorkModal from "../WorkModal"
import InfoItem from "../InfoItem"



const LMS = ({open, onClose }) => {

    const classes = useStyles()

    return(
        <WorkModal open={open} onClose={onClose} >
            <div >
                <Typography variant='h3' className={classes.title} >LMS & e-Learning</Typography>
                <Zoom in={open} timeout={750} style={{ transitionDelay:'500ms' }}>
                <div className={classes.challenge}>
                    <InfoItem 
                        title='Challenge.'
                        info='Every year in Mexico 55k physicians take the 
                        National Exam for Medical Residences (ENARM), 
                        and less than 9% manage to pass it. Most 
                        courses are face-to-face in the three main cities 
                        of the country. Online courses are incomplete, 
                        expensive, and not friendly at all.'
                    />
                </div>
                </Zoom>
                <Zoom in={open} timeout={750} style={{ transitionDelay:'1000ms' }}>
                <div className={classes.solution}>
                    <InfoItem 
                        title='Solution.'
                        info='ālthar academy created Galenox Pre-ENARM, a 
                        complete platform with more than 1 400 online 
                        courses in medicine, with a UX for millennial 
                        doctors, low cost, and 100% mobile-first.'
                    />
                </div>
                </Zoom>
                <Zoom in={open} timeout={750} style={{ transitionDelay:'1500ms' }} >
                <div className={classes.result}>
                    <InfoItem 
                        title='Result.'
                        info='In 2021 nearly 400 physicians pass the exam and 
                        will be specialists thanks to Galenox Pre-ENARM.'
                    />
                </div>
                </Zoom>
                <Slide direction="up" in={open} timeout={1000} >
                    <div className={classes.img_container}>
                        <img src={image} width={'100%'} className={classes.img} />
                    </div>
                </Slide>
            </div>
        </WorkModal>
    )
}

export default LMS

const useStyles = makeStyles(theme => ({
    root:{
      /* position:'relative',
      padding:70,
      color:'white',
      [theme.breakpoints.down('sm')]:{
        padding:32,
        } */
        overflowX:'hidden'
    },
    title:{
        marginBottom:20,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem',
            textAlign:'center',
            //background:'red',
            width:'100%',
            marginTop:40,
            }
    },
    challenge:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:380
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
  
    },
    result:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:380
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
     
    },
    solution:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:340
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
      
    },
    img:{
        transform:'rotate(8deg) scale(1.2)',
        [theme.breakpoints.down('lg')]:{
            transform:'rotate(8deg) scale(1)',
        },
        [theme.breakpoints.down('md')]:{
            transform:' rotate(8deg) scale(0.8)',
        },
        [theme.breakpoints.down('sm')]:{
            transform:'rotate(0deg) scale(1)',
            //background:'pink'
        },
    },
    img_container:{
        position:'absolute',
        top:60,
        right:0,
        [theme.breakpoints.down('lg')]:{
            right:'-17%'
        },
        [theme.breakpoints.down('md')]:{
            right:'-30%'
            //left:0,
            //background:'red',
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:0,
            right:'initial',
            margin:'auto',
            //height:700,
            //background:'blue'
        }
    },
    line:{
        position:'absolute',
        border:'1px solid white',
        left:'34%',
        [theme.breakpoints.down('lg')]:{
            left:'45%'
        },
        [theme.breakpoints.down(1530)]:{
            display:'none'
        },
    }

  }))



