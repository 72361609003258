import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Fade,
  Dialog,
  Grid
} from '@material-ui/core';
import logo from '../../assets/LOGO-ALTHAR-NAME.png';



function Transition(props) {
  return <Fade {...props} />;
}

const Loader = ({open, classes}) => {

  /* onClose = () => {
    console.log('Cerrar')
  } */

    return(
      <Dialog
          fullScreen  
          open={open}
          //onClose={this.onClose}
          TransitionComponent={Transition}
        >
          <div className={classes.container}>
            <Grid container direction='column' alignItems='center' justify='center' spacing={3}>
              <Grid item>
                <img src={logo} alt={''} className={classes.logo}/>
              </Grid>
              {/* <Grid item>
                <div className={classes.spinnerContainer}>
                  <LoaderIcon 
                    type="Bars"
                    color="#00adb5"
                  />  
                </div>
              </Grid> */}
            </Grid>
          </div>
          
        </Dialog>
    )
  
}

export default withStyles(styles)(Loader);
