import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import{Typography, Grid} from '@material-ui/core';
import cx from 'classnames';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
  container:{
    padding:'0px 40px'
  },
  title:{
    fontWeight:'600',
    fontSize:'1.4em',
    color:grey[900],
  },
  content:{
    color:grey[500],
    fontWeight:'500',
    fontSize:'1em'
  },
  subcontent:{
    color:grey[500],
    fontWeight:'500',
    fontSize:'1em'
  },
  icon:{
    width:'100%'
  },
  iconContainer:{
    width:'48px',
    height:'48px',
    padding:'24px',
    borderRadius:'50%',
    border:'4px solid white',
    boxShadow:'0 6px 12px 0 rgba(82, 91, 115, .2)',
    transform:'scale(1)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
    '&:hover':{
        transform:'scale(1.1)',
    }
  },
  colorA:{
    background:'linear-gradient(135deg, #ff4dae, #4268ff)'
  },
  colorB:{
      background:'linear-gradient(135deg, #ff9f2e, #f723c4)'
  },
  colorC:{
      background:'linear-gradient(135deg, #00f2aa, #0083ff)'
  },
  colorD:{
      background:'linear-gradient(135deg, #ffe10a, #ff3e5c)'
  },
  
})

class KeyFeature extends Component{

    render(){

        const {classes, data, color} = this.props;

        return(
          <div className={classes.container}>
            <Grid container justify='center' spacing={2}>
              <Grid item xs={12}>
                <div className={cx({
                        [classes.iconContainer]:true,
                        [classes.colorA]:color === 0,
                        [classes.colorB]:color === 1,
                        [classes.colorC]:color === 2,
                        [classes.colorD]:color === 3
                    })}>
                  <img src={data.icon} alt={''} className={classes.icon}/>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography align='center' className={classes.title}>
                  {data.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align='center' className={classes.content}>
                  {data.content}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )
    }
}

export default withStyles(styles)(KeyFeature);