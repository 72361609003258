//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';
//
import background from '../../../../../../assets/FONDO.jpg';


const styles = (theme) => ({
    root:{
        position:'relative',
        overflow:'hidden',
        height:'25vh',
        [theme.breakpoints.down('sm')]:{
            height:'100%'
            }

    },
    background:{
        position:'absolute',
        //backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(${background})`,
        width:'100%',
        height: '100%',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        zIndex:-1,
    },
    textContainer:{
        width:'100%',
        position:'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color:'white',
        zIndex:10,
        padding:'80px 0px 0px',
        [theme.breakpoints.down('sm')]:{
            padding:'100px 32px 40px',
            boxSizing:'border-box',
            textAlign:'center',
            }
        //paddingTop:'300px'
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem'
            },
        [theme.breakpoints.down('xs')]:{
            fontSize:'2rem'
            }
    },
    textTitle:{
        letterSpacing:theme.spacing.unit*4,
        color:'white',
        fontWeight:'700'
    },
    textSubtitleA:{
        letterSpacing:theme.spacing.unit*1,
        color:'white',
        fontWeight:'300'
    },
    textSubtitleB:{
        letterSpacing:theme.spacing.unit*1,
        color:'white',
        fontWeight:'600'
    },
    textContent:{
        letterSpacing:theme.spacing.unit*0.2,
        color:'white',
        fontWeight:'400'
    },
    divider:{
        height:16,
        width:100,
        borderRadius:24,
        background:theme.palette.primary.main,
        margin:'0px 0px'
    }
    
})

export default styles;