import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Hidden
} from '@material-ui/core';
import { withRouter } from "react-router";
import ReactGA from 'react-ga';


import {contentData} from './content.jsx';

import ServicesDesktop from './ServicesDesktop/ServicesDesktop.jsx';
import ServicesMobile from './ServicesMobile/ServicesMobile.jsx';

class Solutions extends Component{

  componentDidMount(){
    window.scrollTo(0,0)
    ReactGA.initialize('UA-134909612-1');
    ReactGA.pageview(window.location.pathname);
  }

  sendToPage = (event, url) => {
    this.props.history.push(url);
  } 

  render(){
    const {language} = this.props
    const content = contentData[language]

    let desktopView = (
      <Hidden smDown>
        <ServicesDesktop content={content} sendToPage={this.sendToPage}/>
      </Hidden>
    )

    let mobileView = (
      <Hidden mdUp>
        <ServicesMobile content={content} sendToPage={this.sendToPage}/>
      </Hidden>
    )
    
    return(
      <div>
        <ServicesDesktop content={content} sendToPage={this.sendToPage}/>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Solutions));
