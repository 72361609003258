import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid } from '@material-ui/core';
import {Slide, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';


import KeyFeature from '../../../../../../components/Services/KeyFeature/KeyFeature.jsx';

class SectionB extends Component{

    state = {
        title:[false,false,false],
        features:[false,false,false,false,false,false]
      }

    shouldComponentUpdate(nextProps, nextState){
        return this.props.content !== nextProps.content ||
                this.props.visited !== nextProps.visited ||
                JSON.stringify(this.state) !== JSON.stringify(nextState)
    }

    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
      }

    render(){

        const {classes, content} = this.props;
        const {title, features} = this.state;
        const timein = 1500;

        

        return(
            
            <div className={classes.section}>
                          
                <div className={classes.container}>
                
                    <Grid container align='center' spacing={1}>
                        <Grid item xs={12}>  
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                                <Slide direction='left' in={title[0]} timeout={1000}>
                                    <Typography variant='h4' align='center' className={classes.title}>
                                        {content.title}
                                    </Typography>
                                </Slide>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                                <Slide direction='right' in={title[1]} timeout={1000}>
                                    <div className={classes.divider}/>
                                </Slide>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}/>
                        <Grid item xs={12}>  
                          <InViewMonitor onInView={(event) => this.onInview(event, 'title', 2)} >
                            <Grow in={title[2]} timeout={timein+500}>
                                <div className={classes.content_container}>
                                    <Typography variant='body1' align='center' className={classes.about_content}>
                                        {content.content}
                                    </Typography>
                                </div>
                            </Grow>
                          </InViewMonitor>
                            
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.features}>
                            <Grid container spacing={3}>
                            {
                              content.features.map((item,key)=>{
                                return(
                                  <Grid item xs={4} key={key.toString()}>
                                    <InViewMonitor onInView={(event) => this.onInview(event, 'features', key)} >
                                      <Grow in={features[key]} timeout={1000 + 500*(key%3)}>
                                        <div>
                                          <KeyFeature data={item} color={key%4}/>
                                        </div>
                                      </Grow>
                                    </InViewMonitor>
                                    
                                  </Grid>
                                )
                              })
                            }
                              
                            </Grid>
                          </div>
                        </Grid>
                        
                    </Grid>
                        
                </div>
            </div>
            
        )
    }
}

export default withStyles(styles)(SectionB);