import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Loader from '../../components/Loader/Loader.jsx';

import { Switch, Route, Redirect, Router } from "react-router-dom"
import views from '../../routes/principal';
import {
} from '@material-ui/core';

const sections = {
  spanish:['work','services','resources'],
  english:['work','services','resources']
}
const url_sections = ['/work','/services','/resources']


class Principal extends Component{

  state = {
    appbarmode:false,
    menu:0,
    isloading:true,
    cont:0,
    language:'english',
    work:0
  }

  scrollListener = () => {
    const {appbarmode} = this.state;
    let position = window.scrollY;
    const threshold = 150;
    if(!appbarmode){
      if(position > threshold){
        this.setState({appbarmode:true})
      }
    }else{
      if(position < threshold){
        this.setState({appbarmode:false})
      }
    }
  }

  onPageLoaded = () => {
    
    this.setState({isloading:false})
  }

  onGoToWork = (val) => {
    this.setState({work:val})
  }

  componentDidMount(){
    window.addEventListener('scroll', this.scrollListener)
    window.addEventListener('load', this.onPageLoaded)
  }

  componentWillUnmount(){
    window.removeEventListener('scroll',this.scrollListener)
    window.removeEventListener('load', this.onPageLoaded)

  }
  onClearLoading = () => {
    this.setState({isloading:false})
    clearTimeout(this.timer)
  }

  onSelectedSection = (data) => {
    let cont = this.state.cont + 1;
    this.setState({isloading:true, cont:cont})
    this.timer = setTimeout(this.onClearLoading, 1200)
    this.props.history.push(data) 
    
  }

  onChangeLanguage = (key) => {
    let cont = this.state.cont + 1; 
    this.setState({isloading:true, cont:cont})
    this.timer = setTimeout(this.onClearLoading, 2000)
    this.setState({language: key})
  }

  /* const scrolltoId = () =>{
    var ele = document.getElementById("resources2");
    
    window.scrollTo(ele.offsetLeft,ele.offsetTop)
} */

  render(){

    const {classes, history} = this.props
    const {appbarmode, menu, isloading, language, work} = this.state

    //console.log(history.location.pathname)
    
    return(
      <div className={classes.root}>
        <Loader open={isloading}/>
        {/*<Header 
          menu={menu} 
          mode={appbarmode} 
          onChange={this.onSelectedSection} 
          sections={sections[language]} 
          urls={url_sections} 
          history={history} 
          language={language}
          onChangeLanguage={this.onChangeLanguage}
    />*/}
        <div style={{overflowX:'hidden',}}>

          <Router history={history}>
            <Switch>
              {views.map((route,idx) => {
                if(route.redirect)
                  return <Redirect to={route.to} key={idx.toString()}/>
                return <Route 
                  path={route.path} 
                  render={(props) => <route.component            
                    {...props} 
                    work={work}
                    onGoToWork={this.onGoToWork}
                    language={language} 
                  />} 
                  key={idx.toString()}
                />
              })
              }
            </Switch>
          </Router>
        </div>
        {history.location.pathname !== '/contact' ? <Footer onChange={this.onSelectedSection} language={language}/> : null}
      </div>
    )
  }
}

export default withStyles(styles)(Principal);
