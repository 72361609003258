import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{} from '@material-ui/core';


//************ SECTIONS COMPONENTS *************//
import SectionA from './Sections/SectionA/SectionA.jsx';
import SectionB from './Sections/SectionB/SectionB.jsx';

class ContactDesktop extends Component{


  
  state = {
    ready: false,
  }

  componentDidMount(){
    this.setState({ready:true})
  }

  

  render(){
  
    const {classes, content} = this.props;
    const {ready} = this.state;

    return(
      <div className={classes.root}>
        <SectionA ready={ready} content={content}/>
        {/* <SectionB content={content.sectionB}/> */}
        
      </div>
      
    )
  }
}

export default withStyles(styles)(ContactDesktop);
