import { fade } from '@material-ui/core/styles/colorManipulator';
import {blueGrey,grey} from '@material-ui/core/colors'
const styles = (theme) => ({
  root:{
    position:'relative',
    borderTop:`2px solid ${grey[200]}`
  },
  container:{
    padding:'32px 0px',
    borderBottom:`1px solid ${grey[200]}`
  },
  subcontainer:{
    boxSizing: 'border-box',
    width:'1200px',
    margin:'auto',
    padding:'0px 0px',
    paddingBottom:'0px',
    [theme.breakpoints.down('md')]:{
      width:'100%',
      padding:'0px 16px'
    },
  },
  svg:{
    height:'200px',
    background:'red',

  },
  textContent:{
    color:'rgba(255,255,255,0.75)',
    fontSize:'16px',
    fontWeight:'500'
  },
  icon:{
    color:'white',
    fontSize:'48px'
  },
  socialIcon:{
    '&:hover':{
      cursor:'pointer'
    }
  },
  copyFooter:{
    background: fade(theme.palette.primary.main,0.85),
    color:'white',
    fontWeight:'500',
    fontSize:'12px',
    width:'100%',
    padding:'8px 0px'
  },
  title:{
    color:'#58595b',
    fontSize:'1.4em',
    position:'relative',
    fontWeight:'400',
    padding:'16px 0'
  },
  title2:{
    color:'#808285',
    fontSize:'1.4em',
    position:'relative',
    fontWeight:'700',
    padding:'16px 0'
  },
  titleLine:{
    width:'40px',
    minHeight:'4px',
    background:grey[300],
  },
  content:{
    color:grey[700],
    maxWidth:'350px'
  },
  navlink:{
    textDecoration:'none',
    color:grey[700],
    fontSize:'0.9em',
    '&:hover':{
      textDecoration:'underline',
    }
  },
  navlinkB:{
    background:'#4b6bf5',
    padding:'5px 8px',
    borderRadius:'4px',
    textDecoration:'none',
    textTransform:'uppercase',
    textAlign:'center',
    color:'white',
    fontSize:'0.6em',
    fontWeight:'600',
    '&:hover':{
    }
  },
  legalContainer:{
    padding:'16px 0px'
  },
  legal:{
    fontSize:'0.7em',
    color:grey[500],
    fontWeight:'600'
  },
  imageContainer:{
    marginTop:'32px',
    boxSizing:'border-box',
    background:blueGrey[200],
    padding:'3px 6px',
    borderRadius:'4px'
  },
  facebook:{
    '&:hover':{
      background:'#3b5998',
      cursor:'pointer'
    }
  },
  instagram:{
    '&:hover':{
      background:'#dd2a7b',
      cursor:'pointer'
    }
  },
  linkedin:{
    '&:hover':{
      background:'#0e76a8',
      cursor:'pointer'
    }
  },
  twitter:{
    '&:hover':{
      background:'#38A1F3',
      cursor:'pointer'
    }
  },
  image:{
    width:'12px',
    height:'12px',
  },
  
})

export default styles;
