import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Icon,  Slide } from '@material-ui/core';

class SectionC extends Component{

    shouldComponentUpdate(nextProps, nextState){
        return this.props.content !== nextProps.content ||
                this.props.visited !== nextProps.visited
    }


    render(){

        const {classes, content, visited} = this.props;

        let content2Show = visited ? (
            <Grid container  alignItems='center'>
                <Grid item xs={12}> 
                    <Grid container direction='column' alignItems='center'>
                        <Grid item>
                            <Slide direction="left" in timeout={1000}>
                                <Typography variant='h4' align='center' className={classes.title}>
                                    {content.title}
                                </Typography>
                                
                            </Slide>
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item>
                            <Slide direction="right" in timeout={1000}>
                                <div className={classes.divider}/>
                            </Slide>
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid item xs={12}>  
                    <div className={classes.dataContainer}>
                        <Grid container>
                            {
                                content.data.map((item,key)=>{
                                    return(
                                        <Grid item key={key.toString()} xs={3}>
                                            <Slide direction="left" in timeout={300*(key+1)}>
                                                <Grid container direction='column' spacing={1}>
                                                    <Grid item>
                                                        <Grid container justify='center' alignItems='center'>
                                                            <Grid item>
                                                                <Typography variant='h4' align='center' className={classes.item_number}>
                                                                    {item.content}
                                                                </Typography>
                                                            </Grid>
                                                            {
                                                                item.icon ? (
                                                                    <Grid item>
                                                                        <Icon className={classes.item_icon}>
                                                                            {item.icon}
                                                                        </Icon>
                                                                    </Grid>
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                            
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container justify='center'>
                                                            <Grid item>
                                                                <Typography 
                                                                variant='body1' 
                                                                align='center' 
                                                                className={classes.item_title}
                                                                >
                                                                    {item.title}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </Grid>
                                            </Slide>
                                            
                                        </Grid>
                                    )
                                    
                                })
                            }
                        </Grid>
                    </div> 
                </Grid>
            </Grid>
        ):(
            <div style={{minHeight:'300px'}}/>
        );

        return(
            <div className={classes.section}>
                <div className={classes.container}>
                    {content2Show}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionC);