import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, IconButton, Icon, Collapse } from '@material-ui/core';
import cx from 'classnames';
//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';


const styles = (theme) => ({
    root:{margin:'0px 0'},
    title:{
        fontWeight:'600',
        color:grey[900],
        fontSize:'1.3em'
        
    },
    content:{
      padding:'0px 32px',
      fontWeight:'400',
      color:grey[700],
      fontSize:'0.95em',
    },
    container:{
        padding:'30px 20px',
        paddingBottom:'0px'
    },
    imageContainer:{
        marginBottom:'8px',
        width:'28px',
        height:'28px',
        padding:theme.spacing.unit*3,
        borderRadius:'50%',
        backgroundColor:'#4b6bf5',
    },
    image:{
        width:'100%'
    },
    colorA:{
        background:'linear-gradient(135deg, #ff4dae, #4268ff)'
    },
    colorB:{
        background:'linear-gradient(135deg, #ff9f2e, #f723c4)'
    },
    colorC:{
        background:'linear-gradient(135deg, #00f2aa, #0083ff)'
    },
    colorD:{
        background:'linear-gradient(135deg, #ffe10a, #ff3e5c)'
    },
    button:{
        padding:0,
        borderRadius:'50%',
        '&:hover':{
        }
    },
    icon:{
      fontSize:'1.4em'
    },
    paper:{
        borderRadius:0,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)'
    }
})

class ServiceCard extends Component{

  state = {
    open:false,
  }

  onEnter = () => {
    console.log('Entre')
    this.setState({open:true})
  }

  onLeave = () => {
    console.log('Sali')
    this.setState({open:false})
  }

  render(){

    const {classes, data, color, ismobile } = this.props;
    const {open} = this.state;

    const icon_name = open ? 'keyboard_arrow_up': 'keyboard_arrow_down';

    let buttonContent = null;
    if(!ismobile){
      buttonContent = (
        <div onMouseEnter={this.onEnter}>
          <IconButton className={classes.button}>
              <Icon className={classes.icon}>
                {icon_name}
              </Icon>
          </IconButton>
        </div>
      )
    }

    return(
      <div className={classes.root}>
        <Paper className={classes.paper}>
            <div className={classes.container} onMouseLeave={this.onLeave}>
                <Grid container direction='column' alignItems='center' spacing={2}>
                    <Grid item>
                        <div 
                            className={cx({
                                [classes.imageContainer]:true,
                                [classes.colorA]:color === 0,
                                [classes.colorB]:color === 1,
                                [classes.colorC]:color === 2,
                                [classes.colorD]:color === 3
                            })}
                        >
                            <img src={data.icon} alt='' className={classes.image}/>
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant='h5' align='center' className={classes.title}>
                            {data.title}
                        </Typography>
                    </Grid> 
                    <Grid item>
                      <Collapse in={open || ismobile}>
                        <div onMouseLeave={this.onLeave}>
                          <Typography className={classes.content}>
                              {data.content}
                          </Typography>
                        </div>
                        
                      </Collapse>
                    </Grid>
                    <Grid item>
                        {buttonContent}
                        
                    </Grid>
                </Grid>
            </div>
            
        </Paper>
        
      </div>
    )
  }
}

export default withStyles(styles)(ServiceCard);
