

export const sleep = ms => new Promise(r => setTimeout(r, ms));


export const onGetSelectedFormData = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onGetFormData = (form) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const isFormValid = (form, keys) => {
    let errors = []
    if(form){
        if(keys){
            keys.forEach(item => {
                if(!form[item].isValid) errors.push(item)
            })
        }else{
            Object.keys(form).forEach(item => {
                if(form[item].isRequired && !form[item].isValid) errors.push(item)
            })
        }
    }
    return errors
} 

export const onSetErrorsToForm = (form, errors) => {
    let temp = {...form}
    if(errors && errors.length) errors.forEach(item => {
        temp[item].isVisited = true
        temp[item].isValid = false
    })
    return temp
}