import { makeStyles, Typography } from "@material-ui/core"
import React from "react"

const Privacy = () => {

    const classes = useStyles()

    return(
        <div className={classes.root} >
            <div style={{textAlign:'center', marginBottom:70}}>
                {/* <Typography variant="h6" color='primary' >althar.com</Typography> */}
                <Typography variant="h4" >Aviso de Privacidad.</Typography>
                <div className={classes.divider}></div>
            </div>
            <Typography paragraph color='primary' className={classes.subtitle} >
                Relacionado con los datos personales recabados por AMK Healthcare México SA de CV, en adelante denominada como "ālthar<sup>®</sup>".
            </Typography>
            <Typography paragraph >
                ālthar<sup>®</sup>, en lo sucesivo el “Responsable”, con nombre comercial “ālthar<sup>®</sup>”, con domicilio calle Palenque 369, Narvarte Poniente, CP
                03020, alcaldía Benito Juárez, Ciudad de México, México.
            </Typography>
            <Typography paragraph >
                El Responsable expide el presente Aviso de Privacidad, para que el Titular de los datos personales, de ahora en adelante
                denominado “Titular” o “Titulares”, tenga conocimiento del trato que el Responsable les da a sus datos personales.
            </Typography>
            <Typography paragraph >
                La información de los Titulares, o bien, los medios de comunicación por los que se accede a esta pueden ser de manera verbal,
                escrita, medios electrónicos, óptica y de otra tecnología, como internet o vía telefónica.
            </Typography>
            <Typography paragraph color='primary' className={classes.subtitle} >
                Datos personales que recabamos
            </Typography>
            <Typography paragraph >
                Los datos personales que recaba el Responsable de los Titulares son datos de identificación, datos de contacto, datos laborales,
                datos patrimoniales, datos financieros y datos fiscales.
            </Typography>
            <Typography paragraph color='primary' className={classes.subtitle} >
                Finalidades del tratamiento de los datos personales
            </Typography>
            <Typography paragraph >
                El Responsable trata los datos personales del Titular para cumplir con las siguientes finalidades:
            </Typography>
            <Typography paragraph >
                (i) Finalidades originarias: La información personal es recabada, utilizada y almacenada para procesar solicitudes que realice el
                Titular respecto a productos y servicios que ofrece el Responsable, para que, eventualmente, ambos se sometan a una relación
                contractual. Asimismo, la información se utiliza para procesar solicitudes laborales y se recaban datos personales para resolver
                consultas, quejas o reclamaciones. Todas las finalidades anteriores son esenciales para la celebración de la relación jurídica y
                seguridad interna.
            </Typography>
            <Typography paragraph >
                (ii) Finalidades secundarias: se utilizan con fines estadísticos, para ofrecer, mejorar y promover productos y servicios, así como
                hacer llegar información de interés del Titular.
            </Typography>
            <Typography paragraph >
                El Responsable no recaba datos personales sensibles. En caso de que no desee que se le dé tratamiento a sus datos para las
                finalidades secundarias, por favor, mandar un correo electrónico con el asunto “Negativa finalidades secundarias” al correo
                electrónico info@althar.com.
            </Typography>
            <Typography paragraph color='primary' className={classes.subtitle} >
                Transferencia de datos personales
            </Typography>
            <Typography paragraph >
                El Responsable declara que no transferirá ningún tipo de información del Titular a terceros nacionales o extranjeros; en caso
                contrario, se le hará del conocimiento al Titular mediante la publicación de un nuevo aviso de privacidad. Lo anterior de acuerdo
                con lo previsto en el artículo 36 del Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los
                Particulares.
            </Typography>
            <Typography paragraph color='primary' className={classes.subtitle} >
                Ejercicio de derechos ARCO
            </Typography>
            <Typography paragraph >
                El Titular tiene derecho, de propia persona o mediante un representante, a solicitar, en cualquier momento, el acceso, rectificación,
                cancelación u oposición, respecto de los datos personales que trate el Responsable.
            </Typography>
            <Typography paragraph >
                Para lo anterior, el Titular debe enviar por correo electrónico una solicitud en escrito libre con los siguientes requisitos:
            </Typography>
            <Typography paragraph >
                (i) El nombre del Titular, dirección y correo electrónico para comunicarle la respuesta a su solicitud.
                (ii) Los documentos que acrediten la identidad. Solo se aceptarán credencial para votar, pasaporte, cartilla de servicio militar, tarjeta
                migratoria. Deberán adjuntarse como imagen al correo por los dos lados de manera clara y legible.
                (iii) En caso de que se haga mediante representante, también se deberá presentar la identificación del representante y carta poder
                firmada por dos testigos para actuar en nombre del Titular. Se debe adjuntar una imagen de la carta poder y de la identificación
                que sean legibles y claras.
                (iv) La relación que sostuvo o sostiene con el Responsable.
                (v) La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos de acceso,
                rectificación, cancelación u oposición a los mismos.
                (vi) En su caso, las modificaciones a realizarse y aportar la documentación que sustente su petición.
                (vii) Que la carta esté dirigida al Responsable y al Área de Privacidad del Responsable.
            </Typography>
            <Typography paragraph >
                La solicitud será enviada al correo electrónico del Área de Datos Personales: info@althar.com.
            </Typography>
            <Typography paragraph >
                El Responsable comunicará al Titular –en un plazo de 20 días hábiles contados desde la fecha en que se recibió la solicitud de
                acceso, rectificación, cancelación u oposición– la determinación adoptada al mismo correo señalado. El momento en que el
                Responsable recibe la solicitud es aquel en que haya entrado en nuestro servidor, contestando el mismo con un acuse de recibo.
            </Typography>
            <Typography paragraph >
                Una vez que haya sido enviada la contestación en el plazo señalado, el Responsable tendrá 15 días hábiles para ejecutar el acceso,
                rectificación, cancelación u oposición de los datos personales conforme a la solicitud. La respuesta y, en su caso, el envío de la
                información será mediante correo electrónico. En caso de que así lo decida el Titular y que proceda, la información será enviada
                al medio señalado para dichos efectos por el Titular, siempre y cuando este lo solicite y pague los justificados o justificables de
                envío o con el costo de reproducción en copias u otros formatos.
            </Typography>
            

            <Typography paragraph color='primary' className={classes.subtitle} >
                Límite de uso o divulgación de datos personales
            </Typography>
            <Typography paragraph >
                Para que el Titular ejerza su derecho de limitar el uso o divulgación de los datos personales, pero con intención de continuar
                usando de los servicios que presta el Responsable, el usuario debe manifestarlo mediante un escrito al siguiente correo
                electrónico info@althar.com. Dicho escrito debe contener lo siguiente:
            </Typography>
            <Typography paragraph >
                (i) El nombre del Titular, dirección y correo electrónico para comunicarle la respuesta a su solicitud.
                (ii) Los documentos que acrediten la identidad. Solo se aceptarán credencial para votar, pasaporte, cartilla de servicio militar,
                tarjeta migratoria. Deberán adjuntarse como imagen al correo por los dos lados de manera clara y legible.
                (iii) En caso de que se haga mediante representante, también se presentará identificación del representante y carta poder firmada
                por dos testigos para actuar en nombre del Titular. Se debe adjuntar una imagen de la carta poder y de la identificación que sean
                legibles y claras.
                (iv) La relación que sostuvo o sostiene con el Responsable.
                (v) La descripción clara y precisa de los datos que desea limitar de su uso o divulgación.
                (vi) Que la carta esté dirigida al Responsable y al Área de Privacidad del Responsable.
            </Typography>
            <Typography paragraph >
                La solicitud será enviada al correo electrónico del Área de Datos Personales info@althar.com.
            </Typography>
            <Typography paragraph >
                A todo aquel dato que haya sido limitado, que se tenga registro de que esté inscrito en un registro público o que se haya ejercido
                oposición, se le dará el mismo trato expresado en el presente título y se ingresará a un listado de exclusión del Responsable. Los
                datos personales de los que hayan sido cumplidos sus fines, pero que no puedan ser cancelados y/o suprimidos por ministerio de
                ley o contractual, serán bloqueados de los fines a los que eran sometidos, hasta que se pueda suprimir. Durante dicho periodo, los
                datos personales no podrán ser objeto de tratamiento mayor a la conservación y resguardo.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                Uso de Cookies o cualquier tecnología para recabar información
            </Typography>
            <Typography paragraph >
                El Titular, cuando ingresa a las páginas althar.com, adquiere la calidad de “Usuario”. En la página web se utilizan Cookies (las “Cookies”).
            </Typography>
            <Typography paragraph >
                Las Cookies son archivos de datos que se almacenan en el disco duro del equipo de cómputo o del dispositivo de comunicaciones
                electrónicas de un Usuario al navegar en un sitio de internet, el cual permite intercambiar información de estado entre dicho sitio
                y el navegador del Usuario.
            </Typography>
            <Typography paragraph >
                La información de estado puede revelar autenticación o preferencias del Usuario, así como cualquier dato almacenado por el
                navegador respecto al sitio de internet. Las Cookies no pueden utilizarse para obtener datos de su disco duro, dirección de correo
                electrónico o cualquier otra información privada, sino únicamente para recopilar información sobre el comportamiento del Usuario
                al visitar el sitio web.
            </Typography>
            <Typography paragraph >
                Las Cookies se utilizan con finalidades secundarias, con el fin de conocer los intereses, el comportamiento y la demografía de
                quienes visitan la página de internet y de esa forma, comprender mejor sus necesidades e intereses y darles un mejor servicio o
                proveerle información relacionada.
            </Typography>
            <Typography paragraph >
                La instalación, permanencia y existencia de las Cookies en el computador del Usuario depende de su exclusiva voluntad y puede
                ser eliminada de su computador cuando así lo desee. Para tales efectos, ingresar al siguiente link: Ver uso de Cookies. Esta misma
                información aparece al ingresar a la página de internet, en una barra de advertencia o notificación sobre el uso de Cookies.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                Modificaciones al Aviso de Privacidad
            </Typography>
            <Typography paragraph >
                El Responsable, así como el Titular reconocen que este Aviso de Privacidad es de vigencia ilimitada. Sin embargo, el Responsable
                procurara mantener actualizado el presente Aviso. La publicación de las modificaciones al Aviso de Privacidad será generalizada
                para todo Titular. Las reformas al Aviso de Privacidad se podrán hacer saber al Titular a través de un aviso en la página del
                Responsable, correo electrónico, de forma telefónica o por escrito.
            </Typography>
            

            <Typography paragraph color='primary' className={classes.subtitle} >
                Consentimiento
            </Typography>
            <Typography paragraph >
                En razón de que los datos se recaban directamente del Titular, que el presente Aviso de Privacidad se ha puesto a disposición del
                Titular, previo al uso de sus datos y que ninguno de los datos que trata el Responsable son sensibles, patrimoniales o financieros,
                no será necesario que el Titular manifieste su consentimiento de forma expresa, por lo que el Titular consiente el presente Aviso
                de Privacidad de conformidad con la Ley de Protección de Datos Personales en Posesión de los Particulares no oponiéndose al
                mismo.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                Revocación del consentimiento
            </Typography>
            <Typography paragraph >
                El Titular podrá revocar, en cualquier momento, su consentimiento con el presente Aviso de Privacidad y con el tratamiento a sus
                datos personales.
            </Typography>
            <Typography paragraph >
                Para revocar el consentimiento el Titular debe de enviar una solicitud al correo electrónico info@althar.com, la cual debe ser por
                escrito con los siguientes requisitos:
            </Typography>
            <Typography paragraph >
                (i) Los documentos que acrediten la identidad. Solo se aceptarán credencial para votar, pasaporte, cartilla de servicio militar,
                tarjeta migratoria. Deberán adjuntarse como imagen al correo por los dos lados de manera clara y legible.
                (ii) En caso de que se haga mediante representante, también se presentará identificación del representante y carta poder firmada
                por dos testigos para actuar en nombre del Titular. Se debe adjuntar una imagen de la carta poder y de la identificación que sean
                legibles y claras.
                (iii) La relación que sostuvo o sostiene con el Responsable.
                (iv) Que la carta esté dirigida al Responsable y al Área de Privacidad del Responsable.
            </Typography>
            <Typography paragraph >
                El Responsable emitirá una respuesta en la cual confirmará la revocación del consentimiento o, en su caso, señalará el
                razonamiento dependiendo del caso en concreto, contando el Responsable con 15 días para emitir esta respuesta.
            </Typography>
            <Typography paragraph >
                Los plazos serán contados a partir del momento en que el correo entra a nuestro servidor, emitiendo el Responsable el respectivo
                Acuse de Recibido de Solicitud.
            </Typography>
            <Typography paragraph >
                La Solicitud no será válida ante la omisión de lo señalado anteriormente.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                Área interna de Privacidad
            </Typography>
            <Typography paragraph >
                El Titular podrá ejercer sus derechos y realizar las consultas señaladas en el presente documento, así como en la ley, a través del
                área de Privacidad del Responsable a la cual se le deben dirigir las solicitudes señaladas en el presente documento y en la ley. El
                Usuario tendrá que utilizar el multirreferido correo electrónico info@althar.com para contactar el Área de Privacidad.
            </Typography>

            <Typography paragraph color='primary' className={classes.subtitle} >
                Autoridad protectora de datos personales
            </Typography>
            <Typography paragraph >
                Para cualquier duda o información adicional, así como para ejercer distintos tipos de derechos y procedimientos, además de los
                señalados en el presente, el Usuario puede contactar al Instituto Nacional de Transparencia, Acceso a la Información y Protección
                de Datos Personales, INAI, al teléfono 01800-835-4324 (lada sin costo) o por la página de internet <a href="https://www.inai.org.mx/" target='_blank' className={classes.link} >www.ifai.org.mx.</a>
            </Typography>
            <Typography paragraph >
                Fecha de última actualización: enero de 2023.
            </Typography>
        </div>
    )
}

export default Privacy

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.secondary.main,
        padding:'100px 100px',
        color:'white',
        textAlign:'justify',
      
      [theme.breakpoints.down('sm')]:{
        padding:'50px 32px'
        },
      
    },
    divider:{
        height:16,
        width:100,
        borderRadius:24,
        background:theme.palette.primary.main,
        margin:'20px auto'
    },
    subtitle:{
        fontWeight:600
    },
    link:{
        textTransform:'none',
        color:theme.palette.primary.main
    }
    

  }))