// Health projects
import celudoc from '../../../assets/Projects/celu.jpg'
import roche from '../../../assets/Projects/casos-clinicos.jpg'
import sandoz from '../../../assets/Projects/sandoz.jpg'
import bcare from '../../../assets/Projects/bcare.jpg'
import beyond from '../../../assets/Projects/beyond.jpg'
import gilead from '../../../assets/Projects/gilead.jpg'


// Digital transformations - Enterprise
import oncopassport from '../../../assets/Projects/onco.jpg'
import asiconnect from '../../../assets/Projects/asiconnect.jpg'
import managment from '../../../assets/Projects/managment.jpg'
import sabage from '../../../assets/Projects/sabage.jpg'

import smartbuildings from '../../../assets/Projects/smart-buildings.jpg'
import haagen from '../../../assets/Projects/haagen.jpg'
import batta from '../../../assets/Projects/batta.jpg'
import solerpalau from '../../../assets/Projects/solerpalau.jpg'


import gluck from '../../../assets/Projects/gluck.jpg'
import aj from '../../../assets/Projects/aj.jpg'
import parking from '../../../assets/Projects/parking.jpg'
import mcelu from '../../../assets/Projects/mcelu.jpg'
import onconnect from '../../../assets/Projects/onconnect.jpg'



import biutti from '../../../assets/Projects/biutti.jpg'
import ubelens from '../../../assets/Projects/ubelens.jpg'
import kuvik from '../../../assets/Projects/kuvik.jpg'

import trionix from '../../../assets/Projects/trionix.jpg'
import gotechk from '../../../assets/Projects/gotech.jpg'
import althar from '../../../assets/Projects/althar.jpg'
import ajweb from '../../../assets/Projects/ajweb.jpg'
import kuvikweb from '../../../assets/Projects/kuvikweb.jpg'


export const contentData = {
  spanish:{
    sectionA:{
      name:'Proyectos',
      phrase:'Te apoyamos en tu transformación digital'
    },
    sectionB:{
      title:'Transformando el sector salud',
      fixedImage: 'monitor',
      imagePosition:'left',
      content:[
        {
          title:'Gilead VHC',
          content:'Portal para la distribución de contenido en vivo y on demand sobre nuevos fármacos para el tratamiento de VHC. Enfocado a médicos especialistas, el portal es capaz de validar de manera autónoma la identidad de los médicos registrados.',
          image: gilead
        },
        {
          title:'Celu',
          content:'Ecosistema de salud enfocado al paciente que presenta nuevos paradigmas de atención médica para el acompañamiento de pacientes de manera presencial y remota. Incluye 4 pilares: telemedicina, historia clínica, dispositivos médicos y provisión de medicamentos.',
          image: celudoc
        },
        {
          title:'Beyond',
          content:'Plataforma de seguimiento, monitoreo y control de citas, tratamientos e historia clínica de pacientes del área de Oncología.',
          image: beyond
        },
        {
          title:'BCare',
          content:'Plataforma enfocada a enfocada a pacientes con Cáncer de Mama que les permite realizar simulaciones sobre su tratamiento basándose en su diagnóstico y la información de su póliza de seguro para hacer un uso más eficiente de la póliza con la que cuentan',
          image: bcare
        },
        {
          title:'Sandoz',
          content:'Plataforma para la subscripción y gestión del Programa Orientado al Paciente Sandoz, dedicado a apoyar a personas que han sido prescritas con medicamentos de la compañía en el tratamiento de su enfermedad, brindándoles acceso real.',
          image: sandoz
        },
      ]
    },
    sectionC:{
      title:'Digitalizamos los procesos de tu empresa',
      fixedImage: 'laptop',
      imagePosition:'right',
      content:[
        {
          title:'Oncopassport',
          content:'Sistema de gestión de un programa de beneficios para pacientes de oncología, capaz de calcular el beneficio correspondiente para cada paciente, llevar el control de aplicaciones y analizar el rendimiento del programa.',
          image: oncopassport
        },
        {
          title:'ASiConnect',
          content:'Plataforma central para la administración, cobro y soporte de todas las verticales de negocio de una consultora, desde el ramo jurídico, hasta el tecnológico.',
          image: asiconnect
        },
        {
          title:'Trionix Management',
          content:'Sistema web para administración y monitoreo de empleados, enfocado en seguimiento de tareas, tiempo y recursos de cada proyecto.',
          image: managment
        },
        {
          title:'Sabage',
          content:'Plataforma web enfocada en el seguimiento puntual de deportistas de alto rendimiento con el objetivo de analizar su progreso, permite generar estadísticas particulares de cada individuo y métricas de los entrenamientos.',
          image: sabage
        }
      ]
    },
    sectionD:{
      title:'Cuidamos tus edificios',
      fixedImage: 'tablet',
      imagePosition:'left',
      content:[
        {
          title:'TX Net Dashboard',
          content:'Portal de gestión de dispositivos inalámbricos enfocados en automatización, brinda la capacidad de generar alarmas, reportes periódicos y zonas de interés.',
          image: smartbuildings
        },
        {
          title:'HaagenDasz Energy Monitor',
          content:'Sistema web que permite monitorear el consumo eléctrico de múltiples heladerías ubicadas a lo largo del territorio mexicano, segmentado por sucursal, tipo de consumo y aparato.',
          image: haagen
        },
        {
          title:'Batta HVAC Control',
          content:'Plataforma de monitoreo y control de temperatura en distintas tiendas departamentales ubicadas en la zona norte de México, indicando el consumo energético de manera mensual y haciendo un análisis de puntos de mejora.',
          image: batta
        },
        {
          title:'SP - Metro Monitoring System ',
          content:'Sistema para el monitoreo remoto en tiempo real de la condición de los ventiladores y extractores de las unidades del sistema metro de la Ciudad de México.',
          image: solerpalau
        }
      ]
    },
    sectionE:{
      url:'/contact',
      subtitle:'¿Estás listo?',
      title:"¡Creemos algo grandioso juntos!",
      content:'Permítenos trabajar contigo y ayudarte a brindar las mejores herramientas tecnológicas para tu negocio.',
      button:'Contáctanos',
    },
    sectionF:{
      title:'Incrementa tus ventas',
      fixedImage: 'monitor',
      imagePosition:'left',
      content:[
        {
          title:'Kuvik',
          content:'Plataforma que busca crear una comunidad de artistas, galerías y coleccionistas en la cual se pueda comprar, apreciar y aprender del arte.',
          image: kuvik
        },
        {
          title:'Ubelens',
          content:'Tienda en línea de lentes de contacto y artículos relacionados.',
          image: ubelens
        },
        {
          title:'Biutti',
          content:'Marketplace especializado en dermatología.',
          image: biutti
        },
        
      ]
    },
    sectionG:{
      title:'Desarrollos para la palma de tu mano',
      fixedImage: 'smartphone',
      imagePosition:'right',
      content:[
        {
          title:'Onconnect',
          content:'Aplicación multiplataforma para la gestión y distribución de eventos virtuales, enfocada a otorgar una excelente experiencia al usuario, facilitar el acceso a los contenidos y la generación de analíticos de uso con alto nivel de detalle.',
          image: onconnect
        },
        {
          title:'Gluk',
          content:'Aplicación móvil de telemedicina con conexión inalámbrica con dispositivo inteligente para medición de glucosa en sangre.',
          image: gluck
        },
        {
          title:'Plaza Bazalia Lighting Control',
          content:'Aplicación multiplataforma que permite el control y gestión en tiempo real de dispositivos inalámbricos, conectados a distintos sistemas de iluminación en estacionamientos.',
          image: parking
        },
        {
          title:'Celu',
          content:'Aplicación multiplataforma para el ecosistema de salud enfocado al paciente Celu que presenta nuevos paradigmas de atención médica para el acompañamiento de pacientes de manera presencial y remota. Incluye 4 pilares: telemedicina, historia clínica, dispositivos médicos y provisión de medicamentos.',
          image: mcelu
        },
        {
          title:'Acción Juvenil',
          content:'Aplicación móvil enfocada en brindar información oportuna y descriptiva sobre una organización política juvenil del Partido Acción Nacional, brindando documentos y contenido multimedia descargable.',
          image: aj
        },    
      ]
    },
    sectionH:{
      title:'Levantamiento de tu página',
      fixedImage: 'monitor2',
      imagePosition:'left',
      content:[
        {
          title:'Acción Juvenil',
          content:'Página informativa de los principios, valores y visión de una organización política juvenil del Partido Acción Nacional que permite la inscripción y participación de nuevos militantes. ',
          image: ajweb
        },
        {
          title:'Kuvik',
          content:'Página web de una galería virtual que busca descubrir y promover artistas alrededor del mundo mediante espacios virtuales que permiten apreciar el arte en cualquier momento y desde cualquier sitio.',
          image: kuvikweb
        },
        {
          title:'Trionix',
          content:'Página web empresarial de una empresa de soluciones de IoT e Industria 4.0.',
          image: trionix
        },
        {
          title:'Althar',
          content:'Sitio web corporativo de una agencia creativa.',
          image: althar
        },
        /*{
          title:'GoTechk',
          content:'Landing page de empresa enfocada en servicios de iluminación y automatización.',
          image: gotechk
        },*/
      ]
    },
    sectionI:{
      url:'/contact',
      subtitle:'¡Trabajemos juntos!',
      title:"Xandbox es todo lo que necesitas para crear increíbles aplicaciones",
      button:'Contáctanos',
    },
  },
  english:{
    sectionA:{
      name:'Projects',
      phrase:'We support you in the digital transformation'
    },
    sectionB:{
      title:'Transformando el sector salud',
      fixedImage: 'monitor',
      imagePosition:'left',
      content:[
        {
          title:'Gilead VHC',
          content:'Portal para la distribución de contenido en vivo y on demand sobre nuevos fármacos para el tratamiento de VHC. Enfocado a médicos especialistas, el portal es capaz de validar de manera autónoma la identidad de los médicos registrados.',
          image: gilead
        },
        {
          title:'Celu',
          content:'Ecosistema de salud enfocado al paciente que presenta nuevos paradigmas de atención médica para el acompañamiento de pacientes de manera presencial y remota. Incluye 4 pilares: telemedicina, historia clínica, dispositivos médicos y provisión de medicamentos.',
          image: celudoc
        },
        {
          title:'Beyond',
          content:'Plataforma de seguimiento, monitoreo y control de citas, tratamientos e historia clínica de pacientes del área de Oncología.',
          image: beyond
        },
        {
          title:'BCare',
          content:'Plataforma enfocada a enfocada a pacientes con Cáncer de Mama que les permite realizar simulaciones sobre su tratamiento basándose en su diagnóstico y la información de su póliza de seguro para hacer un uso más eficiente de la póliza con la que cuentan',
          image: bcare
        },
        {
          title:'Sandoz',
          content:'Plataforma para la subscripción y gestión del Programa Orientado al Paciente Sandoz, dedicado a apoyar a personas que han sido prescritas con medicamentos de la compañía en el tratamiento de su enfermedad, brindándoles acceso real.',
          image: sandoz
        },
      ]
    },
    sectionC:{
      title:'Digitalizamos los procesos de tu empresa',
      fixedImage: 'laptop',
      imagePosition:'right',
      content:[
        {
          title:'Oncopassport',
          content:'Sistema de gestión de un programa de beneficios para pacientes de oncología, capaz de calcular el beneficio correspondiente para cada paciente, llevar el control de aplicaciones y analizar el rendimiento del programa.',
          image: oncopassport
        },
        {
          title:'ASiConnect',
          content:'Plataforma central para la administración, cobro y soporte de todas las verticales de negocio de una consultora, desde el ramo jurídico, hasta el tecnológico.',
          image: asiconnect
        },
        {
          title:'Trionix Management',
          content:'Sistema web para administración y monitoreo de empleados, enfocado en seguimiento de tareas, tiempo y recursos de cada proyecto.',
          image: managment
        },
        {
          title:'Sabage',
          content:'Plataforma web enfocada en el seguimiento puntual de deportistas de alto rendimiento con el objetivo de analizar su progreso, permite generar estadísticas particulares de cada individuo y métricas de los entrenamientos.',
          image: sabage
        }
      ]
    },
    sectionD:{
      title:'Cuidamos tus edificios',
      fixedImage: 'tablet',
      imagePosition:'left',
      content:[
        {
          title:'TX Net Dashboard',
          content:'Portal de gestión de dispositivos inalámbricos enfocados en automatización, brinda la capacidad de generar alarmas, reportes periódicos y zonas de interés.',
          image: smartbuildings
        },
        {
          title:'HaagenDasz Energy Monitor',
          content:'Sistema web que permite monitorear el consumo eléctrico de múltiples heladerías ubicadas a lo largo del territorio mexicano, segmentado por sucursal, tipo de consumo y aparato.',
          image: haagen
        },
        {
          title:'Batta HVAC Control',
          content:'Plataforma de monitoreo y control de temperatura en distintas tiendas departamentales ubicadas en la zona norte de México, indicando el consumo energético de manera mensual y haciendo un análisis de puntos de mejora.',
          image: batta
        },
        {
          title:'SP - Metro Monitoring System ',
          content:'Sistema para el monitoreo remoto en tiempo real de la condición de los ventiladores y extractores de las unidades del sistema metro de la Ciudad de México.',
          image: solerpalau
        }
      ]
    },
    sectionE:{
      url:'/contact',
      subtitle:'¿Estás listo?',
      title:"¡Creemos algo grandioso juntos!",
      content:'Permítenos trabajar contigo y ayudarte a brindar las mejores herramientas tecnológicas para tu negocio.',
      button:'Contáctanos',
    },
    sectionF:{
      title:'Incrementa tus ventas',
      fixedImage: 'monitor',
      imagePosition:'left',
      content:[
        {
          title:'Kuvik',
          content:'Plataforma que busca crear una comunidad de artistas, galerías y coleccionistas en la cual se pueda comprar, apreciar y aprender del arte.',
          image: kuvik
        },
        {
          title:'Ubelens',
          content:'Tienda en línea de lentes de contacto y artículos relacionados.',
          image: ubelens
        },
        {
          title:'Biutti',
          content:'Marketplace especializado en dermatología.',
          image: biutti
        },
        
      ]
    },
    sectionG:{
      title:'Desarrollos para la palma de tu mano',
      fixedImage: 'smartphone',
      imagePosition:'right',
      content:[
        {
          title:'Onconnect',
          content:'Aplicación multiplataforma para la gestión y distribución de eventos virtuales, enfocada a otorgar una excelente experiencia al usuario, facilitar el acceso a los contenidos y la generación de analíticos de uso con alto nivel de detalle.',
          image: onconnect
        },
        {
          title:'Gluk',
          content:'Aplicación móvil de telemedicina con conexión inalámbrica con dispositivo inteligente para medición de glucosa en sangre.',
          image: gluck
        },
        {
          title:'Plaza Bazalia Lighting Control',
          content:'Aplicación multiplataforma que permite el control y gestión en tiempo real de dispositivos inalámbricos, conectados a distintos sistemas de iluminación en estacionamientos.',
          image: parking
        },
        {
          title:'Celu',
          content:'Aplicación multiplataforma para el ecosistema de salud enfocado al paciente Celu que presenta nuevos paradigmas de atención médica para el acompañamiento de pacientes de manera presencial y remota. Incluye 4 pilares: telemedicina, historia clínica, dispositivos médicos y provisión de medicamentos.',
          image: mcelu
        },
        {
          title:'Acción Juvenil',
          content:'Aplicación móvil enfocada en brindar información oportuna y descriptiva sobre una organización política juvenil del Partido Acción Nacional, brindando documentos y contenido multimedia descargable.',
          image: aj
        },    
      ]
    },
    sectionH:{
      title:'Levantamiento de tu página',
      fixedImage: 'monitor2',
      imagePosition:'left',
      content:[
        {
          title:'Acción Juvenil',
          content:'Página informativa de los principios, valores y visión de una organización política juvenil del Partido Acción Nacional que permite la inscripción y participación de nuevos militantes. ',
          image: ajweb
        },
        {
          title:'Kuvik',
          content:'Página web de una galería virtual que busca descubrir y promover artistas alrededor del mundo mediante espacios virtuales que permiten apreciar el arte en cualquier momento y desde cualquier sitio.',
          image: kuvikweb
        },
        {
          title:'Trionix',
          content:'Página web empresarial de una empresa de soluciones de IoT e Industria 4.0.',
          image: trionix
        },
        {
          title:'Althar',
          content:'Sitio web corporativo de una agencia creativa.',
          image: althar
        },
        /*{
          title:'GoTechk',
          content:'Landing page de empresa enfocada en servicios de iluminación y automatización.',
          image: gotechk
        },*/
      ]
    },
    sectionI:{
      url:'/contact',
      subtitle:"Let's work together!",
      title:"Xandbox is all what you need to create awesome applications",
      button:'Contact us',
    },
  }
}