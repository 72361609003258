import Home from '../views/Principal/Home/Home.jsx';
import Technologies from '../views/Principal/Technologies/Technologies.jsx';
import Services from '../views/Principal/Services/Services.jsx';
import About from '../views/Principal/About/About.jsx';
import Contact from '../views/Principal/Contact/Contact.jsx';
import Projects from '../views/Principal/Projects/Projects.jsx'
import Resources from '../views/Principal/Resources/Resources.jsx';





const dashboardRoutes = [
  {
    path: "/",
    component: Resources
  },
    /*{
      path: "/credentials",
      component: Home
    },
    {
      path: "/work",
      component: Technologies
    },
    {
      path: "/services",
      component: Services
    },
     {
      path: "/projects",
      component: Projects
    },
    {
      path: "/about",
      component: About
    }, 
    {
      path: "/resources",
      component: Resources
    },
    {
      path: "/contact",
      component: Contact
    },*/
    { redirect: true, exact:true, from: "*", to: "/", navbarName: "Redirect" }
  ]
  
  export default dashboardRoutes;