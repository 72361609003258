//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        margin:'100px 0px',
    },
    container:{
        boxSizing:'border-box',
        width:'100%',
        margin:'auto',
        padding:'8px 16px',
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    about_title:{
        fontWeight:'700',
    },
    about_content_container:{
        margin:'24px'
    },
    about_content:{
        color:grey[700],
        fontWeight:'400',
        fontSize:'1.1em'
    },
    image:{
        width:'100%',
    },
    imageContainer:{
        margin:'40px 0px'
    }
    
})

export default styles;