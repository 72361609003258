//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';
import background from '../../../../../../assets/Services/getContact.jpg';

const opacity = 0.9

const styles = (theme) => ({
    section:{
        background:theme.palette.secondary.main,
    },
    container:{
        backgroundImage:`linear-gradient(135deg, rgba(255, 77, 174, ${opacity}), rgba(66, 104, 255, ${opacity})), url(${background})`,
        width:'100%',
        height: '100%',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        padding:'100px 0'
    },
    subcontainer:{
        padding:theme.spacing.unit*6
    },
    icon:{
        color:'white',
        fontSize:'4em'
    },
    subtitle:{
        color:'white',
        textTransform:'uppercase',
        fontSize:'0.8em',
        fontWeight:'700',
        letterSpacing:'0.2em'
    },
    title:{
        color:'white',
        fontSize:'2.1em',
        fontWeight:'700'
    },
    content:{
        color:'rgba(255,255,255,0.9)',
        fontSize:'1em',
        fontWeight:'500',
        width:'100%x',
        margin:'auto'
    },
    number:{
        fontWeight:'800',
        color:'rgba(255,255,255,1)'
    },
    label:{
        fontWeight:'600',
        color:'rgba(255,255,255,0.7)'
    },
    button:{
    background:'#ff9c2d',
    color:'white',
    padding:'12px 28px',
    borderRadius:'24px',
    textTransform:'none',
    fontSize:'1em',
    fontWeight:'600',
    '&:hover':{
        background:'#ff9c2d',
        boxShadow:'0 10px 20px rgba(75, 107, 245, 0.16), 0 6px 6px rgba(75, 107, 245, 0.23)'
    }
    },

    
})

export default styles;