import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Fade, CircularProgress, Snackbar, Icon } from '@material-ui/core';
import InputForm from '../../../../../../components/UI/Forms/InputForm.jsx';
import useSectionA from './useSectionA.jsx';

//pics
import button_img from '../../../../../../assets/Contact/button.png'
import planet from '../../../../../../assets/Home/PLANETA.png';

const SectionA = ({classes}) =>{

    const { actions, form, user, system, modals, } = useSectionA({})

    const successContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.success}} 
    open={Boolean(system.success)} onClose={actions.onCloseSuccess}  autoHideDuration={5000} 
    message={
    <Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>done</Icon></Grid>
        <Grid item><Typography style={{color:'white'}}>{system.success}</Typography></Grid>
    </Grid>}/>

    const errorContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.error}} 
    open={Boolean(system.error)} onClose={actions.onClearError}  autoHideDuration={5000} 
    message={
    <Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>error</Icon></Grid>
        <Grid item><Typography style={{color:'white'}}>{system.error}</Typography></Grid>
    </Grid>}/>

    return(
        <div className={classes.root}>
            {successContent}
            {errorContent}
            <div className={classes.background}/>
            <div className={classes.textContainer}>
                {/* <div style={{width:'100%', padding:'0px 32px'}}> */}
                    <Grid container direction='column' alignItems='center' >
                        <Grid item>
                            <Fade in timeout={1500}>
                                <Typography variant='h3' className={classes.title} >
                                    CONTACT US.
                                </Typography>
                            </Fade>    
                        </Grid>
                        <Grid item>
                            <Fade in timeout={3000}>
                                <div className={classes.divider} />                   
                            </Fade>  
                        </Grid>
                    </Grid>
                {/* </div>  */}           
            </div>
            <div className={classes.form_container} >
                <Grid container spacing={3}  >
                    <Grid item md={6} xs={12} >
                        <InputForm data={form.name} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <InputForm data={form.email} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <InputForm data={form.subject} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <InputForm data={form.phone} onChange={actions.onChangeForm} />
                    </Grid>
                    <Grid item xs={12} >
                        <InputForm data={form.message} onChange={actions.onChangeForm} />
                    </Grid>
                </Grid>
            </div>
            <div style={{display:'flex', justifyContent:'center', padding:'50px 0px'}} >
                <div className={classes.button} onClick={actions.onSubmit} >
                    
                    <Typography className={classes.send_text} color='primary'   >Send message</Typography>
                    {system.sending ? (
                        <Fade in timeout={500}>
                            <div style={{marginLeft:16}}>
                                <CircularProgress size={'4.4vh'} className={classes.progress}/>
                            </div>  
                        </Fade>
                    ) : null }
                </div>
            </div>
            <div className={classes.bottom_section} >
                <div className={classes.planet_cont} >
                    <img src={planet} className={classes.planet} />
                </div>
                <div className={classes.semi_circle}></div>
                <div className={classes.titles_container} >
                    <Grid container justifyContent='center' spacing={4}>
                        <Grid item><Typography variant='h4' >Discover.</Typography></Grid>
                        <Grid item><Typography variant='h4' >Design.</Typography></Grid>
                        <Grid item><Typography variant='h4' >Delight.</Typography></Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
    
}

export default withStyles(styles)(SectionA);