import { Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const ArrowDown = ({onClick}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root} onClick={onClick}>
            <Grid container direction='column' alignItems='center' spacing={1}>
                
                <Grid item>
                    <div className={classes.first_circle}/>
                </Grid>
                <Grid item>
                    <div className={classes.second_circle}/>
                </Grid>
                <Grid item>
                    <Icon className={classes.arrow}>keyboard_arrow_down</Icon>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        cursor:'pointer',
    },
    first_circle:{
        width:24,
        height:24,
        borderRadius:'50%',
        border:`2px solid ${theme.palette.primary.main}`
    },
    second_circle:{
        width:20,
        height:20,
        borderRadius:'50%',
        border:`2px solid ${theme.palette.primary.main}`
    },
    arrow:{
        color:theme.palette.primary.main,
        fontSize:36,
        marginTop:-8
    }
}))
 
export default ArrowDown;