import { green, red } from '@material-ui/core/colors';
import background from '../../../../../../assets/FONDO.jpg';




const styles = (theme) => ({
    root:{
        position:'relative',
        overflow:'hidden',
        background:theme.palette.secondary.main,
        //height:'100vh',
        //background:'red',
        /* [theme.breakpoints.down('sm')]:{
            height:'100%'
            } */
        //height:'100vh'
    },
    background:{
        position:'absolute',
        //backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(${background})`,
        width:'100%',
        height: '100%',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        zIndex:-1,
    },
    divider:{
        height:16,
        width:100,
        borderRadius:24,
        background:theme.palette.primary.main,
        margin:'12px 0px'
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.2rem'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'2rem'
        }
    },
    textContainer:{
        width:'100%',
        position:'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color:'white',
        zIndex:10,
        //paddingBottom:'0px',
        //paddingTop:'300px',
        //background:'red',
        padding:'15vh 0px 5vh',
        [theme.breakpoints.down('sm')]:{
            //padding:'40px 0px',
            //paddingTop:'100px'
            padding:'100px 32px 0px',
            boxSizing:'border-box'
        }
    },
    form_container:{
        padding:'0px 200px 0px',
        position:'relative',
        zIndex:10,
        [theme.breakpoints.down('md')]:{
            padding:'0px 100px 0px',
        },
        [theme.breakpoints.down('sm')]:{
            padding:'0px 32px 0px',
        }
    },
    button_container:{
        margin:'auto',
        width:500,
        //background:'red',
        position:'relative',
        cursor:'pointer',
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        }
        //display:'flex',
        //justifyContent:'center'
        //width:'100%'
    },
    text_button:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)',
        zIndex:10
    },
    button:{
        boxShadow:'0px 0px 40px 0px rgba(0,173,181,1)',
        borderRadius:28,
        padding:'0px 32px',
        background:'white',
        color:theme.palette.primary.main,
        cursor:'pointer',
        position:'relative',
        zIndex:2,
        display:'flex',
        alignItems:'center'
        //margin:'20px auto'
      },
    bottom_section:{
        height:'25vh',
        width:'100%',
        position:'relative',
        [theme.breakpoints.down('sm')]:{
            marginTop:40,
            height:'100%'
        }
        //overflowX:'hidden',
        //background:'red'
    },
    planet:{
        width:800,
        animation:`$planetAnimation 50s linear infinite`,
        [theme.breakpoints.down('md')]:{
            width:600
        },
        [theme.breakpoints.down('sm')]:{
            width:500
        }
        
    },
    planet_cont:{
        position:'absolute',
        bottom:'-40vh',
        right:'-25vw',
        [theme.breakpoints.down('sm')]:{
            //bottom:'-45%',
            //right:'-35%',
            display:'none'
        }
    },
    "@keyframes planetAnimation": {
        "0%": {
          transform: "rotate(360deg)"
        },
        "100%": {
          transform: "rotate(-360deg)"
        }
      },
    semi_circle:{
        position:'absolute',
        bottom:-125,
        left:'50%',
        transform:'translate(-50%, 0px)',
        height: 250,
        width: 250,
        borderRadius: '50%',
        backgroundColor:theme.palette.primary.main,
    },
    titles_container:{
        position:'absolute',
        bottom:30,
        left:0,
        width:'100%',
        color:'white',
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            padding:'0px 32px',
            boxSizing:'border-box'
        }
    },
    send_text:{
        fontSize:'4.4vh', fontWeight:600,
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.4rem'
        }
    },
    success:{
        background:green[700]
    },
    error:{
        background:red[500]
    }
    /* textTitle:{
        letterSpacing:theme.spacing.unit*2,
        color:'white',
        fontWeight:'700'
    },
    textSubtitleA:{
        letterSpacing:theme.spacing.unit*1,
        color:'white',
        fontWeight:'300'
    },
    textSubtitleB:{
        letterSpacing:theme.spacing.unit*1,
        color:'white',
        fontWeight:'600'
    },
    textContent:{
        letterSpacing:theme.spacing.unit*0.2,
        color:'white',
        fontWeight:'400'
    }, */
    
})

export default styles;