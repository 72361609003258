import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{} from '@material-ui/core';

//************ SECTIONS COMPONENTS *************//
import SectionA from './Sections/SectionA/SectionA.jsx';
import SectionB from './Sections/SectionB/SectionB.jsx';
import SectionC from './Sections/SectionC/SectionC.jsx';
import SectionD from './Sections/SectionD/SectionD.jsx';
import SectionE from './Sections/SectionE/SectionE.jsx';
import SectionF from './Sections/SectionF/SectionF.jsx';
import SectionG from './Sections/SectionG/SectionG.jsx';
import SectionH from './Sections/SectionH/SectionH.jsx';
import SectionI from './Sections/SectionI/SectionI.jsx';


class AboutMobile extends Component{

  state = {
    ready: false,
  }

  componentDidMount(){
    this.setState({ready:true})
  }

  render(){
  
    const {classes, content, sendToPage} = this.props;
    const {ready} = this.state;
    
    return(
      <div className={classes.root}>
        <SectionA ready={ready} content={content}/>
        <SectionB content={content.sectionB}/>
        <SectionC content={content.sectionC}/>
        <SectionD content={content.sectionD}/>
        <SectionE content={content.sectionE} sendToPage={sendToPage}/>
        <SectionF content={content.sectionF}/>
        <SectionG content={content.sectionG}/>
        <SectionH content={content.sectionH}/>
        <SectionI content={content.sectionI} sendToPage={sendToPage}/>
      </div>
      
    )
  }
}

export default withStyles(styles)(AboutMobile);
