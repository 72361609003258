import React from "react"
import { Button, CircularProgress, Fade, Grid, Icon, makeStyles, Snackbar, Typography, alpha, Hidden } from "@material-ui/core"
import doctor from '../../../../assets/Resources/IMAGEN-RESOURCES.jpg'
import globo from '../../../../assets/Resources/ELEMENTO-2-RESOURCES.png'
import InputFormFilled from "../../../../components/UI/Forms/InputFormFilled"
import useSectionC from "./useSectionC"
import cx from 'classnames'
import ReportModal from "../modals/ReportModal"
import { red } from "@material-ui/core/colors"
import doctor_wants from '../../../../assets/Resources/medical_title_white.png'
import IOSSwitch from "../components/IOSSwitch"
import ArrowDown from "../components/ArrowDown"




const SectionC = ({
    onGoDown,
    onShowLastView
}) => {

    const classes = useStyles()
    const { actions, form, active_not, user, system, modals} = useSectionC({onGoDown, onShowLastView})

    const successContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.success}} 
    open={Boolean(system.success)} onClose={actions.onCloseSuccess}  autoHideDuration={5000} 
    message={
    <Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>done</Icon></Grid>
        <Grid item><Typography style={{color:'white'}}>{system.success}</Typography></Grid>
    </Grid>}/>

    const errorContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.error}} 
    open={Boolean(system.error)} onClose={actions.onClearError}  autoHideDuration={5000} 
    message={
    <Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>error</Icon></Grid>
        <Grid item><Typography style={{color:'white'}}>{system.error}</Typography></Grid>
    </Grid>}/>

    return(
        <div className={classes.root} id='resources3'>
            {errorContent}
            <ReportModal open={modals.report} onClose={()=>actions.onUpdateModal('report', false)} onClick={actions.onDownloadReport} />
            <div className={classes.image_container}>
                <img src={doctor_wants} className={classes.image_title} width='100%' />
            </div>

            <Grid container spacing={5}>
                <Grid item md={6} xs={12}>
                    <div className={classes.right_side} >
                        <div className={classes.form} >
                            <Grid container  spacing={2}>
                                <Grid item  xs={12}>
                                    <InputFormFilled data={form.name}  onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item  xs={12}>
                                    <InputFormFilled data={form.last_name}  onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item  xs={12}>
                                    <InputFormFilled data={form.email}  onChange={actions.onChangeForm} />
                                </Grid>
                                {/* <Grid item md={6} xs={12}>
                                    <InputFormFilled data={form.company}  onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputFormFilled data={form.position}  onChange={actions.onChangeForm} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputFormFilled data={form.company_size}  onChange={actions.onChangeForm} />
                                </Grid> */}
                            </Grid>
                        </div>
                        <Typography style={{width:'95%', margin:'auto', fontSize:'2vh'}} >
                            Me gustaría recibir ocasionalmente <i>insights</i> e información de <span style={{fontWeight:600, fontSize:'2.1vh'}} >ālthar</span>.
                        </Typography>
                        <div style={{marginTop:16, marginBottom:60}}>
                            <Grid container alignItems="center" spacing={1} justifyContent='center'>
                                <Grid item>
                                    <Typography>SÍ</Typography>
                                </Grid>
                                <Grid item>
                                    <div style={{transform:'rotate(180deg)'}}> 
                                        <IOSSwitch checked={active_not} onChange={actions.onHandleActiveNot} />
                                    </div>
                                    
                                </Grid>
                                <Grid item>
                                    <Typography>NO</Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <Button className={classes.send_button} fullWidth onClick={actions.onSubmit}  >
                            {system.sending ? (
                                <Fade in timeout={500}>
                                    <Grid container justifyContent='center'>
                                        <Grid item>
                                            <div className={classes.loading_container}>
                                                <CircularProgress size={'3vh'} className={classes.progress}/>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    
                                </Fade>
                            ) :
                            <Typography style={{fontSize:'3vh', fontWeight:600}}>Descargar</Typography>}
                        </Button>
                    </div>
                </Grid>
                <Grid item md={6} xs={12} >
                    <div className={classes.right_container}>
                        <Typography paragraph className={classes.paragraph}  style={{marginBottom:32}}>
                            Descarga la última edición del estudio <span>¿Qué quieren los médicos? 2023</span> y
                            descubre:
                        </Typography>
                        <Typography paragraph className={classes.paragraph}  >
                            <li className={classes.list} >
                                <span>
                                    Las 14 principales herramientas
                                    disruptivas para los médicos en México en el
                                    2023
                                </span>
                            </li>
                            <li className={classes.list} >
                                <span>
                                Cómo piensan los médicos sobre la tecnología
                                </span>
                            </li>
                            <li className={classes.list} >
                                <span>
                                Qué canales de contacto, información y educación esperan
                                </span>
                            </li>
                            <li className={classes.list} >
                                <span>
                                Qué y cómo quieren aprender
                                </span>
                            </li>
                            <li className={classes.list} >
                                <span>
                                Qué valor le dan al representante médico
                                </span>
                            </li>
                            <li className={classes.list} >
                                <span>
                                El rol evolutivo del MSL
                                </span>
                            </li>
                        </Typography>
                        <div className={classes.globo} >
                            <img src={globo} 
                            //width={'90%'} 
                            
                            className={classes.globo_img}
                            >
                            
                            </img>
                            <Typography  className={classes.globo_text} noWrap >
                                <span>¡</span>Y mucho más<span>!</span>
                            </Typography>
                        </div>
                    </div>
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default SectionC


const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        background:'#1e2531',
        //background:theme.palette.secondary.main,
        //background:'red',
        color:'white',
        padding:'100px 60px',
        minHeight:'100vh',
        boxSizing:'border-box',
        overflow:'hidden',
        [theme.breakpoints.down('md')]:{
            height:'100%',
            padding:'50px 32px',
        }
    },
    arrow_container:{
        position:'absolute', 
        zIndex:40, bottom:20, 
        left:'50%', transform:'translateX(-50%)',
        //background:alpha('#FFF',0.2),
        borderRadius:4,
        paddingTop:4
        //width:100, height:100,
        //border:'1px solid red'
    },
    image_container:{
        width:512,
        margin:'auto',
        marginBottom:64,
        [theme.breakpoints.down('md')]:{
            width:300,
            marginBottom:48,
        }
        
    },
    error:{
        background:red[500]
    },
    title:{
        maxWidth:360, margin:'auto', 
        margin:'0px auto 2vh',
        fontSize:'5vh',
        fontWeight:600,
        [theme.breakpoints.down('xs')]:{
            fontSize:'2.4rem',
        }
    },
    paragraph:{
        //fontSize:'1.6rem',
        fontSize:'1.250rem',
        '& span':{
            fontWeight:600
        },
        
    },
    right_container:{
        width:'100%',
        maxWidth:600,
        paddingLeft:80,
        [theme.breakpoints.down('md')]:{
            paddingLeft:0,
            paddingRight:16,
        },
        [theme.breakpoints.down('sm')]:{
            paddingLeft:0,
            paddingRight:0,
        }
    },
    list:{
        color:theme.palette.primary.main,
        '& span':{
            color:'white',
            fontWeight:500
        }
    },
    globo_text:{
        position:'absolute',
        left:'50%',
        transform:'translateX(-50%)',
        bottom:8,
        fontSize:'3.5vh',
        fontWeight:600,
        
        //transform:'translateX(-50%)',
        '& span':{
            color:theme.palette.primary.main
        },
        [theme.breakpoints.down('xs')]:{
            //top:'36%',
            //left:30,
            fontSize:'1.250rem',

            bottom:'15%',
        }
    },
    globo:{
        position:'relative',
        //border:'1px solid white',
        width:'85%',
        [theme.breakpoints.down('md')]:{
            width:'100%',
        }
        //background:'green',
       // height:'10vh'
    },
    globo_img:{
        width:'100%',
        [theme.breakpoints.down('xs')]:{
            //width:300,
            //height:'auto'
        }
    },
    right_side:{
        textAlign:'center',
        padding:'0px 32px',
        boxSizing:'border-box',
        width:'100%',
        maxWidth:500,
        margin:'auto',
        [theme.breakpoints.down('sm')]:{
            padding:0
        }
        //background:'red',
       
    },
    form:{
        margin:'0px 0px 20px',
        textAlign:'left'
    },
    costum_switch:{
        borderRadius:32,
        width:100,
        height:32,
        background:'rgba(255,255,255,0.45)'
    },
    swicth_ball:{
        height:32,
        width:32,
        background:'white',
        borderRadius:'50%', 
        cursor:'pointer',
        //transition:'all ease-in-out 1s'
    },
    swicth_ball_inactive:{
        float:'right'
    },
    send_button:{
        background:theme.palette.primary.main,
        borderRadius:32,
        textTransform:'none',
        color:'white',
        '&:hover':{
            background:theme.palette.primary.main, 
        }
    },
    loading_container:{
        //background:theme.palette.primary.main,
        //borderRadius:'50%',
        //padding:'8px 11px',
        position:'relative'
    },
    progress:{
        color:'white',
        marginTop:2
    },
   
    
    
}))