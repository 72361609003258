import React, { Component, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Fade, Slide, Hidden } from '@material-ui/core';
import videoData from '../../../../../../assets/Video_final.mp4';
import InViewMonitor from 'react-inview-monitor';

const SectionA = ({classes,ready,content}) =>{

    
    let videodata = null;
    if(ready){
        videodata = (
            <video id="background-video" loop autoPlay muted className={classes.video}>
                <source src={videoData} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        )
    }

    const [section2_inview, setSection2Inview] = useState(false)
    const [section3_inview, setSection3Inview] = useState(false)



    return(
        <div className={classes.root}>
            <div className={classes.videoContainer}>   
                <div className={classes.video_mask} />  
                {videodata}               
            </div>
            <div className={classes.textContainer}>    
                <InViewMonitor onInView={()=>setSection2Inview(true)} onNotInView={()=>setSection2Inview(false)} repeatOnInView  >
                    {/* <Fade in={section3_inview} timeout={2000} >
                        <div>
                        <Typography className={classes.title} variant='h1' style={{color:'white'}} >Discover.</Typography> 
                        <Typography className={classes.title} variant='h1' color='primary' >Design.</Typography>  
                        <Typography className={classes.title} variant='h1' style={{color:'white'}} >Delight.</Typography>
                        </div>  
                    </Fade>  */} 
                    <Slide in={section2_inview} direction='left' timeout={1000} >
                        <div>
                        <Typography className={classes.title} variant='h1' style={{color:'white'}} >Discover.</Typography> 
                       
                        </div>  
                    </Slide> 
                    <Slide in={section2_inview} direction='left' timeout={1000} style={{ transitionDelay:`${1000}ms` }}>
                        <div>
                
                        <Typography className={classes.title} variant='h1' color='primary' >Design.</Typography>  
                       
                        </div>  
                    </Slide> 
                    <Slide in={section2_inview} direction='left' timeout={1000} style={{ transitionDelay:`${1500}ms` }} >
                        <div>
                        
                        <Typography className={classes.title} variant='h1' style={{color:'white'}} >Delight.</Typography>
                        </div>  
                    </Slide>  
                </InViewMonitor>             
                {/* <div className={classes.divider} ></div> */} 
                {/* <Typography  style={{fontSize:'2rem'}} >A life full of <span style={{fontWeight:600}} >health</span>, hope & happiness</Typography> 
                <Typography variant='h5' style={{marginTop:32, maxWidth:750}} >We design solutions, services & products of technology, strategy & health.</Typography>  */}      
            </div>
            <div className={classes.textContainer}>                
                {/* <Typography className={classes.title} variant='h1' style={{color:'white'}} >Discover.</Typography> 
                <Typography className={classes.title} variant='h1' color='primary' >Design.</Typography>  
                <Typography className={classes.title} variant='h1' style={{color:'white'}} >Delight.</Typography> */}   
                {/* <div className={classes.divider} ></div> */} 
                <InViewMonitor onInView={()=>setSection3Inview(true)} onNotInView={()=>setSection3Inview(false)} repeatOnInView  >
                    <Fade in={section3_inview} timeout={2000} >
                        <div>
                            <Typography className={classes.text1} >A life full of <span style={{fontWeight:600}} >health</span>, hope & happiness</Typography> 
                            <Typography className={classes.text2} >We design solutions, services & products of technology, strategy & health.</Typography> 
                        </div>  
                    </Fade>   
                </InViewMonitor> 
            </div>
        </div>
    )
    
}

export default withStyles(styles)(SectionA);