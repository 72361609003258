import React from "react"
import { alpha, Button, Grid, Hidden, Icon, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import doctor from '../../../../assets/Resources/IMAGEN.png'
import doctor_mobile from '../../../../assets/Resources/IMAGE-MOBILE.png'
import doctor_wants from '../../../../assets/Resources/LOGO-QQLM23-2.png'
import globo from '../../../../assets/Resources/ELEMENTO-RESOURCES.png'
import line from '../../../../assets/Resources/PLECA-ICONO.png'
import bg from '../../../../assets/Resources/FONDO.png'
import bg_mobile from '../../../../assets/Resources/CIRCLES-MOBILE.png'
import binoculares from '../../../../assets/Resources/BINOCULARES.svg'
import ArrowDown from "../components/ArrowDown"




const CongratulationsSection = ({onGoDown}) => {

    const classes = useStyles()

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const scrolltoId = () =>{
        var ele = document.getElementById("resources3");
        /* window.scrollTo({
        top: access.scrollTop,
        left: access.scrollLeft}); */
        window.scrollTo(ele.offsetLeft,ele.offsetTop)
    }

    return(
        <div className={classes.root} id='resources2' >
                
                
            <Grid container>
                <Grid item xs={12} lg={5}>
                    <div className={classes.left_container}>
                        <div >
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <img src={doctor_wants} className={classes.image_title} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.text} align='fl' >
                                        Estamos seguros de que el <span style={{fontWeight:900}} >reporte será de gran utilidad </span> 
                                        para tu práctica profesional.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <div className={classes.circle_binoculares}>
                                                <img src={binoculares} alt='bino' className={classes.binoculares} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                        
                                        <Typography align="left" className={classes.text} >
                                            Si necesitas una agencia que te ayude en este difícil 2023 a <span className={classes.bold_letter} style={{color:'black'}}> crear soluciones galácticas con impacto positivo, </span> escríbenos:
                                            <a className={classes.bold_letter} style={{textDecoration:'none'}} href='mailto:info@althar.com'> info@althar.com</a>
                                        </Typography>
                                </Grid>
                            </Grid>
                            
                        </div>
                        
                        
                        {/*<div className={classes.line_container} >
                            <div className={classes.line_subcontainer}>
                                <img src={line} />
                                
                            </div>
    </div>*/}
                    
                        {/*<div className={classes.info_container} >
                            
                            <div style={{width:'100%'}}>
                            <Button className={classes.button} endIcon={<div className={classes.icon_container} ><Icon color='primary' >download</Icon></div>} 
                            onClick={scrolltoId}>
                                <Typography style={{fontSize:'1.8rem', fontWeight:600}} >Descarga aquí el reporte</Typography>
                            </Button>
                            </div>
                            <div className={classes.globo_container} >
                                <img src={globo} className={classes.globo}  />
                                <Typography className={classes.globo_text} >
                                    Descubre las <span>14 principales 
                                    herramientas</span> con potencial disruptivo para los médicos
                                    en México en el 2023.
                                </Typography>
                            </div>
</div>*/}
                    </div>
                </Grid>
            </Grid>
            
            
            <div className={classes.doctor_img} >
                <img src={sm ? doctor_mobile : doctor} className={classes.doctor} />
            </div>
        </div>
    )
}

export default CongratulationsSection


const useStyles = makeStyles(theme => ({
    left_container:{
        width:'100%',
        maxWidth:500,
        margin:'auto',
        //border:'1px solid black',
        marginTop:120,
        boxSizing:'border-box',
        [theme.breakpoints.down('sm')]:{
            marginTop:0,
            padding:'0px 16px'
        }

    },
    arrow_container:{
        position:'absolute', 
        zIndex:40, bottom:40, 
        left:'50%', transform:'translateX(-50%)',
        background:alpha('#FFF',0.2),
        borderRadius:4,
        paddingTop:4
        //width:100, height:100,
        //border:'1px solid red'
    },
    image_title:{
        width:450,
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            maxWidth:350
        }
    },
    text:{
        fontSize:'1.500rem',
        marginTop:32,
        color:'#393E46',
        textAlign:'left',
        fontWeight:500,
        lineHeight:1.2,
        //marginTop:40,
        [theme.breakpoints.down('md')]:{
            margin:'40px auto 0px',
            fontSize:'1.6rem',
            //minHeight:500
            //background:'red'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'1.4rem'
        }
    },
    circle_binoculares:{
        background:'#b7dadb',
        width:72,
        height:72,
        borderRadius:'50%',
        position:'relative',
        marginTop:48,
        marginBottom:48,
    },
    binoculares:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        width:48
    },
    bottom_text_container:{
        padding:24,
        borderRadius:16,
        border:`1px solid ${theme.palette.primary.main}`,
        marginBottom:32
    },
    bottom_text:{
        fontSize:'1.250rem',
        fontWeight:500,
    },
    bold_letter:{
        fontWeight:600,
        color:theme.palette.primary.main
    },
    root:{
        position:'relative',
        overflow:'hidden',
        background:'white',
        //paddingTop:'15vh',
        paddingLeft:100,
        boxSizing:'border-box',
        minHeight:'calc(100vh - 0px)',
        backgroundImage:`url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        [theme.breakpoints.down('md')]:{
            //padding:'60px 40px 0px',
            paddingTop:60,
            paddingLeft:0,
            height:'100%',
            backgroundImage:`url(${bg_mobile})`,
            backgroundSize:'contain',
            backgroundPosition: "bottom center",
            backgroundRepeat:'no-repeat'
        },
        /* [theme.breakpoints.down('sm')]:{
            backgroundImage:`url(${bg_mobile})`,
        } */
        
    },
    line_container:{
        position:'absolute',
        left:0,
        top:'44vh',
        [theme.breakpoints.down('md')]:{
            //background:'red',
            position:'initial',
            top:'initial',
            margin:'70px 0px',
            //float:'left',
            height:70
        },
        [theme.breakpoints.down('xs')]:{
            margin:'20px 0px',
        },
        //background:'red'
    },
    line_subcontainer:{
        position:'relative',
        [theme.breakpoints.down('md')]:{
            float:'left'
        }
    },
    doctor:{
        height:'100%',
        [theme.breakpoints.down('md')]:{
            width:'100%',
            height:'initial'
        }
    },
    doctor_img:{
        //float:'right',
        //marginBottom:-12,
        position:'absolute',
        right:0,
        bottom:-10,
        height:'80vh',
        [theme.breakpoints.down('md')]:{
            position:'relative',
            right:'initial',
            height:'initial'
            //float:'right'
        },
        /* [theme.breakpoints.down('xs')]:{
            height:'70vh',
            background:'red'
            //float:'right'
        } */
        //position:'relative',
        //zIndex:10
    },
    
    doctor_wants_img:{
        //position:'absolute',
        //top:'10vh',
        //left:'10%',
        height:'15vh',
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:'initial',
            left:'initial',
            //padding:'20px 40px 40px',
            //boxSizing:'border-box'
        },
        [theme.breakpoints.down('xs')]:{
           
            height:'100%',
            //marginBottom:100
        }
    },
    doctor_wants:{
        [theme.breakpoints.down('xs')]:{
            width:'100%',
            height:'auto',
            //marginBottom:100
        }
    },
    globo:{
        [theme.breakpoints.down('xs')]:{
            display:'none'
            //width:'100%'
        }
    },
    container1:{
        position:'absolute',
        left:100,
        top:'10vh',
        textAlign:'center',
        zIndex:10,
        [theme.breakpoints.down('md')]:{
            //position:'relative',
            position:'initial',
            left:'initial',
            top:'inital',
            //background:'blue',
            height:'100%',
            //marginBottom:70,
            padding:'0px 32px'
            //margin:'auto'
        },
       
    },
    info_container:{
        position:'absolute',
        left:100,
        bottom:40,
        textAlign:'center',
        zIndex:10,
        /* [theme.breakpoints.down('md')]:{
            position:'relative',
            background:'green'
        }, */
        [theme.breakpoints.down('md')]:{
            //background:'green',
            position:'initial',
            left:'initial',
            bottom:'initial',
            //marginTop:70,
            height:'100%',
            padding:'0px 32px'
        },
       
    },
    
    button:{
        background:theme.palette.primary.main,
        borderRadius:32,
        color:'white',
        textTransform:'none',
        padding:'8px 24px',
        '&:hover':{
            background:theme.palette.primary.main,
            opacity:0.8
        }
    },
    icon_container:{
        background:'white',
        padding:4,
        borderRadius:'50%',
        width:40,
        height:40,
        marginLeft:12,
        marginRight:-8,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    globo_container:{
        position:'relative',
        //background:'red',
        //float:'left',
        [theme.breakpoints.down('md')]:{
            float:'none'
        }

    },
    globo_text:{
        position:'absolute',
        top:'36%',
        //left:'50%',
        //transform:'translateX(-50%)',
        textAlign:'center',
        fontSize:'1.4rem',
        padding:'0px 12px',
        color:'#393E46',
        '& span':{
            color:theme.palette.primary.main,
            fontWeight:600,
            [theme.breakpoints.down('sm')]:{
                background:'white'
            }
        },
        [theme.breakpoints.down('md')]:{
            width:550,
            left:'50%',
            transform:'translateX(-50%)'
        },
        [theme.breakpoints.down('xs')]:{
            width:'100%',
            position:'relative',
            marginTop:40
        }
    },
    line:{
        position:'absolute',
        top:'40%',
        right:0,
        zIndex:0
    }
    
}))