import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Slide, Paper, Button, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';
import axios from 'axios';
import CustomSnackbar from '../../../../../../components/UI/CustomSnackbar/CustomSnackbar.jsx';
import CircularLoader from '../../../../../../components/UI/CircularLoader/CircularLoader.jsx';
import InputText from '../../../../../../components/UI/InputText/InputText.jsx';
import {ruleValidation, isFormValid} from '../../../customFunctions.jsx';

let mailServer = {
    instance: axios.create({
        baseURL: "https://jna02sfp70.execute-api.us-east-1.amazonaws.com",
        headers: {
        'X-Api-Key': 'agbCMI6hTOBFFt0Uqa2g2z1lyGHGM9N5wHrBrq45',
        },
    }),
    credentials: {
        key: 'agbCMI6hTOBFFt0Uqa2g2z1lyGHGM9N5wHrBrq45'
    }
}

class SectionE extends Component{

    state = {
      openSnack:false,
      error:false,
      title:[false,false],
      steps:[false,false,false,false],
      form:[false],
      isloading:false,
      isValid: false,
        formData:{
            name:{
                value:'',
                isValid:false,
                isVisited:false,
                isRequired: true,
                rules:{
                type:'distance',
                min:1,
                max:1000
                }
            },
            email:{
                value:'',
                isValid:false,
                isVisited:false,
                isRequired: true,
                rules:{
                type:'email'
                }
            },
            subject:{
                value:'',
                isValid:false,
                isVisited:false,
                isRequired: true,
                rules:{
                type:'distance',
                min:1,
                max:1000
                }
            },
            message:{
                value:'',
                isValid:false,
                isVisited:false,
                isRequired: true,
                rules:{
                type:'distance',
                min:1,
                max:1000
                }
            },
            phone:{
                value:'',
                isValid:false,
                isVisited:false,
                isRequired: true,
                rules:{
                type:'none',
                min:1,
                max:1000
                }
            },
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.props.content !== nextProps.content ||
               JSON.stringify(this.state) !== JSON.stringify(nextState)
    }

    onInview = (event, key, pos) => {
      let temp = [...this.state[key]];
      temp[pos] = true;
      this.setState({[key]:[...temp]})
    }

    onChangeData = (event, id) => {
        let temp = JSON.parse(JSON.stringify(this.state.formData));
        temp[id].value = event.target.value;
        temp[id].isVisited = true;
        temp[id].isValid = ruleValidation(temp[id].value, temp[id].rules);
        let validForm = isFormValid(temp);
        this.setState({formData:{...temp}, isValid: validForm})
    }

    resetForm = () => {
        this.setState({formData:{name:{
            value:'',
            isValid:false,
            isVisited:false,
            isRequired: true,
            rules:{
            type:'distance',
            min:1,
            max:1000
            }
        },
        email:{
            value:'',
            isValid:false,
            isVisited:false,
            isRequired: true,
            rules:{
            type:'email'
            }
        },
        subject:{
            value:'',
            isValid:false,
            isVisited:false,
            isRequired: true,
            rules:{
            type:'distance',
            min:1,
            max:1000
            }
        },
        message:{
            value:'',
            isValid:false,
            isVisited:false,
            isRequired: true,
            rules:{
            type:'distance',
            min:1,
            max:1000
            }
        },
        phone:{
            value:'',
            isValid:false,
            isVisited:false,
            isRequired: true,
            rules:{
            type:'none',
            min:1,
            max:1000
            }
        }}})
    }

    submitEmailForm = async() => {
        let data2Send = {};
        Object.keys(this.state.formData).forEach(item => {
          data2Send[item] = this.state.formData[item].value
        })
        try{
            await this.setState({isloading: true})
            await mailServer.instance.post("/dev/contact-email", data2Send);
            await this.setState({isloading: false})
            await this.setState({error: false})
            await this.setState({openSnack: true})
            this.resetForm()
        }catch{
            await this.setState({error: true})
            await this.setState({openSnack: true})
        }

    }

    onOpenSnack = () => {
        this.setState({openSnack:true})
    }

    onClose = () => {
        this.setState({openSnack:false})
    }

    render(){

        const {classes, content} = this.props;
        const {formData, title, form, isValid, isloading, error, openSnack} = this.state;

        let snackContent = null;
        if(!error){
            snackContent = (
                <CustomSnackbar 
                    open={openSnack} 
                    message={content.snackbarSuccess.message} 
                    autoHideDuration={content.snackbarSuccess.duration} 
                    onClose={this.onClose}  
                    variant={content.snackbarSuccess.variant}
                />
            )
        }else{
            snackContent = (
                <CustomSnackbar 
                    open={openSnack} 
                    message={content.snackbarError.message} 
                    autoHideDuration={content.snackbarError.duration} 
                    onClose={this.onClose}  
                    variant={content.snackbarError.variant}
                />
            )
        }

        return(
          <div className={classes.section}>
            {snackContent}
            <div className={classes.container}>
              <Grid container>
                <Grid item xs={12}>  
                  <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                    <Slide direction='left' in={title[0]} timeout={1000}>
                      <Typography variant='h4' align='center' className={classes.title}>
                          {content.title}
                      </Typography>
                    </Slide>
                  </InViewMonitor>
                </Grid>
                <Grid item xs={12}>
                  <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                    <Slide direction='right' in={title[1]} timeout={1000}>
                        <div className={classes.divider}/>
                    </Slide>
                  </InViewMonitor>
                    
                </Grid>
                <Grid item xs={12}>  
                    <div className={classes.content_container}>
                        <Typography align='center' className={classes.content}>
                            {content.content}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <InViewMonitor onInView={(event) => this.onInview(event, 'form', 0)} >
                        <Grow in={form[0]} timeout={2000}>
                            <div className={classes.cardWrapper}>
                                <Paper className={classes.paper}>
                                    <Grid container>
                                        <Grid item xs={12} md={8}>
                                            <div className={classes.form}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} md={6}>
                                                        <InputText
                                                            value={formData['name'].value}
                                                            error={!formData['name'].isValid && formData['name'].isVisited}
                                                            placeholder={content.form.name}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            icon='person_outline'
                                                            onChange={(event) => this.onChangeData(event,'name')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <InputText
                                                            value={formData['email'].value}
                                                            error={!formData['email'].isValid && formData['email'].isVisited}
                                                            placeholder={content.form.email}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            type='email'
                                                            icon='mail_outline'
                                                            onChange={(event) => this.onChangeData(event,'email')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <InputText
                                                            value={formData['subject'].value}
                                                            error={!formData['subject'].isValid && formData['subject'].isVisited}
                                                            placeholder={content.form.subject}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            icon='subject'
                                                            onChange={(event) => this.onChangeData(event,'subject')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <InputText
                                                            value={formData['phone'].value}
                                                            error={!formData['phone'].isValid && formData['phone'].isVisited}
                                                            placeholder={content.form.phone}
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            type='number'
                                                            icon='local_phone'
                                                            onChange={(event) => this.onChangeData(event,'phone')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <InputText
                                                            value={formData['message'].value}
                                                            error={!formData['message'].isValid && formData['message'].isVisited}
                                                            placeholder="Mensaje"
                                                            margin="normal"
                                                            variant="outlined"
                                                            fullWidth
                                                            icon='edit'
                                                            multiline
                                                            rows={8}
                                                            onChange={(event) => this.onChangeData(event,'message')}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container spacing={4}>
                                                            <Grid item>
                                                                <Button className={classes.button} onClick={this.submitEmailForm} disabled={!isValid || isloading}>
                                                                    {content.form.button}
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <CircularLoader open={isloading}/>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.form}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography className={classes.contactTitle}>
                                                                    {content.contact.address.title}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {content.contact.address.content.map((item,key)=>{
                                                                    return(
                                                                        <Typography className={classes.contactContent} key={key.toString()}>
                                                                            {item}
                                                                        </Typography>
                                                                    )
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography className={classes.contactTitle}>
                                                                    {content.contact.phone.title}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {content.contact.phone.content.map((item,key)=>{
                                                                    return(
                                                                        <Typography className={classes.contactContent} key={key.toString()}>
                                                                            {item}
                                                                        </Typography>
                                                                    )
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography className={classes.contactTitle}>
                                                                    {content.contact.email.title}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {content.contact.email.content.map((item,key)=>{
                                                                    return(
                                                                        <Typography className={classes.contactContent} key={key.toString()}>
                                                                            {item}
                                                                        </Typography>
                                                                    )
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </Grow>
                    </InViewMonitor>
                    
                </Grid>
              </Grid>
            </div>
          </div>
        )
    }
}

export default withStyles(styles)(SectionE);