//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    greyBG:{
        padding:'100px 0px',
        width:'100%',
        backgroundColor:grey[50],
    },
    section:{
        margin:'auto',
        padding:'16px',
        position:'sticky',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'100%',
        },
    },
    noBottomPadding:{
        paddingBottom:'0px',
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px',
    },
    divider:{
        marginTop:32,
        width:50,
        minHeight:5,
        background:grey[300],
        marginBottom:40
    },
})

export default styles;