import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import button from '../../../../../../assets/ICONO-MAS.png'


const Service = ({title, icon, onClick}) => {

    const classes = useStyles()

    return(
        <div className={classes.root} >
            <div className={classes.icon_container}>
                <img src={icon} width={'100%'} height={'100%'}  />
            </div>
            <Typography className={classes.title} >{title}</Typography>
            <div onClick={onClick} >
                <img src={button} style={{cursor:'pointer'}}  />
            </div>
            {/* <div>
                <div className={classes.circle} style={{width:16, height:16}} />
                <div className={classes.circle} style={{width:12, height:12}} />
                <div className={classes.arrow} />
            </div> */}
        </div>
    )
}

export default Service

const useStyles = makeStyles(theme => ({
    root:{
        //background:'red',
        //justifyContent:'center',
        textAlign:'center'
    },
    title:{
        margin:'20px 0px 40px', 
        fontSize:'2.2rem',
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.8rem'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:'1.6rem'
        }
    },
    icon:{
        width:68,
        height:68,
        //background:'red'
    },
    icon_container:{
      margin:'auto',
      background:'white',
      borderRadius:'50%',
      width:140,
      height:140,
      padding:30,
      boxSizing:'border-box',   
      display:'flex',
      justifyContent:'center',
      alignItems:'center'  
    },
    circle:{
        border:'0.5px solid white',
        borderRadius:'50%',
        margin:'8px auto 0px'
    },
    arrow:{
        border: 'solid white',
        borderWidth: '0 1px 1px 0',
        display: 'inline-block',
        padding: '5px',
        transform:'rotate(45deg)'
    }

  }))