//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    root:{
        overflow:'hidden'
    },

})

export default styles;