import React from "react"
import { makeStyles, Slide, Typography, Zoom } from "@material-ui/core"
import InfoItem from "../InfoItem"
import image from '../../../../../../../assets/Home/IMAGE-RWE.png'
import WorkModal from "../WorkModal"



const RWE = ({open, onClose}) => {

    const classes = useStyles()

    return(
        <WorkModal open={open} onClose={onClose}>
            <Typography variant='h3' className={classes.title} >RWE</Typography>
            <Zoom in timeout={750} style={{ transitionDelay:'500ms' }}>
            <div className={classes.challenge}>
                <InfoItem 
                    title='Challenge.'
                    info="A huge and common lack in Latin America is data. 
                    There are no reliable data for any disease. Our client 
                    wants to approach a healthcare system in a country to 
                    help the main number of patients to receive treatment."
                />
            </div>
            </Zoom>
            <Zoom in timeout={750} style={{ transitionDelay:'1000ms' }}>
            <div className={classes.solution}>
                <InfoItem 
                    title='Solution.'
                    info='We design and develop a real-world evidence (RWE) 
                    digital platform based on EMR and a methodology 
                    process to find, analyze and present aggregated data 
                    for condition and treatments.'
                />
            </div>
            </Zoom>
            <Zoom in timeout={750} style={{ transitionDelay:'1500ms' }} >
            <div className={classes.result}>
                <InfoItem 
                    title='Result.'
                    info='The government accepted the RWE platform as an 
                    add-value solution from our client, and for the first time, 
                    they have reliable data to publish and take 
                    pharmacoeconomics decisions. The final beneficiary: 
                    The patients.'
                />
            </div>
            </Zoom>
            <Slide direction="up" in timeout={1000} >
                <div className={classes.img_container}>
                    <img src={image} width={'100%'} className={classes.img} />
                </div>
            </Slide>
        </WorkModal>
    )
}

export default RWE

const useStyles = makeStyles(theme => ({
    root:{
      position:'relative',
      padding:70,
      color:'white',
      [theme.breakpoints.down('sm')]:{
        padding:32,
        }
    },
    title:{
        marginBottom:20,
        [theme.breakpoints.down('sm')]:{
            fontSize:'2.5rem',
            textAlign:'center',
            //background:'red',
            width:'100%',
            marginTop:40,
            }
    },
    challenge:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:400
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
  
    },
    result:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:400
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
     
    },
    solution:{
        maxWidth:520,
        marginTop:20,
        [theme.breakpoints.only('md')]:{
            maxWidth:400
            },
        [theme.breakpoints.down('sm')]:{
            maxWidth:'100%'
            }
      
    },
    img:{
        transform:'scale(1.2) rotate(-15deg)',
        [theme.breakpoints.down('lg')]:{
            transform:'scale(1) rotate(-15deg)',
        },
        [theme.breakpoints.down('md')]:{
            transform:'scale(1) rotate(0deg)',
        },
        [theme.breakpoints.down('sm')]:{
            transform:'scale(0.8)',
            //background:'pink'
        },
    },
    img_container:{
        position:'absolute',
        top:120,
        right:'10%',
        [theme.breakpoints.down('lg')]:{
            right:'-5%'
        },
        [theme.breakpoints.down('md')]:{
            right:'-17%'
            //left:0,
            //background:'red',
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:0,
            right:'initial',
            margin:'auto',
            //height:700,
            //background:'blue'
        }
    },
    line:{
        position:'absolute',
        border:'1px solid white',
        left:'38%',
        [theme.breakpoints.down('lg')]:{
            left:'42%'
        },
        [theme.breakpoints.down(1450)]:{
            display:'none'
        },
    }

  }))



